import state from '@/store/layout/state'
import * as getters from '@/store/layout/getters'
import * as mutations from '@/store/layout/mutations'
import * as actions from '@/store/layout/actions'

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  state
}
