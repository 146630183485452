import apiService from '@/modules/api/csp'
import indexDBService from '@/modules/db/indexDB'
import localStorageService from '@/util/local-storage-service'
import localCacherService from '@/modules/cache/localCacher'

const indexedDBSyncService = (function () {
  const _service = this

  const _mapper = {
    cdrs: 'callHistory',
    speedDial: 'speedDial',
    contactBooks: 'contactBooks',
    contactGroups: 'contactGroups'
  }

  function _getService () {
    return _service
  }

  async function _sync () {
    const timestamp = localStorageService.getSyncTimestamp()

    const params = {
      timestamp,
      tables: _parseArgs(arguments)
    }

    const response = await apiService.syncApiModule.sync(params)

    if (response.status !== 200) return

    localStorageService.setSyncTimestamp((Date.now() / 1000).toFixed() - 3)

    const data = response.data

    for (const type in data) {
      indexDBService[_mapper[type]].sync(data[type])
    }
  }

  function _parseArgs (args) {
    if (!args || !args.length || !args.length) {
      return [
        'cdrs',
        'speedDial',
        'contactBooks',
        'contactGroups'
      ].join(',')
    }

    let params = []

    for (const arg of args) {
      if (typeof arg === 'string') {
        params.push(arg)

        continue
      }

      if (Array.isArray(arg)) {
        params = [...params, ...arg]
      }
    }

    return params.join(',')
  }

  async function _refresh () {
    await localCacherService.indexDB.clearDB()
    localStorageService.removeSyncTimestamp()
    _sync([
      'cdrs',
      'speedDial',
      'contactBooks',
      'contactGroups'
    ])
  }

  return {
    sync: _sync,
    refresh: _refresh,
    getService: _getService
  }
})()

export default indexedDBSyncService
