import store from '@/store'

export default {
  methods: {
    setRingingVolume () {
      const volume =
        store.getters['settings/audioSettingsGetter'].ringingVolume
      const audioElements = [
        document.getElementById('ringing-audio'),
        document.getElementById('test-ringing-audio')
      ]
      audioElements.forEach((item) => {
        item.volume = volume
      })
    },
    setCallerVolume () {
      const volume = store.getters['settings/audioSettingsGetter'].callerVolume
      const audioElements = [
        document.getElementById('caller-audio'),
        document.getElementById('test-caller-audio')
      ]
      audioElements.forEach((item) => {
        item.volume = volume
      })
    },
    setItrVolume () {
      const volume = store.getters['settings/audioSettingsGetter'].itrVolume
      const audioElements = [
        document.getElementById('caller-itr-audio'),
        document.getElementById('test-caller-itr-audio')
      ]
      audioElements.forEach((item) => {
        item.volume = volume
      })
    },
    setRecordingsVolume () {
      const volume =
        store.getters['settings/audioSettingsGetter'].recordingsVolume
      const audioElements = [
        document.getElementById('recordings-audio'),
        document.getElementById('test-recordings-audio')
      ]
      audioElements.forEach((item) => {
        if (item) {
          console.log('audioElements', item)
          item.volume = volume
        }
      })
    },
    setNotificationVolume () {
      const volume =
        store.getters['settings/audioSettingsGetter'].notificationVolume
      const audioElements = [
        document.getElementById('notification-audio'),
        document.getElementById('test-notification-audio')
      ]
      audioElements.forEach((item) => {
        if (item) {
          console.log('audioElements', item)
          item.volume = volume
        }
      })
    }
  }
}
