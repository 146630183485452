import state from '@/store/adr/state'
import * as getters from '@/store/adr/getters'
import * as mutations from '@/store/adr/mutations'
import * as actions from '@/store/adr/actions'

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  state
}
