export const heldUpdateMutator = async (state) => {
  state.updated = !state.updated
}

export const heldMutator = async (state, payload) => {
  // Civic
  state.heldState.civicAddress.country = payload?.held?.country
  state.heldState.civicAddress.A1 = payload?.held?.A1
  state.heldState.civicAddress.A2 = payload?.held?.A2 || ''
  state.heldState.civicAddress.A3 = payload?.held?.A3
  state.heldState.civicAddress.A4 = payload?.held?.A4 || ''
  state.heldState.civicAddress.A5 = payload?.held?.A5 || ''
  state.heldState.civicAddress.PRM = payload?.held?.PRM || ''
  state.heldState.civicAddress.PRD = payload?.held?.PRD || ''
  state.heldState.civicAddress.RD = payload?.held?.RD
  state.heldState.civicAddress.STS = payload?.held?.STS || ''
  state.heldState.civicAddress.POD = payload?.held?.POD || ''
  state.heldState.civicAddress.POM = payload?.held?.POM || ''
  state.heldState.civicAddress.RDSEC = payload?.held?.RDSEC || ''
  state.heldState.civicAddress.RDBR = payload?.held?.RDBR || ''
  state.heldState.civicAddress.RDSUBBR = payload?.held?.RDSUBBR || ''
  state.heldState.civicAddress.HNO = payload?.held?.HNO
  state.heldState.civicAddress.HNS = payload?.held?.HNS || ''
  state.heldState.civicAddress.LMK = payload?.held?.LMK || ''
  state.heldState.civicAddress.FLR = payload?.held?.FLR || ''
  state.heldState.civicAddress.NAM = payload?.held?.NAM || ''
  state.heldState.civicAddress.PC = payload?.held?.PC
  state.heldState.civicAddress.BLD = payload?.held?.BLD || ''
  state.heldState.civicAddress.UNIT = payload?.held?.UNIT || ''
  state.heldState.civicAddress.ROOM = payload?.held?.ROOM || ''
  state.heldState.civicAddress.SEAT = payload?.held?.SEAT || ''
  state.heldState.civicAddress.PLC = payload?.held?.PLC || ''
  state.heldState.civicAddress.PCN = payload?.held?.PCN || ''
  state.heldState.civicAddress.POBOX = payload?.held?.POBOX || ''
  state.heldState.civicAddress.ADDCODE = payload?.held?.ADDCODE || ''
  state.heldState.civicAddress.LOC = payload?.held?.LOC || ''

  // Location
  state.heldState.lat = payload?.held?.lat
  state.heldState.lng = payload?.held?.lng
  // Altitude
  state.heldState.altitude = payload?.held?.altitude || ''
  // Method
  state.heldState.method = payload?.held?.method
  // Confidence
  state.heldState.confidence = payload?.held?.confidence
  // Radius
  state.heldState.radius = payload?.held?.radius
  // I3 call info
  state.heldState.semiMajorAxis = payload?.held?.semiMajorAxis
  state.heldState.semiMinorAxis = payload?.held?.semiMinorAxis
  state.heldState.verticalAxis = payload?.held?.verticalAxis
}

export const what3wordsAddressMutator = async (state, payload) => {
  state.what3wordsAddress = payload
}
export const clearWhat3wordsAddressMutator = async state => {
  state.what3wordsAddress = null
}

export const clearHeldMutator = async state => {
  // Civic
  state.heldState.civicAddress.country = null
  state.heldState.civicAddress.A1 = null
  state.heldState.civicAddress.A2 = null
  state.heldState.civicAddress.A3 = null
  state.heldState.civicAddress.A4 = null
  state.heldState.civicAddress.A5 = null
  state.heldState.civicAddress.PRM = null
  state.heldState.civicAddress.PRD = null
  state.heldState.civicAddress.RD = null
  state.heldState.civicAddress.STS = null
  state.heldState.civicAddress.POD = null
  state.heldState.civicAddress.POM = null
  state.heldState.civicAddress.RDSEC = null
  state.heldState.civicAddress.RDBR = null
  state.heldState.civicAddress.RDSUBBR = null
  state.heldState.civicAddress.HNO = null
  state.heldState.civicAddress.HNS = null
  state.heldState.civicAddress.LMK = null
  state.heldState.civicAddress.FLR = null
  state.heldState.civicAddress.NAM = null
  state.heldState.civicAddress.PC = null
  state.heldState.civicAddress.BLD = null
  state.heldState.civicAddress.UNIT = null
  state.heldState.civicAddress.ROOM = null
  state.heldState.civicAddress.SEAT = null
  state.heldState.civicAddress.PLC = null
  state.heldState.civicAddress.PCN = null
  state.heldState.civicAddress.POBOX = null
  state.heldState.civicAddress.ADDCODE = null
  state.heldState.civicAddress.LOC = null
  // Location
  state.heldState.lat = null
  state.heldState.lng = null
  // Altitude
  state.heldState.altitude = null
  // Method
  state.heldState.method = null
  // Confidence
  state.heldState.confidence = null
  // Radius
  state.heldState.radius = null
  // I3 call info
  state.heldState.semiMajorAxis = null
  state.heldState.semiMinorAxis = null
  state.heldState.verticalAxis = null
}
