export default function logosApiModule (instance) {
  return {

    /**
     * Return list of logos
     * @param data
     * @returns {Promise<*>}
     */
    getAll: async (data) => {
      const resource = '/logos'

      const response = await instance.get(resource, data)

      return response
    },

    /**
     * Get logo by realm
     * @param realm
     * @returns {Promise<*>}
     */
    get: async (realm) => {
      const resource = `/logos/${realm}`

      const response = await instance.get(resource)

      return response
    },

    /**
     * Upload logo
     * @param payload
     * @returns {Promise<*>}
     */
    upload: async (payload) => {
      const resource = '/logos'

      const formData = new FormData()

      formData.append('realm', payload.realm)
      formData.append('logo', payload.logo)

      return await instance.post(resource, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },

    /**
     * Delete logo by realm
     * @param realm
     * @returns {Promise<*>}
     */
    delete: async (realm) => {
      const resource = `/logos/${realm}`

      const response = await instance.delete(resource)

      return response
    }

  }
}
