var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DialogShared',{attrs:{"max-width":"900","title":_vm.entityDialog.title},model:{value:(_vm.entityDialog.show),callback:function ($$v) {_vm.$set(_vm.entityDialog, "show", $$v)},expression:"entityDialog.show"}},[_c('v-card',{attrs:{"tile":"","flat":"","color":"secondary_card"}},[_c('v-card-text',[_c('realm-dashboard-form',{key:_vm.entityDialog.key,attrs:{"entity":_vm.entity,"realm":_vm.realm},on:{"afterSubmit":_vm.afterSubmit}})],1)],1)],1),_c('div',{staticClass:"d-flex align-center mb-2"},[_c('v-spacer'),_c('v-select',{staticClass:"mr-2 mb-2",staticStyle:{"max-width":"300px"},attrs:{"hide-details":"","items":_vm.realms},on:{"input":_vm.loadDashboards},model:{value:(_vm.realm),callback:function ($$v) {_vm.realm=$$v},expression:"realm"}}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":"error","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('actions'))+" ")])]}}])},[_c('v-list',[(_vm.realms)?_c('v-subheader',[_vm._v(_vm._s(_vm.$t('realm_actions')))]):_vm._e(),(_vm.realm)?_c('v-list-item',{attrs:{"clickable":""},on:{"click":function($event){return _vm.resetAllDashboardsInRealmClick()}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('reset_all_dashboars_for_selected_realm')))])],1)],1):_vm._e(),_c('v-subheader',[_vm._v(_vm._s(_vm.$t('global_actions')))]),_c('v-list-item',{attrs:{"clickable":""},on:{"click":function($event){return _vm.resetAllDashboardsForAllRealmsClick()}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('reset_all_dashboards_for_all_realms')))])],1)],1),_c('v-list-item',{attrs:{"clickable":""},on:{"click":function($event){return _vm.resetDashboarsForAllUserClick()}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('reset_user_dashboards')))])],1)],1)],1)],1)],1),_c('v-data-table',{staticClass:"transparent-background",attrs:{"dark":_vm.$vuetify.theme.dark,"loading":_vm.table.loading,"title":_vm.$t('realm_dashboards'),"items":_vm.table.data,"headers":_vm.table.columns},scopedSlots:_vm._u([{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"size":"sm"}},[_vm._v(_vm._s(item.configuration.icon))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"color":"green","small":""},on:{"click":function($event){return _vm.editDashboardClick(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('edit')))])])],1),_c('v-btn',{staticClass:"mr-1",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.dashboardConfigClick(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-cog-outline ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('configure')))])])],1),_c('v-btn',{staticClass:"mr-1",attrs:{"color":"green","small":""},on:{"click":function($event){return _vm.resetDashboardForRealmClick(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-backup-restore ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('reset')))])])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }