/**
 * Get speed dial contacts getter
 * @param state
 * @returns {[]|*}
 */
export const getSpeedDialContactsGetter = state => {
  return state.speedDialContacts
}

/**
 * Get filtered speed dial  buttons
 * @param state
 * @returns {[]}
 */
export const getFilteredSpeedDialGetter = state => {
  return state.filteredSpeedDialContacts
}
/**
 * Get current folder from store
 * @param state
 * @returns {null}
 */
export const getCurrentFolderGetter = state => {
  return state.currentFolder
}
/**
 * Get current contact in context getter
 * @param state
 * @returns {null|*}
 */
export const getCurrentContactGetter = state => {
  return state.currentContact
}
