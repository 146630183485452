<template>
  <h3>About us text...</h3>
</template>

<script>
export default {
  name: 'AboutInformationComponent'
}
</script>

<style scoped>

</style>
