import instance from '@/modules/api/csp/instance'

/**
 * Extendable proxy service component
 */
export default {

  resource () {
    return ''
  },

  setToken (token) {
    instance.defaults.headers.common['X-Auth-Token'] = `${token}`
  },

  get (endpoint, data) {
    const resource = `/${this.resource()}?endpoint_key=${endpoint}`

    return instance.get(resource, data)
  },

  post (endpoint, data) {
    const resource = `/${this.resource()}?endpoint_key=${endpoint}`

    return instance.post(resource, data)
  },

  patch (endpoint, data) {
    const resource = `/${this.resource()}?endpoint_key=${endpoint}`

    return instance.patch(resource, data)
  },

  put (endpoint, data) {
    const resource = `/${this.resource()}?endpoint_key=${endpoint}`

    return instance.put(resource, data)
  },

  delete (endpoint, data) {
    const resource = `/${this.resource()}?endpoint_key=${endpoint}`

    return instance.delete(resource, data)
  }

}
