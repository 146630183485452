import { liveQuery } from 'dexie'
import { useObservable } from '@vueuse/rxjs'

export default function speedDialDBModule (db) {
  db.version(15).stores({
    speedDial: '++id'
  })

  return {
    insert: (data) => {
      data.id = parseInt(data.id)

      db.speedDial.add(data)
    },
    insertMany: (data) => {
      data.forEach(d => {
        d.id = parseInt(d.id)

        db.speedDial.add(d)
      })
    },
    get: (filter) => {
      return useObservable(
        liveQuery(async () => {
          filter = filter || {}
          filter.parent_id = parseInt(filter.parent_id) || null

          return await db.speedDial.filter((value) => {
            return value.parent_id === filter.parent_id
          }).toArray()
        })
      )
    },
    delete: (id) => {
      db.speedDial.delete(id)
    },
    edit: (id, data) => {
      db.speedDial.update(id, data)
    },
    clear: () => {
      db.speedDial.clear()
    },
    last: () => {
      return db.speedDial.orderBy('id').last()
    },
    count: (filter) => {
      return useObservable(
        liveQuery(async () => {
          return db.speedDial.count()
        })
      )
    },
    sync: (data) => {
      data.forEach(async d => {
        d.id = parseInt(d.id)

        if (await db.speedDial.get(d.id)) {
          db.speedDial.update(d.id, d)
        } else {
          db.speedDial.add(d)
        }
      })
    }
  }
}
