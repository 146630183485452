import Socket from '@/modules/socket/socket.module'

export default {
  install (Vue) {
    let url = process.env.VUE_APP_SOCKET_URL

    if (process.env.VUE_APP_SOCKET_PORT.trim().length) {
      url += `:${process.env.VUE_APP_SOCKET_PORT}`
    }

    Vue.prototype.$socket = new Socket(url)
  }
}
