import apiService from '@/modules/api/csp'
import LogEventEnum from '@/plugins/i3-logger/enums/i3-logger.enum'
import LogEventSchema from '@/plugins/i3-logger/schemas/LogEvent.schema'
import CallLogEventSchema from '@/plugins/i3-logger/schemas/CallLogEvent.schema'
import LocationLogEventSchema from '@/plugins/i3-logger/schemas/LocationLogEvent.schema'
import helpers from '@/plugins/i3-logger/helpers/helper'
import store from '@/store'

export default class I3Logger {
  static queryId = null;
  constructor (uuid) {
    if (uuid) {
      I3Logger.queryId = uuid
    }
  }

  i3LogEvent (session, logEventName, responseData = null, subEventName = null) {
    if (!store.getters['settings/getGlobalSetting']('i3-logger')?.value?.active) return
    let payload = ''
    switch (logEventName) {
      case LogEventEnum.CallStartLogEvent:
        payload = this.CallStartLogEvent(session, logEventName)
        apiService.i3Logger.callStartLogEvent(payload)
        break
      case LogEventEnum.CallEndLogEvent:
        payload = this.CallEndLogEvent(session, logEventName)
        apiService.i3Logger.callEndLogEvent(payload)
        break
      case LogEventEnum.LocationQueryLogEvent:
        this.LocationQueryLogEvent(session, logEventName, I3Logger.queryId)
        break
      case LogEventEnum.LocationResponseLogEvent:
        this.LocationResponseLogEvent(session, logEventName, responseData, I3Logger.queryId)
        break
      case LogEventEnum.AdditionalDataQueryLogEvent:
        this.AdditionalDataQueryLogEvent(session, logEventName)
        break
      case LogEventEnum.AdditionalDataResponseLogEvent:
        this.additionalDataResponseLogEvent(session, logEventName, responseData, I3Logger.queryId, subEventName)
        break
      default:
        break
    }
  }

  CallStartLogEvent (session, logEventName) {
    /* CallStartLogEvent:
      allOf:
        - $ref: '#/components/schemas/LogEvent'
        - $ref: '#/components/schemas/CallLogEvent' */
    const logEvent = new LogEventSchema().logEvent(session, logEventName)
    const callLogEvent = new CallLogEventSchema().callLogEvent(session)
    return {
      ...logEvent,
      ...callLogEvent
    }
  }

  CallEndLogEvent (session, logEventName) {
    /* CallEndLogEvent:
      allOf:
        - $ref: '#/components/schemas/LogEvent'
        - $ref: '#/components/schemas/CallLogEvent' */
    const logEvent = new LogEventSchema().logEvent(session, logEventName)
    const callLogEvent = new CallLogEventSchema().callLogEvent(session)
    return {
      ...logEvent,
      ...callLogEvent
    }
  }

  LocationQueryLogEvent (session, logEventName, queryId) {
    const logEvent = new LogEventSchema().logEvent(session, logEventName)
    const locationLogEventSchema = new LocationLogEventSchema().LocationQueryLogEvent(session, queryId)
    const payload = {
      ...logEvent,
      ...locationLogEventSchema
    }
    apiService.i3Logger.locationQueryLogEvent(payload)
  }

  LocationResponseLogEvent (session, logEventName, responseData, queryId) {
    /* LocationResponseLogEvent:
      allOf:
        - $ref: '#/components/schemas/LogEvent'
        - type: object
          required:
            - text
            - direction
            - responseId
          properties:
            text:
              type: string
            direction:
              $ref: '#/components/schemas/Direction'
            responseStatus:
              type: string
            description: Values limited to those in the Status Codes registry
            responseId:
              type: string */
    const logEvent = new LogEventSchema().logEvent(session, logEventName)
    const locationLogEventSchema = new LocationLogEventSchema().LocationResponseLogEvent(session, responseData, queryId)
    const payload = {
      ...logEvent,
      ...locationLogEventSchema
    }
    apiService.i3Logger.locationResponseLogEvent(payload)
  }

  AdditionalDataQueryLogEvent (session, logEventName) {
    const adrTypes = helpers().mapADR()
    /* AdditionalDataQueryLogEvent:
      allOf:
        - $ref: '#/components/schemas/LogEvent' */
    adrTypes.forEach(item => {
      const logEvent = new LogEventSchema().logEvent(session, logEventName, item)
      const locationLogEventSchema = new LocationLogEventSchema().AdditionalDataQueryLogEvent(session, item)
      const payload = {
        ...logEvent,
        ...locationLogEventSchema
      }
      apiService.i3Logger.additionalDataQueryLogEvent(payload)
    })
  }

  additionalDataResponseLogEvent (session, logEventName, responseData, queryId, subEventName) {
    /* AdditionalDataResponseLogEvent:
      allOf:
        - $ref: '#/components/schemas/LogEvent'
        - type: object
          required:
            - text
            - direction
            - responseId
          properties:
            text:
              type: string
            direction:
              $ref: '#/components/schemas/Direction'
            responseStatus:
              type: string
            description: Values limited to those in the Status Codes registry
            responseId:
              type: string */
    const logEvent = new LogEventSchema().logEvent(session, logEventName, subEventName)
    const locationLogEventSchema = new LocationLogEventSchema().AdditionalDataResponseLogEvent(session, responseData, queryId)
    const payload = {
      ...logEvent,
      ...locationLogEventSchema
    }
    apiService.i3Logger.additionalDataResponseLogEvent(payload)
  }
}
