const cacheControl = {
  getTimestamp (module, action = 'get') {
    if (action === 'replace') {
      return this.setTimestamp(module)
    }
    return this.checkTimestamp(module)
  },
  checkTimestamp (module) {
    const cache = JSON.parse(localStorage.getItem('cache'))
    if (cache?.[module]) {
      return cache[module]
    } else {
      return this.setTimestamp(module)
    }
  },
  setTimestamp (module) {
    const timeStamp = Math.floor(Date.now() / 1000)

    let cache = JSON.parse(localStorage.getItem('cache'))
    if (!cache) {
      cache = {}
    }
    cache[module] = `timestamp=${timeStamp}`
    localStorage.setItem('cache', JSON.stringify(cache))
    return timeStamp
  }
}
export default cacheControl
