export const setLayoutsMutator = async (state, payload) => {
  state.layouts = payload
}
export const setTemplateMutator = async (state, payload) => {
  state.layout.template = payload
}
export const setSlotsMutator = async (state, payload) => {
  state.layout.slots = payload
}

export const setWidgetSettingsMutator = async (state, payload) => {
  state.layout.widget_settings = payload
}
