<template>
  <v-slider
    thumb-color="success"
    min="0"
    max="100"
    color="success"
    thumb-label="always"
    :value="audioSettingsGetter.callerVolume * 100"
    @change="changeCallerVolume"
  >
    <template v-slot:prepend>
      <div class="audio-helper-icons d-flex justify-end">
        <v-tooltip v-if="testCallerGetter" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon @click="toggleTestAudio(false)" v-bind="attrs" v-on="on">
              mdi-stop
            </v-icon>
          </template>
          <span>{{ $t('stop_ringtone')}}</span>
        </v-tooltip>
        <v-tooltip v-else bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon @click="toggleTestAudio(true)" v-bind="attrs" v-on="on">
              mdi-play
            </v-icon>
          </template>
          <span>{{ $t('play_ringtone')}}</span>
        </v-tooltip>
      </div>
    </template>
  </v-slider>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import localStorageService from '@/util/local-storage-service'
import audioVolumeMixin from '@/mixins/audio/audio-volume.mixin'

export default {
  name: 'CallerVolumeComponent',
  mixins: [audioVolumeMixin],
  data: () => ({
    playing: false
  }),
  computed: {
    ...mapGetters('settings', [
      'audioSettingsGetter'
    ]),
    ...mapGetters('audioVolume', [
      'testCallerGetter'
    ])
  },
  methods: {
    ...mapMutations('audioVolume', [
      'testCallerMutator'
    ]),
    changeCallerVolume (val) {
      localStorageService.setCallerVolume(val / 100)
      this.setCallerVolume()
    },
    toggleTestAudio (play) {
      this.testCallerMutator(play)
    }
  }
}
</script>
