export const audioSettingsGetter = (state) => {
  return state.audioSettings
}
export const settingsProxyGetter = state => {
  return state.settingsProxyState
}

export const sipAddressesGetter = state => {
  return state.addresses
}

export const sipDefaultAddressGetter = state => {
  return state.sipDefaultAddress
}

export const egressAuthGetter = state => {
  return state.egressAuthState
}

export const accountsGetter = (state) => {
  return state.accounts
}

export const accountNamesGetter = (state) => {
  return state.accountNames
}

export const userSettingsGetter = state => {
  return state.userSettings
}

export const globalSettingsGetter = state => {
  return state.globalSettings
}

export const getUserSetting = state => (name) => {
  return state.userSettings.find(item => item.setting.name === name)
}

export const getGlobalSetting = state => (name) => {
  return state.globalSettings.find(item => item.name === name)
}

export const getPrerecordedAudiosGetting = state => {
  return state.prerecordedAudios
}

export const getPrerecordedAudisGetting = state => (name) => {
  return state.prerecordedAudios.find(item => item.name === name)
}
