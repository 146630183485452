export default function callLinesApiModule (instance) {
  return {

    /**
     * Return list of realm settings
     * @param realmName
     * @returns {Promise<*>}
     */
    load: async (realmName = null) => {
      let resource = '/custom-call-lines'

      if (realmName) {
        resource = `/custom-call-lines?realm_name=${realmName}`
      }

      const response = await instance.get(resource)

      return response
    }
  }
}
