import lodash from 'lodash'

export const layoutsGetter = state => {
  return state.layouts
}
export const layoutGetter = state => {
  return state.layout
}
export const defaultTemplatesGetter = state => {
  return state.layout.default_templates
}
export const defaultSlotsGetter = state => {
  return state.layout.default_slots
}
export const emptySlotsGetter = state => {
  return state.layout.empty_slots
}
export const defaultWidgetSettings = state => {
  return state.layout.default_widget_settings
}
export const getWidgetSettings = state => {
  return lodash.merge(state.layout.widget_settings, state.layout.default_widget_settings)
}
