import Vue from 'vue'
import VueI18n from 'vue-i18n'

import messages from '@/i18n'
import localStorageService from '@/util/local-storage-service'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: localStorageService.getLanguage(), // set locale
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
  silentTranslationWarn: process.env.NODE_ENV === 'production'
})

export default i18n
