import store from '@/store'

export default class Serial {
  _devices = []
  _data = null

  constructor () {
    this.loadDevices().then(() => this.openAll())
  }

  async loadDevices () {
    this._devices = []

    const devices = await navigator.serial.getPorts()

    for (let i = 0; i < devices.length; i++) {
      this._devices.push({ device: devices[i], isOpen: false, BaudRate: 9600 })
    }

    await store.commit('global/serialMutator', [...this._devices])
  }

  async checkDevice (device) {
    device = await this.openDevice({ device, BaudRate: 9600 })

    await this.writeToDevice(device.device, new Uint8Array([0xFF, 0x01, 0x03]))

    let data = null

    data = await this.readFromDevice(device.device)

    if (data.length !== 3) return false
    if (data[0] !== 0xFF || data[1] !== 0x01 || data[2] !== 0x00) return false

    return true
  }

  async readFromDevice (device) {
    const reader = device.readable.getReader()

    const { value, done } = await reader.read()

    if (done) {
      // Allow the serial port to be closed later.
      reader.releaseLock()
    }

    return value
  }

  async writeToDevice (device, data) {
    if (!device.writable) return

    const writer = device.writable.getWriter()

    await writer.write(data)

    await writer.close()
  }

  async requestPair () {
    await navigator.serial.requestPort()

    await this.loadDevices()
  }

  async openAll () {
    const devices = []

    const promises = this._devices.map(async d => {
      devices.push(await this.openDevice({ ...d }))
    })

    await Promise.all(promises)

    this._devices = devices

    store.commit('global/serialMutator', [...this._devices])
  }

  async openMany (devices) {
    const _devices = []

    const promises = this.devices.map(async d => {
      const length = devices.length

      for (let i = 0; i < length; i++) {
        if (this.sameDevice(d, devices[i])) {
          _devices.push(await this.openDevice({ ...d }))

          return
        }
      }

      _devices.push(d)
    })

    await Promise.all(promises)

    this._devices = _devices

    store.commit('global/serialMutator', [...this._devices])
  }

  async openDevice (device) {
    if (device.device.writable) {
      device.isOpen = true

      return device
    }

    await device.device.open({ baudRate: device.BaudRate })
    device.device.addEventListener('disconnect', this.handleDisconnect.bind(this))

    device.isOpen = true

    return device
  }

  async closeAll () {
    const _devices = []

    const devices = this.devices.map(async d => {
      const device = await this.closeDevice({ ...d })

      _devices.push(device)
    })

    await Promise.all(devices)

    this._devices = _devices

    store.commit('global/serialMutator', [...this._devices])
  }

  async closeMany (devices) {
    const _devices = []

    const promises = this.devices.map(async d => {
      const length = devices.length

      for (let i = 0; i < length; i++) {
        if (this.sameDevice(d, devices[i])) {
          _devices.push(await this.closeDevice({ ...d }))

          return
        }
      }

      _devices.push(d)
    })

    await Promise.all(promises)

    this._devices = _devices

    store.commit('global/serialMutator', [...this._devices])
  }

  async closeDevice (device) {
    if (!device.device.writable) {
      device.isOpen = false

      return device
    }

    await device.device.close()
    device.device.removeEventListener('disconnect', this.handleDisconnect)

    device.isOpen = false

    return device
  }

  sameDevice (first, second) {
    return (
      first.device.getInfo().usbProductId === second.device.getInfo().usbProductId &&
      first.device.getInfo().usbVendorId === second.device.getInfo().usbVendorId
    )
  }

  async toggleOnAll () {
    const data = new Uint8Array([0xA0, 0x01, 0x01, 0xA2]) // Command to light up controller

    const first = new Uint8Array([0xFF, 0x01, 0x01]) // Command to light up double controller
    const second = new Uint8Array([0xFF, 0x02, 0x01]) // Command to light up double controller

    this.devices.forEach(async d => {
      if (!d.device.writable) return

      const writer = d.device.writable.getWriter()

      await writer.write(data)
      await writer.write(first)
      await writer.write(second)

      await writer.releaseLock()
    })
  }

  async toggleOffAll () {
    const data = new Uint8Array([0xA0, 0x01, 0x00, 0xA1]) // Command to light down controller

    const first = new Uint8Array([0xFF, 0x01, 0x00]) // Command to light down double controller
    const second = new Uint8Array([0xFF, 0x02, 0x00]) // Command to light down double controller

    this.devices.forEach(async d => {
      if (!d.device.writable) return

      const writer = d.device.writable.getWriter()

      await writer.write(data)
      await writer.write(first)
      await writer.write(second)

      await writer.releaseLock()
    })
  }

  get devices () {
    return this._devices
  }

  async turnOffOffhook () {
    const data = new Uint8Array([0xFF, 0x01, 0x00])

    this.devices.forEach(async d => {
      if (!d.device.writable) return

      const writer = d.device.writable.getWriter()

      await writer.write(data)

      await writer.releaseLock()
    })
  }

  async turnOnOffhook () {
    // const data = new Uint8Array([0xA0, 0x01, 0x01, 0xA2]) // Command to light up controller

    const data = new Uint8Array([0xFF, 0x01, 0x01]) // Command to light up double controller

    this.devices.forEach(async d => {
      if (!d.device.writable) return

      const writer = d.device.writable.getWriter()

      await writer.write(data)

      await writer.releaseLock()
    })
  }

  async turnOffAuxilarity () {
    const data = new Uint8Array([0xFF, 0x02, 0x00])

    this.devices.forEach(async d => {
      if (!d.device.writable) return

      const writer = d.device.writable.getWriter()

      await writer.write(data)

      await writer.releaseLock()
    })
  }

  async turnOnAuxilarity () {
    const data = new Uint8Array([0xFF, 0x02, 0x01])

    this.devices.forEach(async d => {
      if (!d.device.writable) return

      const writer = d.device.writable.getWriter()

      await writer.write(data)

      await writer.releaseLock()
    })
  }

  removeDevice (device) {
    const _devices = this.devices.filter(d => !this.sameDevice(d, { device }))

    this._devices = _devices

    store.commit('global/serialMutator', [...this._devices])
  }

  handleDisconnect (e) {
    this.removeDevice(e.currentTarget)

    const event = new Event('serial-device-disconnect')

    event.eventData = e

    document.dispatchEvent(event)
  }
}
