<template>
  <v-select
    :label="$t('caller_output')"
    :items="audioSettingsGetter.audioOutputs"
    item-value="deviceId"
    item-text="label"
    return-object
    :value="audioSettingsGetter.callerOutputDeviceId"
    @change="updateCallerOutput"
  ></v-select>
</template>
<script>
import { mapGetters } from 'vuex'
import localStorageService from '@/util/local-storage-service'

export default {
  name: 'CallerOutputComponent',
  computed: {
    ...mapGetters('settings', [
      'audioSettingsGetter'
    ])
  },
  methods: {
    updateCallerOutput (device) {
      localStorageService.setCallerOutputId(device.deviceId)
    }
  }
}
</script>
