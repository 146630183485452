<template>
  <div>

    <v-form  ref="form" @submit.prevent="saveUserLayout" novalidate>
      <v-card tile flat color="secondary_card">
        <v-card-text>

          <v-select
            :label="$t('layout')"
            v-model="current"
            :items="dashboardsGetter"
            item-value="dashboard_id"
            item-text="name"
          ></v-select>

        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="saveUserLayout">{{ $t('save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import localStorageService from '@/util/local-storage-service'

export default {
  name: 'LayoutSettingTab',
  data: () => ({
    loading: false,
    current: null,
    layouts: []
  }),

  computed: {
    ...mapGetters('global', ['authUserGetter']),
    ...mapGetters('dashboard', ['dashboardsGetter', 'defaultUserDashboardGetter'])
  },

  methods: {

    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    async saveUserLayout () {
      await localStorageService.setDefaultDashboard(this.current)

      location.reload()
    }
  },
  mounted () {
    if (this.authUserGetter && this.defaultUserDashboardGetter) {
      this.current = this.defaultUserDashboardGetter.dashboard_id
    }
  }
}
</script>
