import { liveQuery } from 'dexie'
import { useObservable } from '@vueuse/rxjs'

export default function callHistoryDBModule (db) {
  db.version(15).stores({
    callHistory: '++id, call_from'
  })

  return {
    insert: (data) => {
      data.id = parseInt(data.id)

      db.callHistory.add(data)
    },
    insertMany: (data) => {
      data.forEach(d => {
        d.id = parseInt(d.id)

        db.callHistory.add(d)
      })
    },
    get: (filter) => {
      return useObservable(
        liveQuery(async () => {
          let tmp = null

          filter = filter || {}

          if (filter.call_from) {
            filter.call_from = filter.call_from.replaceAll('+', '\\+')

            tmp = db.callHistory.filter((value) => {
              return value.call_from.match(new RegExp(filter.call_from, 'ig'))
            }).reverse()
          } else {
            tmp = db.callHistory.reverse()
          }

          if (filter.offset) {
            tmp = tmp.offset(filter.offset)
          }

          if (filter.limit) {
            tmp = tmp.limit(filter.limit)
          }

          return await tmp.toArray()
        })
      )
    },
    clear: () => {
      db.callHistory.clear()
    },
    last: () => {
      return db.callHistory.orderBy('id').last()
    },
    count: (filter) => {
      return useObservable(
        liveQuery(async () => {
          if (filter && filter.call_from) {
            filter.call_from = filter.call_from.replaceAll('+', '\\+')

            return db.callHistory.filter((value) => {
              return value.call_from.match(new RegExp(filter.call_from, 'ig'))
            }).count()
          }

          return db.callHistory.count()
        })
      )
    },
    sync: (data) => {
      data.forEach(async d => {
        d.id = parseInt(d.id)

        if (await db.callHistory.get(d.id)) {
          db.callHistory.update(d.id, d)
        } else {
          db.callHistory.add(d)
        }
      })
    }
  }
}
