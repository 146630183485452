/**
 * Get dashboard icons
 * @param state
 * @returns {[]}
 */
export const dashboardIconsGetter = (state) => {
  return state.dashboardIcons
}

/**
 * Show Dashboard Getter
 * @param state
 * @returns {boolean}
 */
export const showDashboardGetter = (state) => {
  return state.showDashboard
}

/**
 * Get list of dashboards
 * @param state
 * @returns {[]|*}
 */
export const dashboardsGetter = (state) => {
  return state.dashboards
}

export const defaultUserDashboardGetter = (state) => {
  if (state.currentDashboard) {
    return state.currentDashboard
  }
  return state.dashboards[0]
}

/**
 * Get list of widgets
 * @param state
 * @returns {[]|*}
 */
export const widgetsGetter = (state) => {
  return state.widgets
}

/**
 * Get current dashboard
 * @param state
 * @returns {{}|*}
 */
export const currentDashboardGetter = (state) => {
  // TODO Refactor this and get current dashboard base on dashboard id
  return state.currentDashboard
}
