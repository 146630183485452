<template>
  <v-select
    :label="$t('microphone')"
    :items="audioSettingsGetter.audioInputs"
    item-value="deviceId"
    item-text="label"
    return-object
    @change="updateMicrophone"
    :value="audioSettingsGetter.microphoneDeviceId"
  ></v-select>
</template>
<script>
import { mapGetters } from 'vuex'
import localStorageService from '@/util/local-storage-service'

export default {
  name: 'MicrophoneComponent',
  computed: {
    ...mapGetters('settings', [
      'audioSettingsGetter'
    ])
  },
  methods: {
    async updateMicrophone (device) {
      await localStorageService.setMicrophoneId(device.deviceId)
      await localStorageService.setMicrophoneSource(this.$sip.answeredCallSession)
    }
  }
}
</script>
