import store from '@/store'
import apiService from '@/modules/api/csp'
import localStorageService from '@/util/local-storage-service'

export default class CallLogs {
  clearIncidentID = false

  async saveCallLogs (message, direction) {
    const method = this.getMethod(message)
    const callId = this.getCallId(message)

    const position = localStorageService.getPositionNumber()

    const data = {
      call_id: callId,
      sip_address: store.getters['settings/sipDefaultAddressGetter'].sip_address,
      sip_message: message,
      method: method,
      direction: direction,
      type: 'text',
      write_time: Date.now(),
      position: position ?? null
    }

    await apiService.callLogs.saveCallLogs(data)

    if (this.clearIncidentID) this.clearNenaIncidentID(callId)
  }

  getMethod (message) {
    const regex = new RegExp(/SIP\/2\.0\s\d\d\d\s/i)
    const method = new RegExp(/INVITE sip:|ACK sip:|BYE sip:|CANCEL sip:|REGISTER sip:|OPTIONS sip:|PRACK sip:|SUBSCRIBE sip:|NOTIFY sip:|PUBLISH sip:|INFO sip:|REFER sip:|MESSAGE sip:|UPDATE sip:/g)
    const match = message.match(regex)
    // results for characters and numbers
    let character = message.match(method)?.[0]?.replace(' sip:', '').replace('SIP/2.0 ', '')
    const number = match?.[0]?.replace(' sip:', '').replace('SIP/2.0 ', '')

    if (character) character = character.toUpperCase()

    if (character === 'INVITE') {
      this.setNenaIncidentID(message)
    }

    if (['BYE', 'CANCEL'].indexOf(character) >= 0) {
      this.clearIncidentID = true
    }

    if (!match || character === 'NOTIFY') {
      return character
    } else {
      return number
    }
  }

  getCallId (message) {
    const regex = new RegExp(/Call-ID:\s*([\w-]+)/i)
    const match = message.match(regex)
    return match?.[0]?.replace('Call-ID: ', '')
  }

  setNenaIncidentID (message) {
    const incident = message.match(/incidentid:(.*):/i)

    if (incident) {
      const callID = this.getCallId(message)

      store.commit('callInformation/incidentID', { call_id: callID, incident_id: incident[1] })
    }
  }

  clearNenaIncidentID (callId) {
    const callIDs = store.getters['callInformation/incidentIDGetter']

    delete callIDs[callId]

    store.commit('callInformation/incidentID', { ...callIDs })

    this.clearIncidentID = false
  }
}
