export default {
  data: () => ({
    fixedHeight: 0
  }),
  methods: {
    modifyHeight () {
      const height = this.height = this.$refs['parent-height']?.$el.parentNode.parentElement.clientHeight
      this.fixedHeight = height
    },
    event () {
      window.addEventListener('resize', (e) => {
        this.modifyHeight()
      })
    }
  },
  async mounted () {
    this.event()
    this.modifyHeight()
  }
}
