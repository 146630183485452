import localStorageService from '@/util/local-storage-service'

export default {
  methods: {
    setFontSize () {
      const fontSize = localStorageService.getFontSize()
      const style = document.createElement('style')
      const classList = [
        '.v-alert',
        '.v-chip',
        '.v-select__slot',
        '.v-label',
        '.v-input__slot'
      ]
      style.innerHTML = this.mergeClassString(classList, fontSize)
      document.getElementsByTagName('html')[0].appendChild(style)
      document.getElementsByTagName('html')[0].setAttribute('style', `font-size: ${fontSize || 100}% !important`)
    },
    mergeClassString (ClassList, fontSize) {
      let string = ''
      let fSize = ''
      ClassList.forEach(item => {
        if (item === '.v-select__slot' || item === '.v-input__slot') fSize = fontSize - 20
        string += `${item} {font-size: ${fSize || 100}% !important }`
      })
      return string
    }
  }
}
