<template>
  <v-slider
    :loading="loading"
    color="success"
    readonly
    v-model="inputAudioLevel"
    thumb-color="transparent"
    min="0"
    max="100"
  >
    <template v-slot:prepend>
      <div class="audio-helper-icons d-flex justify-end">
        <v-icon cypress="mdi-play" class="pointer">mdi-microphone</v-icon>
      </div>
    </template>
  </v-slider>
</template>
<script>
export default {
  name: 'InputVolumeComponent',
  options: ['value'],
  data: () => ({
    loading: false,
    inputAudioLevel: 0
  }),
  watch: {
    value (val) {
      this.inputAudioLevel = val
    },
    inputAudioLevel (val) {
      this.value = val
    }
  }
}
</script>
