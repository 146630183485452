export default function contactGroupsLocalCacherModule (indexDB) {
  return {
    get: async function (payload) {
      const data = indexDB.contactGroups.get(payload)

      return data
    },
    updateTable: (data) => {
      indexDB.contactGroups.insertMany(data)
    },
    delete: (id) => {
      indexDB.contactGroups.delete(id)
    },
    edit: (id, data) => {
      indexDB.contactGroups.edit(id, data)
    },
    clear () {
      indexDB.contactGroups.clear()
    },
    last: function () {
      return indexDB.contactGroups.last()
    },
    count: function (filter) {
      return indexDB.contactGroups.count(filter)
    }
  }
}
