<template>
  <v-footer
    app
    outlined
    color="footer"
    absolute
  >
    <v-col
      class="text-center pa-0"
      cols="12"
    >
      <span class="caption">
        Call Handling System v {{ version }}
      </span>
    </v-col>
  </v-footer>
</template>

<script>
export default {
  name: 'FooterComponent',
  data: () => ({
    version: process.env.VUE_APP_VERSION
  })
}
</script>

<style scoped>

</style>
