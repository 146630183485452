
import apiService from '@/modules/api/csp'
import store from '@/store'
import localStorageService from '@/util/local-storage-service'
import localCacherService from '@/modules/cache/localCacher'

export default async function auth ({ next, router }) {
  try {
    // Get and store user authorization from api and put it in store (authUserState)
    const response = await apiService.user.get()
    await store.commit('global/authUserMutator', response.data)

    return next()
  } catch (e) {
    localCacherService.indexDB.clearDB()
    localStorageService.clearLocalStorageOnLogout()
    return router.push({ name: 'Login' }).catch(() => {})
  }
}
