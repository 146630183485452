export const ringingGetter = (state) => {
  return state.ringingState
}

export const testRingingGetter = (state) => {
  return state.testRingingState
}

export const testItrGetter = (state) => {
  return state.testItrState
}

export const testCallerGetter = (state) => {
  return state.testCallerState
}

export const testRecordingsGetter = (state) => {
  return state.testRecordingsState
}

export const testNotificationGetter = (state) => {
  return state.testNotificationState
}
