<template>
  <div>
    <audio :id="audioId" :ref="audioId" />
    <audio :id="audioTestId" :ref="audioTestId" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import store from '@/store'

export default {
  name: 'NotificationAudioComponent',
  data: () => ({
    warningOn: false,
    audioId: 'notification-audio',
    audioTestId: 'test-notification-audio',
    audioIsCreated: false,
    downloadedAudio: null
  }),
  computed: {
    ...mapGetters('audioVolume', ['testNotificationGetter']),
    ...mapGetters('settings', ['audioSettingsGetter']),
    notificationFileUrl () {
      return store.getters['global/getRingtoneByType']('notification_sound')
    }
  },
  methods: {
    ...mapMutations('audioVolume', ['testNotificationMutator'])
  },
  watch: {
    testNotificationGetter (val) {
      const ringtoneURL = this.notificationFileUrl

      if (ringtoneURL) {
        this.$refs[this.audioTestId].src = this.downloadedAudio?.src
      } else {
        this.$refs[
          this.audioTestId
        ].src = require('@/assets/audio/sounds/notification_warning_sound.mp3')
      }

      this.$refs[this.audioTestId].load()
      this.$refs[this.audioTestId].volume =
        this.audioSettingsGetter.notificationVolume
      this.$refs[this.audioTestId].setSinkId(
        this.audioSettingsGetter.notificationOutputDeviceId
      )
      val
        ? this.$refs[this.audioTestId].play()
        : this.$refs[this.audioTestId].pause()
    },
    notificationFileUrl: {
      handler: function (val) {
        if (!this.audioIsCreated && val) {
          this.downloadedAudio = new Audio(val)
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    this.$refs[this.audioTestId].addEventListener('pause', () => {
      this.testNotificationMutator(false)
    })
  }
}
</script>
