import apiService from '@/modules/api/csp'

export const getFccMasterRegistryAction = async ({ commit, dispatch }, payload = {}) => {
  let response = {}

  response = await apiService.fccMasterRegistry.search(payload)

  commit('setFccMasterRegistryMutator', response.data)

  return response
}
