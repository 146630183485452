import apiService from '@/modules/api/csp'

import CallHistoryTransformer from '@/modules/api/csp/transformers/call-history.transformer'

export const saveAbandonedCallAction = async ({ commit, dispatch }, payload = {}) => {
  try {
    await new CallHistoryTransformer(payload.event).setRealm(payload.realm).collectData().then(async data => {
      if (data) {
        await apiService.abandonedCalls.saveAbandonCall(data)
      }
    })
  } catch (e) {
    return true
  }
}

export const setUnresolvedAbandonedCallsCountAction = async ({ commit, dispatch }, payload = 0) => {
  commit('setAbandonedCallsMutator', payload)
}

export const updateAbandonCallStatusAction = async ({ commit, dispatch }, payload = {}) => {
  await apiService.abandonedCalls.updateAbandonCallStatus(payload).then()
}
