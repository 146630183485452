import apiService from '@/modules/api/csp'
import localStorageService from '@/util/local-storage-service'
import i18n from '@/plugins/i18n'
import sipHelper from '@/helpers/sip.helper'
/**
 * Authorize user
 * @param dispatch
 * @param payload
 * @returns {Promise<*>}
 */
export const authorizeAction = async ({ dispatch }, payload) => {
  const response = await apiService.auth.login(payload)

  localStorageService.setUserTokens({
    userAccessToken: response.data.access_token,
    refreshToken: response.data.refresh_token
  })

  return response
}

/**
 * Load translations from api
 * @param context
 * @returns {Promise<void>}
 */
export const loadTranslationsAction = async ({ rootGetters }) => {
  const config = {
    application_id: 1,
    realm: null
  }
  if (process.env.VUE_APP_ID) {
    config.application_id = process.env.VUE_APP_ID
  }
  const sipAddress = rootGetters['settings/sipDefaultAddressGetter'].sip_address

  if (sipAddress) {
    const realm = sipHelper.getRealmFromSip(sipAddress)
    if (realm) {
      config.realm = realm
    }
  }

  await apiService.translations.load(config).then(async (response) => {
    for (const [key, value] of Object.entries(response.data)) {
      await i18n.setLocaleMessage(key, value)
    }
  })
}

/**
 * Logout user
 * @param context
 * @param payload
 * @returns {Promise<boolean>}
 */
export const authLogoutAction = async (context, payload) => {
  localStorage.removeItem('user_access_token')
  localStorage.removeItem('refresh_token')

  context.commit('authUserMutator', null)

  return true
}

/**
 * Has answered call
 * @param context
 * @param payload
 * @returns VoidFunction
 */
export const hasAnsweredCallAction = async (context, payload) => {
  context.commit('hasAnsweredCallMutator', null)
}

export const loadRingtonesAction = async ({ commit, getters }) => {
  await apiService.globalRingtone.getAllDefault().then(globalRingtonesResponse => {
    commit('updateGlobalRingtonesMutator', globalRingtonesResponse.data)
    return globalRingtonesResponse.data
  })

  await apiService.sip.getDefaultSipAddresses().then(async response => {
    if (response != null && response.realm != null) {
      await apiService.realmRingtone.getAllDefault(response.realm).then(realmRingtonesReponse => {
        commit('updateRealmRingtonesMutator', realmRingtonesReponse.data)
        return realmRingtonesReponse.data
      })
    }
  })
}

export const changeFontSizeAction = async (context, payload) => {
  context.commit('changeFontSizeMutator', payload)
}

export const clearingMapAction = async (context, payload) => {
  context.commit('clearingMapMutator', payload)
}
