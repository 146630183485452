<template>
  <div>
    <top-bar-component />
  </div>

</template>

<script>
/* Components */
import TopBarComponent from '@/app/widgets/top-bar-widget/components/TopBarComponent'

export default {
  name: 'TopBarView',
  components: {
    TopBarComponent
  }

}
</script>

<style scoped>

</style>
