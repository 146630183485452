import { mapGetters } from 'vuex'
import apiService from '@/modules/api/csp'
import localStorageService from '@/util/local-storage-service'

export default {
  computed: {
    ...mapGetters('global', ['authUserGetter']),
    ...mapGetters('settings', ['sipDefaultAddressGetter'])
  },
  methods: {
    sendActivityLog (method, data = {}) {
      return apiService.callActivityLogs.sendActivityLog({
        url: window.location.pathname,
        type: 'text',
        email: data.email ?? this.authUserGetter?.email,
        method: method,

        request: data.request ?? '',
        request_headers: data.request_headers ?? '',

        status: data.status ?? '',
        response: data.response ?? '',
        response_headers: data.response_headers ?? '',

        log_level: data.log_level ?? 'info',
        user_id: data.userID ?? this.authUserGetter?.id,
        position: localStorageService.getPositionNumber() || null,
        write_time: Date.now(),
        sip_address: this.sipDefaultAddressGetter?.sip_address
      })
    }
  }
}
