import permissionHelper from '@/helpers/permission.helper'

/**
 * Permission mixin
 */
export default {
  methods: {

    /**
     * Check is user has any permission
     * @param permissions
     * @returns {boolean|*}
     */
    $canAny (permissions) {
      return permissionHelper.canAny(permissions)
    },

    /**
     * Check if user has permission
     *
     * @param permissionName
     * @returns {boolean|*}
     */
    $can (permissionName) {
      return permissionHelper.can(permissionName)
    }
  }
}
