export const callDetailsGetter = state => {
  return state.callDetails
}

export const callDetailsHeldGetter = state => {
  return state.callDetailsHeld
}

export const callDetailsAdrGetter = state => {
  return state.callDetailsAdr
}

export const callDetailsModalGetter = state => {
  return state.callDetailsModal
}
