import apiService from '@/modules/api/csp'
import sipHelper from '@/helpers/sip.helper'

/**
 * Load logo and set in store
 * @param commit
 * @param rootGetters
 * @returns {Promise<void>}
 */
export const loadLogoAction = async ({ commit, rootGetters }) => {
  try {
    const sipAddress = rootGetters['settings/sipDefaultAddressGetter'].sip_address
    const realm = sipHelper.getRealmFromSip(sipAddress)

    await apiService.logos.get(realm).then(response => {
      commit('logoMutation', response.data)
    }).catch(e => {
      commit('logoMutation', null)
    })
  } catch (e) {
    console.error(e.message)
  }
}
