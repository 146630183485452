<template>
  <div>
    <audio id="caller-audio" ref="caller-audio"></audio>
    <audio id="test-caller-audio" ref="test-caller-audio" :src="require('@/assets/audio/sounds/caller-audio-test.mp3')"></audio>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'CallerAudioComponent',
  data: () => ({
    ringing: false
  }),
  computed: {
    ...mapGetters('audioVolume', [
      'testCallerGetter'
    ]),
    ...mapGetters('settings', [
      'audioSettingsGetter'
    ])
  },
  methods: {
    ...mapMutations('audioVolume', [
      'testCallerMutator'
    ]),
    setRemoteAudio (event) {
      event.eventData.session.connection.ontrack = async e => {
        this.$refs['caller-audio'].srcObject = e.streams[0]
        await this.$refs['caller-audio'].setSinkId(this.audioSettingsGetter.callerOutputDeviceId)
        await this.$refs['caller-audio'].play()
      }
    }
  },
  watch: {
    testCallerGetter (val) {
      this.$refs['test-caller-audio'].load()
      this.$refs['test-caller-audio'].volume = this.audioSettingsGetter.callerVolume
      this.$refs['test-caller-audio'].setSinkId(this.audioSettingsGetter.callerOutputDeviceId)
      val ? this.$refs['test-caller-audio'].play() : this.$refs['test-caller-audio'].pause()
    },
    'audioSettingsGetter.callerOutputDeviceId' (val) {
      this.$refs['caller-audio'].setSinkId(val)
      this.$refs['test-caller-audio'].setSinkId(val)
    },
    'audioSettingsGetter.callerVolume' (val) {
      this.$refs['caller-audio'].volume = val
      this.$refs['test-caller-audio'].volume = val
    }
  },
  mounted () {
    this.$refs['test-caller-audio'].addEventListener('pause', () => {
      this.testCallerMutator(false)
    })
    this.$event.listen('setRemoteAudio', e => {
      this.setRemoteAudio(e)
    })
  }
}
</script>

<style scoped>

</style>
