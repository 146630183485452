import EmergencyCallDataParser from '@/modules/api/csp/parsers/emergency-call-data.parser'

export default class LocationLogEventSchema {
  LocationQueryLogEvent (session, queryId) {
    const geolocationURL = new EmergencyCallDataParser().parseGeoLocation(session?._request?.headers?.Geolocation)
    return {
      text: null,
      uri: geolocationURL?.location_url,
      direction: 'outgoing',
      queryId: `urn:emergency:uid:${queryId}`
    }
  }

  LocationResponseLogEvent (session, responseData, queryId) {
    return {
      text: responseData,
      direction: 'outgoing',
      responseStatus: '200',
      responseId: `urn:emergency:uid:${queryId}`
    }
  }

  AdditionalDataQueryLogEvent (session, ADR) {
    const ADRUrls = new EmergencyCallDataParser().parseAdr(session?._request?.headers?.['Call-Info'])
    return {
      uri: ADRUrls[ADR.key],
      text: null,
      direction: 'outgoing',
      queryId: `urn:emergency:uid:${session?._request?.call_id}`
    }
  }

  AdditionalDataResponseLogEvent (session, responseData, queryId) {
    return {
      text: responseData,
      direction: 'outgoing',
      responseStatus: '200',
      responseId: `urn:emergency:uid:${queryId}`
    }
  }
}
