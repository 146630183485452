<template>
  <code class="transparent">
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          @click="informationModal"
          icon
          color="grey_light"
          text
          v-on="on"
        >
          <v-icon>mdi-information-outline</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('information') }}</span>
    </v-tooltip>
    <DialogShared v-model="toggleInfo" max-width="1200" :title="$t('information')">
      <information-tabs-component />
    </DialogShared>
  </code>
</template>

<script>
import InformationTabsComponent from '@/app/widgets/information-guide/components/InformationTabsComponent'
/* Shared */
import DialogShared from '@/app/shared/dialog/DialogShared'
export default {
  name: 'InformationButton',
  data: () => ({
    toggleInfo: false
  }),
  components: {
    DialogShared,
    InformationTabsComponent
  },
  methods: {
    informationModal () {
      this.toggleInfo = !this.toggleInfo
    }
  }
}
</script>

<style scoped>

</style>
