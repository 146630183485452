export const callPhoneNumberGetter = state => {
  return state.callPhoneNumberState
}

export const callOutgoingSettingsGetter = state => {
  return state.callOutgoingSettingsState
}
export const dialPadStatusGetter = state => {
  return state.dialPadStatusState
}
