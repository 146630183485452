<template>

  <div>

    <DialogShared max-width="900" v-model="entityDialog.show" :title="entityDialog.title">
      <v-card tile flat color="secondary_card">
        <v-card-text>
          <realm-dashboard-form :key="entityDialog.key" :entity="entity" :realm="realm"
                                @afterSubmit="afterSubmit"></realm-dashboard-form>
        </v-card-text>
      </v-card>
    </DialogShared>

    <div class="d-flex align-center mb-2">

      <v-spacer />

      <v-select style="max-width: 300px" class="mr-2 mb-2" hide-details @input="loadDashboards"
                v-model="realm"
                :items="realms"
      ></v-select>

      <v-menu offset-y>

        <template v-slot:activator="{ on, attrs }">
          <v-btn small class="mr-2"
                 color="error"
                 dark
                 v-bind="attrs"
                 v-on="on"
          >
            {{ $t('actions') }}
          </v-btn>
        </template>

        <v-list>

          <v-subheader v-if="realms">{{ $t('realm_actions') }}</v-subheader>

          <v-list-item v-if="realm" clickable @click="resetAllDashboardsInRealmClick()">
            <v-list-item-content>
              <v-list-item-title>{{ $t('reset_all_dashboars_for_selected_realm') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-subheader>{{ $t('global_actions') }}</v-subheader>

          <v-list-item clickable @click="resetAllDashboardsForAllRealmsClick()">
            <v-list-item-content>
              <v-list-item-title>{{ $t('reset_all_dashboards_for_all_realms') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item clickable @click="resetDashboarsForAllUserClick()">
            <v-list-item-content>
              <v-list-item-title>{{ $t('reset_user_dashboards') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list>
      </v-menu>

    </div>

    <v-data-table class="transparent-background" :dark="$vuetify.theme.dark" :loading="table.loading"
                  :title="$t('realm_dashboards')" :items="table.data" :headers="table.columns">

      <template v-slot:[`item.icon`]="{ item }">
        <v-icon size="sm">{{ item.configuration.icon }}</v-icon>
      </template>
      <template v-slot:[`item.actions`]="{ item }">

        <v-btn class="mr-1" color="green" small @click="editDashboardClick(item)">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind="attrs" v-on="on">
                mdi-pencil
              </v-icon>
            </template>
            <span> {{ $t('edit') }}</span>
          </v-tooltip>

        </v-btn>

        <v-btn class="mr-1" color="primary" small @click="dashboardConfigClick(item)">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind="attrs" v-on="on">
                mdi-cog-outline
              </v-icon>
            </template>
            <span> {{ $t('configure') }}</span>
          </v-tooltip>
        </v-btn>

        <v-btn class="mr-1" color="green" small @click="resetDashboardForRealmClick(item)">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind="attrs" v-on="on">
                mdi-backup-restore
              </v-icon>
            </template>
            <span> {{ $t('reset') }}</span>
          </v-tooltip>
        </v-btn>

      </template>

    </v-data-table>
  </div>

</template>

<script>

import DialogShared from '@/app/shared/dialog/DialogShared'
import RealmDashboardForm from '@/app/widgets/dashboard-widget/components/RealmDashboardForm'

import apiService from '@/modules/api/csp'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DashboardList',
  components: { RealmDashboardForm, DialogShared },

  computed: {
    ...mapGetters('dashboard', [
      'widgetsGetter',
      'dashboardsGetter'
    ])
  },

  data: (vm) => ({

    entity: null,

    realms: [],
    realm: null,

    entityDialog: {
      show: false,
      title: null,
      key: null
    },

    table: {
      loading: false,
      data: [],
      columns: [
        {
          value: 'id',
          text: vm.$t('id'),
          sortable: true,
          align: 'left'
        },
        {
          value: 'icon',
          text: vm.$t('icon'),
          sortable: false,
          align: 'left'
        },
        {
          value: 'name',
          text: vm.$t('name'),
          field: 'name',
          sortable: true,
          align: 'left'
        },
        {
          value: 'actions',
          text: vm.$t('actions'),
          sortable: false,
          align: 'right'
        }
      ]
    }

  }),

  async mounted () {
    await this.loadRealms()

    const realm = await this.$route.query.realm
    if (realm) {
      this.realm = realm
      await this.loadDashboards()
    } else {
      this.realm = this.realms[0]
      await this.loadDashboards()
    }
  },

  methods: {

    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    async loadDashboards () {
      if (this.realm) {
        this.table.loading = true
        await apiService.realmDashboard.getList(this.realm).then(response => {
          this.table.loading = false
          this.table.data = response.data
        })
      }
    },

    async loadRealms () {
      await apiService.sip.getRealms().then(response => {
        this.realms = response
      })
    },

    async dashboardConfigClick (dashboard) {
      window.location.href = `/realm-dashboard-configure/${dashboard.id}?realm_name=${this.realm}`
    },

    resetDashboardForRealmClick (dashboard) {
      this.$confirm(this.$t('are_you_sure_you_want_to_perform_this_operation', { what: this.$t('realm_dashboard') })).then(confirmed => {
        if (confirmed) {
          apiService.realmDashboard.delete(dashboard.id, this.realm).then((response) => {
            this.loadDashboards()
          }).catch(() => {
            this.notificationSnackBarAction({
              text: this.$t('cant_reset_dashboard'),
              color: 'error',
              show: true
            })
          })
        }
      })
    },

    resetAllDashboardsInRealmClick () {
      this.$confirm(this.$t('are_you_sure_you_want_to_perform_this_operation', { what: this.$t('realm_dashboard') })).then(confirmed => {
        if (confirmed) {
          apiService.realmDashboard.deleteAllForRealm(this.realm).then((response) => {
            this.loadDashboards()
          }).catch(() => {
            this.notificationSnackBarAction({
              text: this.$t('cant_reset_dashboard'),
              color: 'error',
              show: true
            })
          })
        }
      })
    },

    resetDashboarsForAllUserClick () {
      this.$confirm(this.$t('are_you_sure_you_want_to_perform_this_operation', { what: this.$t('realm_dashboard') })).then(confirmed => {
        if (confirmed) {
          apiService.userDashboard.deleteAll().then((response) => {
            this.loadDashboards()
          }).catch(() => {
            this.notificationSnackBarAction({
              text: this.$t('cant_reset_dashboard'),
              color: 'error',
              show: true
            })
          })
        }
      })
    },

    resetAllDashboardsForAllRealmsClick () {
      this.$confirm(this.$t('are_you_sure_you_want_to_perform_this_operation', { what: this.$t('realm_dashboard') })).then(confirmed => {
        if (confirmed) {
          apiService.realmDashboard.deleteAll().then((response) => {
            this.loadDashboards()
          }).catch(() => {
            this.notificationSnackBarAction({
              text: this.$t('cant_reset_dashboard'),
              color: 'error',
              show: true
            })
          })
        }
      })
    },

    editDashboardClick (dashboard) {
      this.entityDialog.key = this.$uuidKey()
      this.entityDialog.title = this.$t('update_realm_dashboard')
      this.entityDialog.show = true

      this.entity = dashboard
    },
    async afterSubmit () {
      await this.loadDashboards()
      this.entityDialog.show = false
    }
  }
}
</script>

<style scoped>

</style>
