import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters('settings', ['getUserSetting'])
  },
  methods: {
    ...mapActions('notifications', ['notificationSnackBarAction']),

    serialDeviceEvents () {
      this.$event.listen('serial-device-disconnect', () => {
        const offHook = this.getUserSetting('enable_off_hook_relay').value.active
        const auxiliary = this.getUserSetting('enable_auxiliary_relay').value.active

        if (offHook || auxiliary) {
          this.notificationSnackBarAction({
            text: this.$t('serial_device_disconnect'),
            color: 'warning',
            show: true
          })
        }
      })

      this.$event.listen('serialHooksOn', async () => {
        const offHook = this.getUserSetting('enable_off_hook_relay').value.active
        const auxiliary = this.getUserSetting('enable_auxiliary_relay').value.active

        if (offHook && auxiliary) {
          await this.$serial.toggleOnAll()

          return
        }

        if (offHook) await this.$serial.turnOnOffhook()
        if (auxiliary) await this.$serial.turnOnAuxilarity()
      })

      this.$event.listen('serialHooksOff', () => {
        this.$serial.toggleOffAll()
      })
    }
  }
}
