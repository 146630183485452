import apiService from '@/modules/api/csp'

export const loadLayoutsAction = async (context, payload) => {
  const { data } = apiService.layouts.list({
    page: 1,
    limit: 100
  }).then((result) => {
    context.commit('setLayoutsMutator', result.data.data)
  })
  return data
}
export const updateTemplateAction = async (context, payload) => {
  context.commit('setTemplateMutator', payload)
}
export const updateSlotsAction = async (context, payload) => {
  context.commit('setSlotsMutator', payload)
}

/**
 * Update user layout
 * @param dispatch
 * @param payload
 * @returns {Promise<void>}
 */
export const updateLayoutAction = async ({ dispatch }, payload) => {
  dispatch('updateTemplateAction', payload.layout_config.layout)
  dispatch('updateSlotsAction', payload.layout_config.slots)
  dispatch('updateWidgetSettingsAction', payload.layout_config.widgets)
}

/**
 * Load layout from api or set default layout
 * @param dispatch
 * @param getters
 * @param context
 * @param state
 * @param rootGetters
 * @param payload
 * @returns {Promise<void>}
 */
export const loadLayoutAction = async ({ dispatch, getters, context, state, rootGetters }, payload) => {
  const authUserGetter = rootGetters['global/authUserGetter']

  if (authUserGetter.layout) {
    // Set layout config from user
    dispatch('updateTemplateAction', authUserGetter.layout.layout_config.layout)
    dispatch('updateSlotsAction', authUserGetter.layout.layout_config.slots)
    dispatch('updateWidgetSettingsAction', authUserGetter.layout.layout_config.widgets)
  } else {
    // Set default layout
    dispatch('updateTemplateAction', 'DashboardTemplate1Component')
    dispatch('updateSlotsAction', state.layout.default_slots)
    dispatch('updateWidgetSettingsAction', state.layout.default_widget_settings)
  }
}

export const updateWidgetSettingsAction = async (context, payload) => {
  context.commit('setWidgetSettingsMutator', payload)
}
