<template>
  <v-tooltip v-if="$can('can_see_admin_panel')" bottom>
    <template v-slot:activator="{ on }">
      <v-btn :title="$t('admin')"
             @click="onAdminPanelClick()"
             icon
             color="grey_light"
             text
             v-on="on"
      >
        <v-icon>mdi-account-group</v-icon>
      </v-btn>
    </template>
    <span>

      {{ $t('admin_panel') }}

    </span>

  </v-tooltip>
</template>

<script>

export default {
  name: 'AdminPanelButton',
  components: { },

  data: () => ({

  }),

  methods: {
    onAdminPanelClick () {
      window.open(process.env.VUE_APP_ADMIN_PANEL_URL, '_blank').focus()
    }
  }
}
</script>

<style scoped>

</style>
