const LogEventEnum = {
  CallProcessLogEvent: 'CallProcessLogEvent',
  CallStartLogEvent: 'CallStartLogEvent',
  CallEndLogEvent: 'CallEndLogEvent',
  RecCallStartLogEvent: 'RecCallStartLogEvent',
  RecCallEndLogEvent: 'RecCallEndLogEvent',
  CallTransferLogEvent: 'CallTransferLogEvent',
  RouteLogEvent: 'RouteLogEvent',
  MediaStartLogEvent: 'MediaStartLogEvent',
  MediaEndLogEvent: 'MediaEndLogEvent',
  RecMediaStartLogEvent: 'RecMediaStartLogEvent',
  RecMediaEndLogEvent: 'RecMediaEndLogEvent',
  RecordingFailedLogEvent: 'RecordingFailedLogEvent',
  MessageLogEvent: 'MessageLogEvent',
  AdditionalAgencyLogEvent: 'AdditionalAgencyLogEvent',
  IncidentMergeLogEvent: 'IncidentMergeLogEvent',
  IncidentUnMergeLogEvent: 'IncidentUnMergeLogEvent',
  IncidentLinkLogEvent: 'IncidentLinkLogEvent',
  IncidentUnLinkLogEvent: 'IncidentUnLinkLogEvent',
  IncidentClearLogEvent: 'IncidentClearLogEvent',
  IncidentReopenLogEvent: 'IncidentReopenLogEvent',
  LostQueryLogEvent: 'LostQueryLogEvent',
  LostResponseLogEvent: 'LostResponseLogEvent',
  CallSignalingMessageLogEvent: 'CallSignalingMessageLogEvent',
  SipRecMetadataLogEvent: 'SipRecMetadataLogEvent',
  NonRtpMediaMessageLogEvent: 'NonRtpMediaMessageLogEvent',
  AliLocationQueryLogEvent: 'AliLocationQueryLogEvent',
  AliLocationResponseLogEvent: 'AliLocationResponseLogEvent',
  MalformedMessageLogEvent: 'MalformedMessageLogEvent',
  IncidentSplitLogEvent: 'IncidentSplitLogEvent',
  EidoLogEvent: 'EidoLogEvent',
  DiscrepancyReportLogEvent: 'DiscrepancyReportLogEvent',
  ElementStateChangeLogEvent: 'ElementStateChangeLogEvent',
  ServiceStateChangeLogEvent: 'ServiceStateChangeLogEvent',
  AdditionalDataQueryLogEvent: 'AdditionalDataQueryLogEvent',
  AdditionalDataResponseLogEvent: 'AdditionalDataResponseLogEvent',
  LocationQueryLogEvent: 'LocationQueryLogEvent',
  LocationResponseLogEvent: 'LocationResponseLogEvent',
  CallStateChangeLogEvent: 'CallStateChangeLogEvent',
  GatewayCallLogEvent: 'GatewayCallLogEvent',
  HookflashLogEvent: 'HookflashLogEvent',
  LegacyDigitsLogEvent: 'LegacyDigitsLogEvent',
  AgentStateChangeLogEvent: 'AgentStateChangeLogEvent',
  QueueStateChangeLogEvent: 'QueueStateChangeLogEvent',
  KeepAliveFailureLogEvent: 'KeepAliveFailureLogEvent',
  RouteRuleMsgLogEvent: 'RouteRuleMsgLogEvent',
  PolicyChangeLogEvent: 'PolicyChangeLogEvent',
  VersionsLogEvent: 'VersionsLogEvent',
  SubscribeLogEvent: 'SubscribeLogEvent'
}
export default LogEventEnum
