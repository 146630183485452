<template>
  <tabs-shared :items="items" />
</template>

<script>
/* Shared */
import tabsShared from '@/app/shared/tabs/TabsShared'
/* Tabs */
import HotkeysInformationComponent from '@/app/widgets/information-guide/components/tabs/HotkeysInformationComponent'
import AboutInformationComponent from '@/app/widgets/information-guide/components/tabs/AboutInformationComponent'

export default {
  name: 'InformationTabsComponent',
  data: (vm) => ({
    items: [
      {
        tab: vm.$t('hot_keys'),
        name: 'hot-keys',
        component: HotkeysInformationComponent
      },
      {
        tab: vm.$t('about'),
        name: 'about',
        component: AboutInformationComponent
      }
    ]
  }),
  components: {
    tabsShared
  }
}
</script>

<style scoped>

</style>
