import apiService from '@/modules/api/csp'

export async function getCustomCallLinesAction ({ commit }, realmName) {
  apiService.callLines.load(realmName).then(response => {
    if (response.status !== 200) return

    response = response.data

    const data = {}

    response.map(item => {
      data[item.key] = {
        name: item.name,
        value: item.value
      }
    })

    commit('setCustomCallLinesMutator', data)
  })
}
