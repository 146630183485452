<template>
  <v-overlay :z-index="100000" color="#2F3446" :opacity="1" :value="(!authUserGetter && ['Login', 'Conference'].indexOf(this.$route.name) === -1) || loading">
    <v-progress-circular indeterminate size="85">{{ $t('nga911') }}</v-progress-circular>
  </v-overlay>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'PageLoaderComponent',
  computed: {
    ...mapGetters('global', ['authUserGetter'])
  },
  data: () => ({
    loading: true
  }),
  mounted () {
    setTimeout(() => {
      this.loading = false
    }, 2000)
  }
}
</script>

<style scoped>

</style>
