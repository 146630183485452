
export default function globalDashboardApiModule (instance) {
  return {

    /**
     * Get global dashboards
     * @returns {Promise<*>}
     */
    getList: async () => {
      const resource = `/dashboards/apps/${process.env.VUE_APP_ID}`

      return await instance.get(resource)
    },

    /**
     * Get single dashboard
     * @param id Dashboard id
     * @returns {Promise<*>}
     */
    getSingle: async (id) => {
      const resource = `dashboards/${id}/apps/${process.env.VUE_APP_ID}`

      return await instance.get(resource)
    },

    /**
     * Create dashboard
     * @param params - name, configuraiton, etc
     * @returns {Promise<*>}
     */
    create: async (params) => {
      params.app_id = process.env.VUE_APP_ID

      const resource = '/dashboards'

      return await instance.post(resource, params)
    },

    /**
     * Update dashboard
     * @param id - Dashboard Id
     * @param params {name, configuration}
     * @returns {Promise<*>}
     */
    update: async (id, params) => {
      const resource = `/dashboards/${id}/apps/${process.env.VUE_APP_ID}`

      return await instance.patch(resource, params)
    },

    /**
     * Delete single dashboard
     * @param id
     * @returns {Promise<*>}
     */
    delete: async (id) => {
      const resource = `/dashboards/${id}/apps/${process.env.VUE_APP_ID}`

      return await instance.delete(resource)
    }

  }
}
