import state from '@/app/components/audio-volume/store/state'
import * as getters from '@/app/components/audio-volume/store/getters'
import * as mutations from '@/app/components/audio-volume/store/mutations'
import * as actions from '@/app/components/audio-volume/store/actions'

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  state
}
