import store from '../store'

/**
 * Permission Helper
 * @type {{can(*=): (boolean|*), canAny(*): (boolean|*)}}
 */
const rolesHelper = {

  /**
     * Check if logged user has any roles
     * @param roles
     * @returns {boolean|*}
     */
  hasAnyRole (roles) {
    try {
      if (!Array.isArray(roles)) {
        roles = [roles]
      }

      roles = roles.map(role => role.toLowerCase())

      return store.getters['auth/authUserGetter'].roles.map(role => role.toLowerCase()).some(v => roles.includes(v))
    } catch (e) {
      return false
    }
  },

  /**
     * Check if logged user has role
     * @param roleName
     * @returns {boolean|*}
     */
  hasRole (roleName) {
    try {
      return store.getters['auth/authUserGetter'].roles.map(v => v.toLowerCase()).includes(roleName.toLowerCase())
    } catch (e) {
      return false
    }
  }
}

export default rolesHelper
