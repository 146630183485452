import LocalCacher from '@/modules/cache/localCacher'

/**
 * Set hot contact in vuex store
 * @param state
 * @param payload
 */
export const setSpeedDialContactMutator = (state, payload) => {
  state.speedDialContacts = payload
}

/**
 * Set filtered speed dial buttons
 * @param state
 * @param payload
 */
export const setFilteredSpeedDialContactsMutator = (state, payload) => {
  state.filteredSpeedDialContacts = payload

  LocalCacher.indexDB.speedDial.updateTable(payload)
}
/**
 * Set current folder
 * @param state
 * @param payload
 */
export const setCurrentFolderMutator = (state, payload) => {
  state.currentFolder = payload
}
/**
 * Set current contact in vuex store
 * @param state
 * @param payload
 */
export const setCurrentContact = (state, payload) => {
  state.currentContact = payload
}
