export class DtmfTones {
    tones = () => {
      const AudioContext = window.AudioContext || window.webkitAudioContext || window.mozAudioContext

      function Tone (context, freq1, freq2) {
        this.context = context
        this.status = 0
        this.freq1 = freq1
        this.freq2 = freq2
      }

      Tone.prototype.setup = function () {
        this.osc1 = context.createOscillator()
        this.osc2 = context.createOscillator()
        this.osc1.frequency.value = this.freq1
        this.osc2.frequency.value = this.freq2

        this.gainNode = this.context.createGain()
        this.gainNode.gain.value = 0.25

        this.filter = this.context.createBiquadFilter()
        this.filter.type = 'lowpass'
        // this.filter.frequency = 8000;

        this.osc1.connect(this.gainNode)
        this.osc2.connect(this.gainNode)

        this.gainNode.connect(this.filter)
        this.filter.connect(context.destination)
      }

      Tone.prototype.start = function () {
        this.setup()
        this.osc1.start(0)
        this.osc2.start(0)
        this.status = 1
      }

      Tone.prototype.stop = function () {
        this.osc1.stop(0)
        this.osc2.stop(0)
        this.status = 0
      }

      const dtmfFrequencies = {
        button_Numpad1: { f1: 697, f2: 1209 },
        button_Numpad2: { f1: 697, f2: 1336 },
        button_Numpad3: { f1: 697, f2: 1477 },
        button_Numpad4: { f1: 770, f2: 1209 },
        button_Numpad5: { f1: 770, f2: 1336 },
        button_Numpad6: { f1: 770, f2: 1477 },
        button_Numpad7: { f1: 852, f2: 1209 },
        button_Numpad8: { f1: 852, f2: 1336 },
        button_Numpad9: { f1: 852, f2: 1477 },
        button_NumpadMultiply: { f1: 941, f2: 1209 },
        button_Numpad0: { f1: 941, f2: 1336 },
        button_NumpadDivide: { f1: 941, f2: 1477 }
      }

      const context = new AudioContext()

      const dtmf = new Tone(context, 350, 440)

      const el = document.getElementsByTagName('button')

      el.forEach(item => {
        item.addEventListener('mousedown', function (e) {
          if (item.id in dtmfFrequencies) {
            const frequencyPair = dtmfFrequencies[item.id] // this looks up which frequency pair we need

            // this sets the freq1 and freq2 properties
            dtmf.freq1 = frequencyPair.f1
            dtmf.freq2 = frequencyPair.f2

            if (dtmf.status === 0) {
              dtmf.start()
            }
          }
        })
      })

      el.forEach(item => {
        item.addEventListener('mouseup', function (e) {
          if (item.id in dtmfFrequencies) {
            dtmf.stop()
          }
        })
      })
    }
}

export default {
  install (Vue) {
    Vue.prototype.$dtmfTones = new DtmfTones()
  }
}
