import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

var firebaseConfig = {
  appId: process.env.VUE_APP_APPID,
  apiKey: process.env.VUE_APP_APIKEY,
  projectId: process.env.VUE_APP_PROJECTID,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  measurementId: process.env.VUE_APP_MEASUREMENTID,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID
}

// Initialize Firebase
export default {
  install (Vue) {
    Vue.prototype.$firestore = firebase.initializeApp(firebaseConfig).firestore()
  }
}
