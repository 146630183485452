export default function userSettingsApiModule (instance) {
  return {

    /**
     * Return list of user settings
     * @param data
     * @returns {Promise<*>}
     */
    load: async (data) => {
      const resource = 'user-settings'

      return await instance.get(resource, data)
    },

    /**
     * Update User Setting
     * @param id
     * @param data
     * @returns {Promise<*>}
     */
    edit: async (id, data) => {
      const resource = `/user-settings/${id}`

      return await instance.patch(resource, data)
    }

  }
}
