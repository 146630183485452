<template>

  <validation-observer ref="observer" >

    <v-form @submit.stop="submit" @reset="reset">

      <v-row dense>
        <v-col>
          <validation-provider v-slot="{ errors }" vid="name" :name="$t('name')" rules="required">
            <v-text-field :label="$t('name')" v-model="form.name" :error-messages="errors"  />
          </validation-provider>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>

          <validation-provider v-slot="{ errors  }" vid="icon" :name="$t('icon')" rules="required">

            <v-select
                      v-model="form.configuration.icon"
                      :items="dashboardIconsGetter"
                      :label="$t('icon')"
                      item-value="icon"
                      item-text="label"
                      :error-messages="errors"
                      clearable
            >

              <template v-slot:item="{item }" >
                <v-icon class="mr-2">{{item.icon}}</v-icon> {{ item.label}}
              </template>

            </v-select>
          </validation-provider>

        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-btn color="red" @click="submit" >{{ $t('save')}}</v-btn>
        </v-col>
      </v-row>

    </v-form>
  </validation-observer>

</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import '@/rules/validation.rules.js'
import apiService from '@/modules/api/csp'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'GlobalDashboardForm',

  components: {
    ValidationObserver,
    ValidationProvider
  },

  props: {
    entity: {
      type: Object
    }
  },

  computed: {
    ...mapGetters('dashboard', ['dashboardIconsGetter'])
  },

  data: () => {
    return {
      icons: [],

      form: {
        id: null,
        name: null,
        configuration: {
          icon: null,
          layout: null,
          widgets: []
        }
      }
    }
  },

  mounted () {
    if (this.entity) {
      this.form = JSON.parse(JSON.stringify(this.entity))
    } else {
      this.reset()
    }
  },

  methods: {

    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    async submit () {
      const isValid = await this.$refs.observer.validate()

      if (isValid) {
        if (this.form.id) {
          apiService.globalDashboard.update(this.form.id, this.form).then((response) => {
            this.notificationSnackBarAction({
              text: this.$t('dashboard_updated'),
              color: 'success',
              show: true
            })

            this.$emit('afterSubmit')
          }).catch(() => {
            this.notificationSnackBarAction({
              text: this.$t('cant_update_dashboard'),
              color: 'error',
              show: true
            })
          })
        } else {
          apiService.globalDashboard.create(this.form).then((response) => {
            this.notificationSnackBarAction({
              text: this.$t('dashboard_created'),
              color: 'success',
              show: true
            })

            this.$emit('afterSubmit')
          }).catch(() => {
            this.notificationSnackBarAction({
              text: this.$t('cant_create_dashboard'),
              color: 'error',
              show: true
            })
          })
        }
      }
    },

    reset () {
      this.form.id = null
      this.form.name = null
      this.form.configuration = {
        icon: null,
        layout: null,
        widgets: []
      }
    }
  }
}
</script>
