import CADSpillFormat4 from './formats/format4'
import Alias4 from './aliases/alias4'

export default class CADSpill {
  formats = {
    4: new CADSpillFormat4()
  }

  aliases = {
    4: Alias4
  }

  getFormat (format = 4) {
    return this.formats[format]
  }

  getAlias (alias = 4) {
    return this.aliases[alias]
  }
}
