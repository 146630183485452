import apiService from '@/modules/api/csp'
import { mapGetters, mapActions } from 'vuex'

export default {

  computed: {
    ...mapGetters('settings', ['sipDefaultAddressGetter'])
  },
  methods: {
    ...mapActions('callConference', ['conferenceDataAction', 'clearConferenceDataAction']),

    async setConferenceData (session) {
      try {
        const isFocus = session._request.headers.Contact[0].raw.match(/isfocus/)
        const conferenceID = session._request.headers.Contact[0].raw.match(/<sip:(.*?)@/)

        if (isFocus && conferenceID) {
          await apiService.egress.getConference(this.sipDefaultAddressGetter.account_id, conferenceID[1]).then(data => {
            const payload = {
              isConference: true,
              conferenceId: data.data.data.id,
              participants: data.data.data._read_only.participants,
              count: data.data.data._read_only.members
            }
            this.conferenceDataAction(payload)
          })
        }
      } catch (e) {
        console.error('Error. Can\'t set conference information')
      }
    },
    clearConferenceData () {
      this.clearConferenceDataAction()
    }
  }
}
