export default class EventDispatcher {
  dispatch (event, params) {
    const newEvent = new Event(event)
    newEvent.eventData = params
    document.dispatchEvent(newEvent)
  }

  listen (event, callback) {
    document.addEventListener(event, callback)
  }

  remove (event, callback) {
    document.removeEventListener(event, callback)
  }
}
