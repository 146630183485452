export default function callActivityLogsApiModule (instance) {
  return {
    /**
     * Save call WS logs
     * @param data
     * @returns {Promise<*>}
     */
    sendActivityLog: async (data) => {
      const resource = '/call-activity-logs'

      return await instance.post(resource, data)
    }

  }
}
