import apiService from '@/modules/api/csp'
import { XMLParser } from 'fast-xml-parser'
import EventDispatcher from '@/modules/event/event-dispatcher.module'
import I3Logger from '@/plugins/i3-logger/I3Logger.plugin'
import LogEventEnum from '@/plugins/i3-logger/enums/i3-logger.enum'

const event = new EventDispatcher()

export default function adrXmlParser () {
  return {
    async parse (resource, type) {
      if (!resource) return
      try {
        return await apiService.adrApiModule.get(resource).then(data => {
          try {
            new I3Logger().i3LogEvent(window.answeredCallSession, LogEventEnum.AdditionalDataResponseLogEvent, data?.data, type)
          } catch (e) {
            console.log('Cant record ADR response data', e)
          }
          const adr = this.parseADR(data?.data, type)

          event.dispatch(`updated-adr-${type}`, { ...adr })

          return adr
        }).catch(() => {
          event.dispatch(`updated-adr-${type}`, {})
        })
      } catch (e) {
        console.log(e)
      }
    },

    parseADR (data, adrType) {
      const selectedADR = ['Comment', 'SubscriberInfo', 'ServiceInfo', 'DeviceInfo', 'ProviderInfo']

      if (selectedADR.includes(adrType)) {
        const match = new RegExp('EmergencyCallData.' + adrType + '(?=[\\S\\s]{10,8000})[\\S\\s]*EmergencyCallData.' + adrType + '>')
        const ADRMatch = data?.match(match)

        if (ADRMatch && ADRMatch[0]) {
          const parseOptions = { removeNSPrefix: true }
          const parser = new XMLParser(parseOptions)
          return parser.parse(ADRMatch[0])
        }
      }
    }

  }
}
