import { extend, localize } from 'vee-validate'
// eslint-disable-next-line camelcase
import { required, email, numeric, min_value, max_value, regex } from 'vee-validate/dist/rules'

extend('required', required)
extend('email', email)
extend('numeric', numeric)
extend('min_value', min_value)
extend('max_value', max_value)
extend('regex', regex)

extend('sipAddress', {
  validate: value => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    return pattern.test(value)
  }
})

extend('proxy', {
  validate: value => {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i')

    return pattern.test(value)
  }
})

localize('en', {
  messages: {
    proxy: '{_field_} is not a valid Proxy address',
    sipAddress: '{_field_} is not a valid SIP address'
  }
})
