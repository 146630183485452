import store from '@/store'
import localStorageService from '@/util/local-storage-service'

export default function heldApiModule (instance) {
  return {
    get: async (url) => {
      const callID = window.answeredCallSession._request.call_id
      const resource = '/proxy/url'
      const position = localStorageService.getPositionNumber()
      const sipAddress = store.getters['settings/sipDefaultAddressGetter'].sip_address
      const incidentID = store.getters['callInformation/incidentIDGetter'][callID]

      return await instance.get(resource, {
        headers: {
          accept: 'application/xml'
        },
        params: {
          type: 'xml',
          stage: (window.rebidCounter) ? `rebid ${window.rebidCounter}` : 'Initial',
          method: 'Held',
          call_id: callID,
          position: position ?? null,
          should_log: true,
          incident_id: incidentID,
          write_time: Date.now(),
          sip_address: sipAddress,
          endpoint_key: url
        }
      })
    }
  }
}
