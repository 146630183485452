export default function contactBooksLocalCacherModule (indexDB) {
  return {
    get: async function (payload) {
      const data = indexDB.contactBooks.get(payload)

      return data
    },
    updateTable: (data) => {
      indexDB.contactBooks.insertMany(data)
    },
    delete: (id) => {
      indexDB.contactBooks.delete(id)
    },
    edit: (id, data) => {
      indexDB.contactBooks.edit(id, data)
    },

    clear () {
      indexDB.contactBooks.clear()
    },
    last: function () {
      return indexDB.contactBooks.last()
    },
    count: function (filter) {
      return indexDB.contactBooks.count(filter)
    }
  }
}
