export const adrUpdateGetter = state => {
  return state.updated
}

export const commentGetter = state => {
  return state.commentState
}

export const deviceInfoGetter = state => {
  return state.deviceInfoState
}

export const providerInfoGetter = state => {
  return state.providerInfoState
}

export const serviceInfoGetter = state => {
  return state.serviceInfoState
}

export const subscriberInfoGetter = state => {
  return state.subscriberInfoState
}
