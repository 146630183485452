import indexDBService from '@/modules/db/indexDB'
import agentsLocalCacherModule from '@/modules/cache/localCacher/modules/agents.localCacher.module'
import speedDialLocalCacherModule from '@/modules/cache/localCacher/modules/speedDial.localCacher.module'
import callHistoryLocalCacherModule from '@/modules/cache/localCacher/modules/callHistory.localCacher.module'
import contactBooksLocalCacherModule from '@/modules/cache/localCacher/modules/contactBooks.localCacher.module'
import contactGroupsLocalCacherModule from '@/modules/cache/localCacher/modules/contactGroups.localCacher.module'

const localCacherService = {
  indexDB: {
    agents: agentsLocalCacherModule(indexDBService),
    speedDial: speedDialLocalCacherModule(indexDBService),
    callHistory: callHistoryLocalCacherModule(indexDBService),
    contactBooks: contactBooksLocalCacherModule(indexDBService),
    contactGroups: contactGroupsLocalCacherModule(indexDBService),
    clearDB: async function () {
      const promises = []

      promises.push(this.agents.clear())
      promises.push(this.speedDial.clear())
      promises.push(this.callHistory.clear())
      promises.push(this.contactBooks.clear())
      promises.push(this.contactGroups.clear())

      await Promise.all(promises)
    }
  }
}

export default localCacherService
