import store from '@/store'
import localStorageService from '@/util/local-storage-service'
export default {
  methods: {
    getAudioDevices () {
      navigator.mediaDevices.enumerateDevices().then(devices => {
        const inputs = devices.filter(item => item.kind === 'audioinput')
        const outputs = devices.filter(item => item.kind === 'audiooutput')
        this.populateAudioDevices('audioInputs', inputs)
        this.populateAudioDevices('audioOutputs', outputs)
        this.updateLocalStorageInputs(inputs)
        this.updateLocalStorageOutputs(outputs)
      })
    },
    populateAudioDevices (type, data) {
      const devices = data.map(item => {
        return {
          deviceId: item.deviceId,
          label: item.label
        }
      })
      store.commit('settings/audioSettingsMutation', { type: type, data: devices })
    },
    updateLocalStorageInputs (devices) {
      localStorageService.setMicrophoneId(devices)
    },
    updateLocalStorageOutputs (devices) {
      localStorageService.setRingingOutputId(devices)
      localStorageService.setCallerOutputId(devices)
      localStorageService.setItrOutputId(devices)
      localStorageService.setRecordingsOutputId(devices)
    },
    updateAudioDevices () {
      navigator.mediaDevices.addEventListener('devicechange', () => {
        this.getAudioDevices()
      })
      this.$event.listen('microphonePermissionUpdated', e => {
        if (e.permissionStatus) { this.getAudioDevices() }
      })
    }
  }
}
