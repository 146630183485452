import { liveQuery } from 'dexie'
import { useObservable } from '@vueuse/rxjs'

export default function agentsDBModule (db) {
  db.version(15).stores({
    agents: '++id'
  })

  return {
    insert: (data) => {
      db.agents.add(data)
    },
    insertMany: (data) => {
      data.forEach(d => {
        db.agents.add(d)
      })
    },
    get: (filter) => {
      return useObservable(
        liveQuery(async () => {
          return await db.agents.toArray()
        })
      )
    },
    clear: () => {
      db.agents.clear()
    }
  }
}
