import io from 'socket.io-client'
import localStorageService from '@/util/local-storage-service'

export default class Socket {
  _io = null
  _url = null

  constructor (socketURL) {
    this._url = socketURL

    this.connect()
  }

  /**
   * Receive event from the socket.
   *
   * @param { string } event
   * @param { any[] | object | string | number } payload
   *
   * @return { Promise } response
   */
  on (event, callback) {
    this._io.on(event, (response) => {
      callback(response)
    })
  }

  /**
   * Send event to the socket.
   *
   * @param { string } event
   * @param { any[] | object | string | number } payload
   *
   * @return { void }
   */
  emit (event, payload) {
    this._io.emit(event, payload)
  }

  off (event, callback) {
    if (callback) this.io.off(event, callback)
    else this._io.off(event)
  }

  connect () {
    this._io = io(this._url, {
      transports: ['websocket'],
      auth: {
        authorization: 'Bearer ' + localStorageService.getUserAccessToken()
      }
    })
  }

  get io () {
    return this._io
  }
}
