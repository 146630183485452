const textHelper = {

  /**
   * Capitalize
   * @param value
   * @returns {string}
   */
  capitalize (value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
  },

  /**
   * Substring text if long enough
   * @param value
   * @param start
   * @param end
   * @param add
   * @returns {string|*}
   */
  substringIf (value, start = 0, end = 15, add = '...') {
    if (value && value.length > end) {
      return value.substring(start, end) + add
    }
    return value
  },

  /**
   * Clean stringify
   * @param object
   * @returns {string}
   */
  cleanStringify (object) {
    if (object && typeof object === 'object') {
      object = copyWithoutCircularReferences([object], object)
    }
    return JSON.stringify(object)

    function copyWithoutCircularReferences (references, object) {
      var cleanObject = {}
      Object.keys(object).forEach(function (key) {
        var value = object[key]
        if (value && typeof value === 'object') {
          if (references.indexOf(value) < 0) {
            references.push(value)
            cleanObject[key] = copyWithoutCircularReferences(references, value)
            references.pop()
          } else {
            cleanObject[key] = '###_Circular_###'
          }
        } else if (typeof value !== 'function') {
          cleanObject[key] = value
        }
      })
      return cleanObject
    }
  }

}

export default textHelper
