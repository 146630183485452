import { liveQuery } from 'dexie'
import { useObservable } from '@vueuse/rxjs'

export default function contactBookDBModule (db) {
  db.version(15).stores({
    contactGroups: '++id'
  })

  return {
    insert: (data) => {
      data.id = parseInt(data.id)

      db.contactGroups.add(data)
    },
    insertMany: (data) => {
      data.forEach(d => {
        d.id = parseInt(d.id)

        db.contactGroups.add(d)
      })
    },
    get: (filter) => {
      return useObservable(
        liveQuery(async () => {
          let tmp = null

          tmp = db.contactGroups.reverse()

          if (filter.offset) {
            tmp = tmp.offset(filter.offset)
          }

          if (filter.limit) {
            tmp = tmp.limit(filter.limit)
          }

          return await tmp.toArray()
        })
      )
    },
    delete: (id) => {
      db.contactGroups.delete(id)
    },
    edit: (id, data) => {
      db.contactGroups.update(id, data)
    },
    clear: () => {
      db.contactGroups.clear()
    },
    last: () => {
      return db.contactGroups.orderBy('id').last()
    },
    count: (filter) => {
      return useObservable(
        liveQuery(async () => {
          return db.contactGroups.count()
        })
      )
    },
    sync: (data) => {
      data.forEach(async d => {
        d.id = parseInt(d.id)

        if (await db.contactGroups.get(d.id)) {
          db.contactGroups.update(d.id, d)
        } else {
          db.contactGroups.add(d)
        }
      })
    }
  }
}
