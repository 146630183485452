var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DialogShared',{attrs:{"max-width":"900","title":_vm.entityDialog.title},model:{value:(_vm.entityDialog.show),callback:function ($$v) {_vm.$set(_vm.entityDialog, "show", $$v)},expression:"entityDialog.show"}},[_c('v-card',{attrs:{"tile":"","flat":"","color":"secondary_card"}},[_c('v-card-text',[_c('global-dashboard-form',{key:_vm.entityDialog.key,attrs:{"entity":_vm.entity},on:{"afterSubmit":_vm.afterSubmit}})],1)],1)],1),_c('div',{staticClass:"d-flex align-center mb-2"},[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.addDashboardClick}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v(" mdi-plus ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('add_new')))])])],1)],1),_c('v-data-table',{staticClass:"transparent-background",attrs:{"dark":_vm.$vuetify.theme.dark,"loading":_vm.table.loading,"title":_vm.$t('global_dashboards'),"items":_vm.table.data,"headers":_vm.table.columns},scopedSlots:_vm._u([{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"size":"sm"}},[_vm._v(_vm._s(item.configuration.icon))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"color":"green","small":""},on:{"click":function($event){return _vm.editDashboardClick(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('edit')))])])],1),_c('v-btn',{staticClass:"mr-1",attrs:{"color":"red","small":""},on:{"click":function($event){return _vm.deleteDashboardClick(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-outline ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('delete')))])])],1),_c('v-btn',{staticClass:"mr-1",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.dashboardConfigClick(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-cog-outline ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('configure')))])])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }