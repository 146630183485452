import apiService from '@/modules/api/csp'
import CallHistoryTransformer from '@/modules/api/csp/transformers/call-history.transformer'
import indexedDBSyncService from '@/util/indexedDB-sync-service'

export const getCallHistoryAction = async ({ commit, dispatch }, payload = {}) => {
  indexedDBSyncService.sync()
}

export const saveCallHistoryAction = async ({ commit, dispatch }, payload = []) => {
  const collectData = await new CallHistoryTransformer(payload).collectData()
  if (collectData) {
    await apiService.callHistory.saveCallHistory(collectData).then(async () => {
      indexedDBSyncService.sync()
    })
  }
}
