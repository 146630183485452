import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('global', ['authUserGetter']),
    ...mapGetters('callInformation', [
      'esrkNumberGetter'
    ])
  },
  methods: {
    async storeCallInfo (data) {
      await this.$firestore.collection('calls').doc(data.call_id).set(data)
    },
    async updateCallInfo (event) {
      try {
        await this.$firestore.collection('calls').doc(event._request.call_id).update({
          end_time: event._end_time
        })
      } catch (e) {
        console.log('Cant update call status!')
      }
    },
    async prepareAdminCallInfo (e) {
      const dataShare = {
        call_id: e.session._request.call_id,
        start_time: e.session._start_time,
        end_time: null,
        user_id: this.authUserGetter.id,
        email: this.authUserGetter.email,
        phone_number: this.esrkNumberGetter,
        type: 'admin'
      }
      await this.storeCallInfo(dataShare)
    }
  }
}
