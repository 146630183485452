var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"tile":"","flat":"","color":"secondary_card"}},[_c('v-card-title',[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","cypress":"sip_address_add"},on:{"click":_vm.openToCreate}},on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-plus ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('add_new')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","disabled":!!!_vm.$can('can_edit_sip_address') || _vm.selected.length === 0,"cypress":"sip_address_edit"},on:{"click":_vm.openToEdit}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-pencil ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('edit')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","loading":_vm.loading.delete,"disabled":!!!_vm.$can('can_delete_sip_address'),"cypress":"sip_address_delete"}},on),[_c('v-icon',{attrs:{"color":"error","disabled":_vm.selected.length === 0},on:{"click":_vm.removeSipAddress}},[_vm._v(" mdi-delete-outline ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('delete')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":!!!_vm.$can('can_edit_sip_address'),"icon":"","loading":_vm.loading.default,"cypress":"sip_address_default"}},on),[_c('v-icon',{attrs:{"color":"success","disabled":_vm.selected.length === 0},on:{"click":_vm.setDefaultSipAddress}},[_vm._v(" mdi-check-bold ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('make_default')))])])],1),_c('v-card-text',[_c('v-data-table',{staticClass:"transparent-background",attrs:{"cypress":"sip_address_data","headers":_vm.headers,"items":_vm.sipAddressesGetter,"loading":_vm.loading.data,"items-per-page":10,"show-select":"","single-select":""},scopedSlots:_vm._u([{key:"item.default",fn:function(ref){
var item = ref.item;
return [(item.default)?[_vm._v(" "+_vm._s(_vm.$t('default'))+" ")]:_vm._e()]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),_c('validation-observer',{ref:"observer"},[_c('DialogShared',{staticClass:"white--text",attrs:{"eager":"","max-width":"600","title":_vm.dialogTitle},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-spacer'),(_vm.mode === 'create')?_c('v-btn',{staticClass:"pa-7",attrs:{"cypress":"add_sip_address_email","color":"error","loading":_vm.loading.add,"disabled":_vm.loading.add},on:{"click":function($event){return _vm.addSipAddress()}}},[_vm._v(" "+_vm._s(_vm.$t('add'))+" ")]):_vm._e(),(_vm.mode === 'edit')?_c('v-btn',{staticClass:"pa-7",attrs:{"cypress":"update_sip_address","color":"error","loading":_vm.loading.edit,"disabled":_vm.loading.edit},on:{"click":function($event){return _vm.edit()}}},[_vm._v(" "+_vm._s(_vm.$t('edit'))+" ")]):_vm._e()]},proxy:true}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"form",staticClass:"pt-10",attrs:{"novalidate":""}},[_c('v-row',{staticClass:"white--text",attrs:{"tile":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('sip_address'),"rules":"required|sipAddress"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"cypress":"add_sip_address","error-messages":errors,"name":"sip_address","label":_vm.$t('sip_address') + '*',"dark":_vm.$vuetify.theme.dark,"clearable":"","outlined":"","counter":"100","hint":"username@domain.com"},on:{"input":_vm.findMatchingAccountNameForSipAddress},model:{value:(_vm.newSipAddress.sip_address),callback:function ($$v) {_vm.$set(_vm.newSipAddress, "sip_address", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"newSipAddress.sip_address"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('display_name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"cypress":"add_display_name","error-messages":errors,"name":"display_name","label":_vm.$t('display_name'),"dark":_vm.$vuetify.theme.dark,"clearable":"","outlined":"","counter":"100","hint":_vm.$t('eg_alice')},model:{value:(_vm.newSipAddress.display_name),callback:function ($$v) {_vm.$set(_vm.newSipAddress, "display_name", $$v)},expression:"newSipAddress.display_name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('your_account_name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"cypress":"add_account_name","error-messages":errors,"name":"account_name","label":_vm.$t('account_name') + '*',"items":_vm.accountNamesGetter,"dark":_vm.$vuetify.theme.dark,"outlined":"","autocomplete":"new-password","hint":_vm.$t('your_account_name')},model:{value:(_vm.newSipAddress.account_name),callback:function ($$v) {_vm.$set(_vm.newSipAddress, "account_name", $$v)},expression:"newSipAddress.account_name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('password'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"cypress":"add_password","error-messages":errors,"name":"password","label":_vm.$t('password') + '*',"dark":_vm.$vuetify.theme.dark,"clearable":"","outlined":"","counter":"100","type":"password","autocomplete":"new-password","hint":_vm.$t('your_sip_address_password')},model:{value:(_vm.newSipAddress.password),callback:function ($$v) {_vm.$set(_vm.newSipAddress, "password", $$v)},expression:"newSipAddress.password"}})]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }