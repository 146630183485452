export default function userDashboardApiModule (instance) {
  return {

    /**
     * Get user dashboards
     * @returns {Promise<*>}
     */
    getList: async () => {
      const resource = `/user-dashboards/apps/${process.env.VUE_APP_ID}`

      return await instance.get(resource)
    },

    /**
     * Get single user dashboard
     * @param id Dashboard id
     * @returns {Promise<*>}
     */
    getSingle: async (id) => {
      const resource = `/user-dashboards/${id}`

      return await instance.get(resource)
    },

    /**
     * Update user dashboard
     * @param id - Dashboard Id
     * @param params {name, configuration}
     * @returns {Promise<*>}
     */
    update: async (id, params) => {
      if (!id) {
        id = params.dashboard_id
      }

      const resource = `/user-dashboards/${id}/apps/${process.env.VUE_APP_ID}`

      return await instance.patch(resource, params)
    },

    /**
     * Delete user dashboard
     * @param id
     * @param params
     * @returns {Promise<*>}
     */
    delete: async (id, params) => {
      const resource = `/user-dashboards/${id}/apps/${process.env.VUE_APP_ID}`

      return await instance.delete(resource, params)
    },

    /**
     * Delete all dashboards for all users
     * @param id
     * @param params
     * @returns {Promise<*>}
     */
    deleteAll: async (id, params) => {
      const resource = `/user-dashboards/all-realms/apps/${process.env.VUE_APP_ID}`

      return await instance.delete(resource, params)
    }
  }
}
