<template>

  <div >

    <DialogShared max-width="900" v-model="entityDialog.show" :title="entityDialog.title">
      <v-card tile flat color="secondary_card">
        <v-card-text>
      <global-dashboard-form :key="entityDialog.key" :entity="entity" @afterSubmit="afterSubmit"></global-dashboard-form>
        </v-card-text>
      </v-card>
    </DialogShared>

    <div class="d-flex align-center mb-2">

      <v-spacer />

      <v-btn @click="addDashboardClick" icon>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="error" v-bind="attrs" v-on="on">
              mdi-plus
            </v-icon>
          </template>
          <span> {{ $t('add_new')}}</span>
        </v-tooltip>
      </v-btn>

    </div>

    <v-data-table class="transparent-background"  :dark="$vuetify.theme.dark" :loading="table.loading" :title="$t('global_dashboards')" :items="table.data" :headers="table.columns">

      <template v-slot:[`item.icon`]="{ item }">
        <v-icon size="sm" >{{item.configuration.icon}}</v-icon>
      </template>
      <template v-slot:[`item.actions`]="{ item }">

          <v-btn class="mr-1" color="green" small  @click="editDashboardClick(item)">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small v-bind="attrs" v-on="on">
                  mdi-pencil
                </v-icon>
              </template>
              <span> {{ $t('edit')}}</span>
            </v-tooltip>

          </v-btn>

          <v-btn class="mr-1" color="red" small @click="deleteDashboardClick(item)">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small v-bind="attrs" v-on="on">
                  mdi-delete-outline
                </v-icon>
              </template>
              <span> {{ $t('delete')}}</span>
            </v-tooltip>

          </v-btn>

          <v-btn class="mr-1" color="primary" small @click="dashboardConfigClick(item)">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small v-bind="attrs" v-on="on">
                  mdi-cog-outline
                </v-icon>
              </template>
              <span> {{ $t('configure')}}</span>
            </v-tooltip>
          </v-btn>

      </template>

    </v-data-table>
  </div>

</template>

<script>

import DialogShared from '@/app/shared/dialog/DialogShared'
import GlobalDashboardForm from '@/app/widgets/dashboard-widget/components/GlobalDashboardForm'

import apiService from '@/modules/api/csp'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DashboardList',
  components: { GlobalDashboardForm, DialogShared },

  computed: {
    ...mapGetters('dashboard', [
      'widgetsGetter',
      'dashboardsGetter'
    ])
  },

  data: (vm) => ({

    entity: null,

    entityDialog: {
      show: false,
      title: null,
      key: null
    },

    table: {
      loading: false,
      data: [],
      columns: [
        {
          value: 'id',
          text: vm.$t('id'),
          sortable: true,
          align: 'left'
        },
        {
          value: 'icon',
          text: vm.$t('icon'),
          sortable: false,
          align: 'left'
        },
        {
          value: 'name',
          text: vm.$t('name'),
          field: 'name',
          sortable: true,
          align: 'left'
        },
        {
          value: 'actions',
          text: vm.$t('actions'),
          sortable: false,
          align: 'right'
        }
      ]
    }

  }),

  mounted () {
    this.loadDashboards()
  },

  methods: {

    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    async loadDashboards () {
      this.table.loading = true
      await apiService.globalDashboard.getList().then(response => {
        this.table.loading = false
        this.table.data = response.data
      })
    },

    addDashboardClick () {
      this.entity = null
      this.entityDialog.key = this.$uuidKey()
      this.entityDialog.title = this.$t('add_dashboard')
      this.entityDialog.show = true
    },

    async dashboardConfigClick (dashboard) {
      window.location.href = `/global-dashboard-configure/${dashboard.id}`
    },

    deleteDashboardClick (dashboard) {
      this.$confirm(this.$t('are_you_sure_you_want_to_perform_this_operation', { what: this.$t('global_dashboard') })).then(confirmed => {
        if (confirmed) {
          this.loading = true

          apiService.globalDashboard.delete(dashboard.id).then((response) => {
            this.loadDashboards()

            this.notificationSnackBarAction({
              text: this.$t('dashboard_deleted'),
              color: 'success',
              show: true
            })
          }).catch(() => {
            this.notificationSnackBarAction({
              text: this.$t('cant_delete_dashboard'),
              color: 'error',
              show: true
            })
          })
        }
      })
    },

    editDashboardClick (dashboard) {
      this.entityDialog.key = this.$uuidKey()
      this.entityDialog.title = this.$t('update_dashboard')
      this.entityDialog.show = true

      this.entity = dashboard
    },
    async afterSubmit () {
      await this.loadDashboards()
      this.entityDialog.show = false
    }
  }
}
</script>

<style scoped>

</style>
