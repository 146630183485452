// Reference https://docs.google.com/document/d/1n85Uu_qY6Pcc7SNMy0m14q9bKH_uRNui/edit

import CADSpillFormat from './format'

export default class CADSpillFormat4 extends CADSpillFormat {
  name = 'CADSpillFormat4'

  data = {
    ems: null,
    esn: null,
    lat: null,
    lon: null,
    date: null,
    fire: null,
    time: null,
    city: null,
    house: null,
    phone: null,
    state: null,
    police: null,
    street: null,
    companyID: null,
    houseSufix: null,
    pilotNumber: null,
    uncertainty: null,
    customerName: null,
    locationInfo: null,
    telcoComment: null,
    confidencePct: null,
    classOfService: null,
    prefixDirection: null
  }

  constructor () {
    super()

    this.dataLength = 441
    this.responseType = 2

    for (const key in this.data) {
      Object.defineProperty(this, key, {
        get () {
          return this.data[key]
        }
      })
    }
  }

  setData (data) {
    for (const key in data) {
      this.data[key] = data[key]
    }

    return this
  }

  formatData () {
    if (!this.checkData()) {
      console.error(this.name + ':', 'Validation not passed')

      return {
        data: null,
        error: 'Validation not passed',
        written: -1
      }
    }

    const data = new Uint8Array(this.dataLength)

    data[0] = 0x02

    // Add Response Type
    let index = this.fillData(data, 1, this.responseType, 1)

    // Add Possition number
    index = this.fillData(data, 2, this.position, 2)

    // Carriage Return
    data[index++] = 0x0d

    // Telephone Number
    index = this.fillData(data, 5, this.phone, 14)

    // Space
    index = this.fillData(data, index, '', 3)

    // Time
    index = this.fillData(data, index, this.time, 5)

    // Space
    index = this.fillData(data, index, '', 4)

    // Date
    index = this.fillData(data, index, this.date, 5)

    // Carriage Return
    data[index++] = 0x0d

    // House#
    index = this.fillData(data, index, this.house, 8, true)

    // Space
    index = this.fillData(data, index, '', 1)

    // House# Suffix
    index = this.fillData(data, index, this.houseSufix, 4)

    // Space
    index = this.fillData(data, index, '', 1)

    // Prefix Direction
    index = this.fillData(data, index, this.prefixDirection, 2)

    // Space
    index = this.fillData(data, index, '', 1)

    // Street Name
    index = this.fillData(data, index, this.street, 47)

    // City
    index = this.fillData(data, index, this.city, 18)

    // State abbr.
    index = this.fillData(data, index, this.state, 2)

    // Space
    index = this.fillData(data, index, '', 1)

    // ESN.
    index = this.fillData(data, index, this.esn, 3)

    // Space
    index = this.fillData(data, index, '', 1)

    // Class of Service
    index = this.fillData(data, index, this.classOfService, 4)

    // Carriage Return
    data[index++] = 0x0d

    // Customer Name
    index = this.fillData(data, index, this.customerName, 32)

    // Carriage Return
    data[index++] = 0x0d

    // Spaces
    index = this.fillData(data, index, '', 16)

    // Pilot Number
    index = this.fillData(data, index, this.pilotNumber, 14)

    // Carriage Return
    data[index++] = 0x0d

    // Location Info
    index = this.fillData(data, index, this.locationInfo, 20)

    // Carriage Return
    data[index++] = 0x0d

    // Company ID
    index = this.fillData(data, index, this.companyID, 5)

    // Spaces
    index = this.fillData(data, index, '', 2)

    // Telco Comment
    index = this.fillData(data, index, this.telcoComment, 23)

    // Carriage Return
    data[index++] = 0x0d

    // New-Line Char.
    data[index++] = 0x0a

    // Police
    index = this.fillData(data, index, this.police, 31)

    // Carriage Return
    data[index++] = 0x0d

    // Fire
    index = this.fillData(data, index, this.fire, 31)

    // Carriage Return
    data[index++] = 0x0d

    // EMS
    index = this.fillData(data, index, this.ems, 31)

    // Carriage Return
    data[index++] = 0x0d

    // Label
    index = this.fillData(data, index, 'LAT')

    // Space
    index = this.fillData(data, index, '', 1)

    // Latitude
    index = this.fillData(data, index, this.lat, 11)

    // Space
    index = this.fillData(data, index, '', 1)

    // Label
    index = this.fillData(data, index, 'LON')

    // Space
    index = this.fillData(data, index, '', 1)

    // Longitude
    index = this.fillData(data, index, this.lon, 11)

    // Carriage Return
    data[index++] = 0x0d

    // Label
    index = this.fillData(data, index, 'METERS')

    // Space
    index = this.fillData(data, index, '', 1)

    // Uncertainty
    index = this.fillData(data, index, this.uncertainty, 7)

    // Spaces
    index = this.fillData(data, index, '', 5)

    // Label
    index = this.fillData(data, index, 'PERCENT')

    // Space
    index = this.fillData(data, index, '', 1)

    // Confidence Pct.
    index = this.fillData(data, index, this.confidencePct, 3)

    // Space
    index = this.fillData(data, index, '', 1)

    // Carriage Return
    data[index++] = 0x0d

    // Spaces (future)
    index = this.fillData(data, index, '', 31)

    // Carriage Return
    data[index++] = 0x0d

    // End of frame
    data[index] = 0x03

    return {
      data,
      error: null,
      written: index
    }
  }
}
