export default function realmDashboardApiModule (instance) {
  return {

    /**
     * Get realm dashboards
     * @param realmName Realm
     * @returns {Promise<*>}
     */
    getList: async (realmName) => {
      const resource = `/realm-dashboards/apps/${process.env.VUE_APP_ID}`

      return await instance.get(resource, {
        params: {
          realm_name: realmName
        }
      })
    },

    /**
     * Get single dashboard
     * @param realmName RealmName Name
     * @param id Dashboard id
     * @returns {Promise<*>}
     */
    getSingle: async (realmName, id) => {
      const resource = `/realm-dashboards/${id}/apps/${process.env.VUE_APP_ID}`

      return await instance.get(resource, {
        params: {
          realm_name: realmName
        }
      })
    },

    /**
     * Update realm dashboard
     * @param id - Dashboard Id
     * @param realmName - Realm Name
     * @param params {name, configuration}
     * @returns {Promise<*>}
     */
    update: async (id, realmName, data) => {
      const resource = `/realm-dashboards/${id}/apps/${process.env.VUE_APP_ID}`

      return await instance.patch(resource, data, {
        params: {
          realm_name: realmName
        }
      })
    },

    /**
     * Delete selected realm dashboard
     * @param realmName
     * @returns {Promise<*>}
     */
    deleteAllForRealm: async (realmName) => {
      const resource = `/realm-dashboards/apps/${process.env.VUE_APP_ID}`

      return await instance.delete(resource, {
        params: {
          realm_name: realmName
        }
      })
    },

    /**
     * Reset single dashboard for selected realm
     * @param id
     * @param realmName
     * @returns {Promise<*>}
     */
    delete: async (id, realmName) => {
      const resource = `/realm-dashboards/${id}/apps/${process.env.VUE_APP_ID}`

      return await instance.delete(resource, {
        params: {
          realm_name: realmName
        }
      })
    },

    /**
     * Reset all dashboards for all realms
     * @returns {Promise<*>}
     */
    deleteAll: async () => {
      const resource = `/realm-dashboards/all/apps/${process.env.VUE_APP_ID}`

      return await instance.delete(resource)
    }

  }
}
