<template>
  <div>
    <audio id="test-recordings-audio" ref="test-recordings-audio" :src="require('@/assets/audio/sounds/caller-audio-test.mp3')"></audio>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'RingingAudioComponent',
  data: () => ({
    ringing: false
  }),
  computed: {
    ...mapGetters('audioVolume', [
      'testRecordingsGetter'
    ]),
    ...mapGetters('settings', [
      'audioSettingsGetter'
    ])
  },
  watch: {
    testRecordingsGetter (val) {
      this.$refs['test-recordings-audio'].load()
      this.$refs['test-recordings-audio'].volume = this.audioSettingsGetter.recordingsVolume
      this.$refs['test-recordings-audio'].setSinkId(this.audioSettingsGetter.recordingsOutputDeviceId)
      val ? this.$refs['test-recordings-audio'].play() : this.$refs['test-recordings-audio'].pause()
    },
    'audioSettingsGetter.recordingsOutputDeviceId' (val) {
      this.$refs['test-recordings-audio'].setSinkId(val)
    },
    'audioSettingsGetter.recordingsVolume' (val) {
      this.$refs['test-recordings-audio'].volume = val
    }
  },
  methods: {
    ...mapMutations('audioVolume', [
      'testRecordingsMutator'
    ])
  },
  mounted () {
    this.$refs['test-recordings-audio'].addEventListener('pause', () => {
      this.testRecordingsMutator(false)
    })
  }
}
</script>

<style scoped>

</style>
