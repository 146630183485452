export default function permissionsApiModule (instance) {
  return {

    /**
     * Get list of roles
     * @param data
     * @returns {Promise<*>}
     */
    list: async (data) => {
      const appId = process.env.VUE_APP_PERMISSION_APP_ID
      const resource = `/permissions/${appId}`

      const response = await instance.get(resource, data)

      return response
    },

    /**
     * Sync permissions for user
     * @param userId
     * @param permissions
     * @returns {Promise<AxiosResponse<any>>}
     */
    syncPermissions: async (userId, permissions) => {
      const resource = '/permissions/sync-permissions'

      const response = await instance.post(resource, {
        user_id: userId,
        permissions: permissions
      })

      return response
    },

    /**
     * Return list of roles
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    get: async (data) => {
      const resource = '/permissions'

      const response = await instance.get(resource, data)

      return response
    },

    /**
     * Assign permissions to user
     * @param userId
     * @param rolpermissionses
     * @returns {Promise<AxiosResponse<any>>}
     */
    assignPermissions: async (userId, permissions) => {
      const resource = '/permissions/assign-permissions'

      const response = await instance.post(resource, {
        user_id: userId,
        permissions: permissions
      })

      return response
    },

    /**
     * Revoke permissions from user
     * @param userId
     * @param permissions
     * @returns {Promise<AxiosResponse<any>>}
     */
    revokePermissions: async (userId, permissions) => {
      const resource = '/permissions/revoke-permissions'

      const response = await instance.post(resource, {
        user_id: userId,
        permissions: permissions
      })

      return response
    }

  }
}
