<template>
  <div class="pt-6">
    <div v-if="audioSettingsGetter.microphonePermissionState">
      <!--   Ringing Volume   -->
      <v-row>
        <v-col cols="4">
          <RingingOutputComponent />
        </v-col>
        <v-col align-self="end" cols="8">
          <RingingVolumeComponent />
        </v-col>
      </v-row>
      <!--   Microphone Volume   -->
      <v-row>
        <v-col cols="4">
          <MicrophoneComponent />
        </v-col>
        <v-col align-self="end" cols="8">
          <InputVolumeComponent />
        </v-col>
      </v-row>
      <!--   Caller Volume   -->
      <v-row>
        <v-col cols="4">
          <CallerOutputComponent />
        </v-col>
        <v-col align-self="end" cols="8">
          <CallerVolumeComponent />
        </v-col>
      </v-row>
      <!--   Recordings Volume   -->
      <v-row>
        <v-col cols="4">
          <recordings-output-component />
        </v-col>
        <v-col align-self="end" cols="8">
          <recordings-volume-component />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <notification-output-component />
        </v-col>
        <v-col align-self="end" cols="8">
          <notification-volume-component />
        </v-col>
      </v-row>
      <!--   Itr Volume   -->
      <!--      <v-row>
              <v-col cols="4">
                <ItrOutputComponent />
              </v-col>
              <v-col align-self="end" cols="8">
                <ItrVolumeComponent />
              </v-col>
            </v-row>-->
    </div>
    <div v-else class="text-h5 text-center">
      {{ $t("application_does_not_have_access_to_your_microphone") }}
      <div class="mt-5">
        <v-btn @click="getMicrophonePermission()" color="error">
          {{ $t("request_microphone_access") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
/* Mixins */
import microphonePermissionMixin from '@/mixins/audio/microphone-permission.mixin'

/* Components */
import MicrophoneComponent from '@/app/widgets/settings-widget/components/audio-control-setting/MicrophoneComponent'
import InputVolumeComponent from '@/app/widgets/settings-widget/components/audio-control-setting/InputVolumeComponent'
import CallerOutputComponent from '@/app/widgets/settings-widget/components/audio-control-setting/CallerOutputComponent'
import CallerVolumeComponent from '@/app/widgets/settings-widget/components/audio-control-setting/CallerVolumeComponent'
import RingingVolumeComponent from '@/app/widgets/settings-widget/components/audio-control-setting/RingingVolumeComponent'
import RingingOutputComponent from '@/app/widgets/settings-widget/components/audio-control-setting/RingingOutputComponent'
import RecordingsOutputComponent from '@/app/widgets/settings-widget/components/audio-control-setting/RecordingsOutputComponent'
import RecordingsVolumeComponent from '@/app/widgets/settings-widget/components/audio-control-setting/RecordingsVolumeComponent'
/* import ItrVolumeComponent from '@/app/widgets/settings-widget/components/audio-control-setting/ItrVolumeComponent' */
/* import ItrOutputComponent from '@/app/widgets/settings-widget/components/audio-control-setting/ItrOutputComponent' */
import NotificationOutputComponent from '@/app/widgets/settings-widget/components/audio-control-setting/NotificationOutputComponent.vue'
import NotificationVolumeComponent from '@/app/widgets/settings-widget/components/audio-control-setting/NotificationVolumeComponent.vue'

export default {
  name: 'AudioArbitrationTab',
  mixins: [microphonePermissionMixin],
  components: {
    MicrophoneComponent,
    InputVolumeComponent,
    CallerOutputComponent,
    CallerVolumeComponent,
    RingingVolumeComponent,
    RingingOutputComponent,
    RecordingsOutputComponent,
    RecordingsVolumeComponent,
    NotificationOutputComponent,
    NotificationVolumeComponent
    /* ItrOutputComponent, */
    /* ItrVolumeComponent */
  },
  computed: {
    ...mapGetters('settings', ['audioSettingsGetter'])
  }
}
</script>

<style lang="scss" scoped></style>
