<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn
        @click="onLogoutClick"
        icon
        color="grey_light"
        text
        v-on="on"
      >
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </template>
    <span>{{ $t('logout') }}</span>
  </v-tooltip>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'LogoutButton',
  data: () => ({
    loading: false
  }),
  computed: {
    ...mapGetters('global', ['authUserGetter']),
    ...mapGetters('settings', ['userSettingsGetter', 'getUserSetting', 'sipDefaultAddressGetter'])
  },
  methods: {

    ...mapActions('global', ['authLogoutAction']),

    onLogoutClick () {
      const email = this.authUserGetter?.email
      const userID = this.authUserGetter?.id

      this.loading = true

      this.authLogoutAction().then(async (response) => {
        try {
          await this.sendActivityLog('logout_attempt', {
            email,
            userID,
            status: response && response.status.toString(),
            response: response && response.data && JSON.stringify(response.data),
            response_headers: response && response.headers && JSON.stringify(response.headers)
          })
        } finally {
          location.reload()
        }
      }).catch((error) => {
        this.sendActivityLog('logout_attempt', {
          email,
          userID,
          status: error.response && error.response.status.toString(),
          response: error.response && error.response.data && JSON.stringify(error.response.data),
          response_headers: error.response && error.response.headers && JSON.stringify(error.response.headers)
        })
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
