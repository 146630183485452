export default () => ({

  callDetails: [],
  callDetailsHeld: {},
  callDetailsAdr: {
    comment: {},
    serviceInfo: {},
    deviceInfo: {},
    providerInfo: {},
    subscriberInfo: {}
  },
  callDetailsModal: false

})
