export default function usersApiModule (instance) {
  return {

    /**
     * Search user
     * @param data
     * @returns {Promise<*>}
     */
    search: async (data) => {
      const resource = '/users/search/' + data.search

      const response = await instance.get(resource, data)

      return response
    },

    /**
     * Return list of users
     *
     * @param data
     * @returns {Promise<*>}
     */
    list: async (data) => {
      const resource = '/users'

      const response = await instance.get(resource, data)

      return response
    },

    /**
     * Get single user
     *
     * @param id
     * @returns {Promise<*>}
     */
    get: async (id) => {
      const resource = '/users/' + id

      const response = await instance.get(resource)

      return response
    },

    /**
     * Create User
     *
     * @param data
     * @returns {Promise<*>}
     */
    create: async (data) => {
      const resource = '/users'

      const response = await instance.post(resource, data)

      return response
    },

    /**
     * Update user
     *
     * @param id
     * @param data
     * @returns {Promise<*>}
     */
    edit: async (id, data) => {
      const resource = `/users/${id}`

      const response = await instance.patch(resource, data)

      return response
    },

    /**
     * Import Users
     *
     * @param data
     * @returns {Promise<*>}
     */
    importUsers: async (data) => {
      const resource = '/users/create-by-file'

      const formData = new FormData()
      formData.append('file', data.file)

      const response = await instance.post(resource, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      return response
    }

  }
}
