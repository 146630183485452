export default function contactGroupsApiModule (instance) {
  return {

    /**
     * Get contact book groups
     * @param data
     * @returns {Promise<*>}
     */
    get: async (data) => {
      const resource = '/contact-book-groups'

      const response = await instance.get(resource, {
        params: data
      })

      return response
    },

    /**
     * Create single contact book group
     * @param data
     * @returns {Promise<*>}
     */
    create: async (data) => {
      const resource = '/contact-book-groups'

      const response = await instance.post(resource, data)

      return response
    },

    /**
     * Update contact book group
     * @param id
     * @param data
     * @returns {Promise<*>}
     */
    edit: async (id, data) => {
      const resource = `/contact-book-groups/${id}`

      const response = await instance.patch(resource, data)

      return response
    },

    /**
     * Delete contact book group
     * @param id
     * @returns {Promise<*>}
     */
    delete: async (id) => {
      const resource = `/contact-book-groups/${id}`

      const response = await instance.delete(resource)

      return response
    }

  }
}
