export const setCallDetailsMutator = async (state, payload) => {
  state.callDetails = payload
}
export const clearCallDetailsMutator = async (state) => {
  state.callDetails = []
}

export const setCallDetailsHeldMutator = async (state, payload) => {
  state.callDetailsHeld = payload
}

export const clearCallDetailsHeldMutator = async (state) => {
  state.callDetailsHeld = {}
}

export const setCallDetailsAdrMutator = async (state, payload) => {
  state.callDetailsAdr[payload.type] = payload.value
}

export const clearCallDetailsAdrMutator = async (state, payload) => {
  state.callDetailsAdr = {
    comment: {},
    serviceInfo: {},
    deviceInfo: {},
    providerInfo: {},
    subscriberInfo: {}
  }
}

export const callDetailsModalMutator = async (state) => {
  state.callDetailsModal = !state.callDetailsModal
}
