export default function fccMasterRegistry (instance) {
  return {

    search: async (data) => {
      try {
        const resource = '/fcc/search-master-registry'

        const response = await instance.get(resource, {
          params: data
        })
        return response
      } catch (e) {
        console.log(e)
      }
    }
  }
}
