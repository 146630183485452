export default function syncApiModule (instance) {
  return {
    sync: async (payload) => {
      const resource = '/sync'

      const response = await instance.get(resource, {
        params: payload
      })

      return response
    }
  }
}
