import localStorageService from '@/util/local-storage-service'
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    audio: null
  }),
  computed: {
    ...mapGetters('settings', ['getPrerecordedAudiosGetting', 'getUserSetting'])
  },
  methods: {
    async checkShouldPlayAudio (host) {
      if (!host) return

      const allowPlay = JSON.parse(JSON.stringify(this.getUserSetting('play_prerecorded_audio')))

      if (!allowPlay.value.active) return

      const prerecordedAudio = this.getPrerecordedAudiosGetting.find(item => item.name === host)

      if (!prerecordedAudio) return

      await this.playPreRecordedAudio(prerecordedAudio.file_url)
    },
    async playPreRecordedAudio (url) {
      this.audio = new Audio()

      this.audio.src = await this.downloadFile(url)

      await this.audio.play()

      const mediaStream = this.audio.captureStream()
      const getAudioTracks = mediaStream.getAudioTracks()

      this.$sip.answeredCallSession.connection.getSenders().forEach(item => {
        item.replaceTrack(getAudioTracks[0])
      })

      this.replaceMicrophoneAudio()
    },
    replaceMicrophoneAudio () {
      this.audio.addEventListener('ended', async () => {
        await localStorageService.setMicrophoneSource(this.$sip.answeredCallSession)
      })
    },
    async downloadFile (url) {
      const response = await fetch(`${process.env.VUE_APP_API_URL}proxy/download?endpoint_key=${btoa(url)}&type=b64`, {
        headers: {
          Authorization: `Bearer ${localStorageService.getUserAccessToken()}`
        }
      })

      const data = await response.blob()

      return URL.createObjectURL(data)
    },
    endRecording () {
      if (!this.audio) return

      this.audio.pause()

      this.audio = null
    }
  },
  mounted () {
    this.$event.listen('incoming-ended', this.endRecording)
    this.$event.listen('outgoing-ended', this.endRecording)
  }
}
