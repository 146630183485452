export default function TTYPredefinedMessagesApiModule (instance) {
  return {
    getList: async ({ realm, id }) => {
      let resource = null

      if (realm) {
        resource = `/chat-text-lines/${realm}`
      } else {
        resource = `/chat-text-lines${id}`
      }

      if (!resource) return
      return await instance.get(resource)
    }
  }
}
