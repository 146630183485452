var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"tile":"","flat":"","color":"secondary_card"}},[_c('v-card-title',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-switch',_vm._g({attrs:{"label":_vm.$t('prerecorded_audio_permission')},model:{value:(_vm.playPrerecordedAudioValue),callback:function ($$v) {_vm.playPrerecordedAudioValue=$$v},expression:"playPrerecordedAudioValue"}},on))]}}])}),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","disabled":!!!_vm.$can('can_create_prerecorded_audios')},on:{"click":_vm.openToCreate}},on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-plus ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('add_new')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","disabled":!!!_vm.$can('can_edit_prerecorded_audios') || _vm.selected.length === 0},on:{"click":_vm.openToEdit}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-pencil ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('edit')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","loading":_vm.loading.delete,"disabled":!!!_vm.$can('can_delete_prerecorded_audios')}},on),[_c('v-icon',{attrs:{"color":"error","disabled":_vm.selected.length === 0},on:{"click":_vm.remove}},[_vm._v(" mdi-delete-outline ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('delete')))])])],1),_c('v-card-text',[_c('v-data-table',{staticClass:"transparent-background",attrs:{"headers":_vm.headers,"loading":_vm.loading.data,"items-per-page":10,"items":_vm.getPrerecordedAudiosGetting,"show-select":"","single-select":""},scopedSlots:_vm._u([{key:"item.file_url",fn:function(ref){
var item = ref.item;
return [_c('audio-player',{key:item.file_url,staticClass:"audio-player",attrs:{"flat":true,"auto-play":false,"file":item.file_url}})]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),_c('validation-observer',{ref:"observer"},[_c('DialogShared',{staticClass:"white--text",attrs:{"eager":"","max-width":"600","title":_vm.dialogTitle},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-spacer'),(_vm.mode === 'create')?_c('v-btn',{staticClass:"pa-7",attrs:{"color":"error","loading":_vm.loading.add,"disabled":_vm.loading.add},on:{"click":_vm.add}},[_vm._v(" "+_vm._s(_vm.$t('add'))+" ")]):_vm._e(),(_vm.mode === 'edit')?_c('v-btn',{staticClass:"pa-7",attrs:{"color":"error","loading":_vm.loading.edit,"disabled":_vm.loading.edit},on:{"click":_vm.edit}},[_vm._v(" "+_vm._s(_vm.$t('edit'))+" ")]):_vm._e()]},proxy:true}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"form",staticClass:"pt-10",attrs:{"novalidate":""}},[_c('v-row',{staticClass:"white--text",attrs:{"tile":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('file'),"rules":_vm.mode === 'create' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"truncate-length":"15","name":"ringtone","accept":".mp3,.wav,.aac,.mpga","label":_vm.$t('choose_file'),"dark":_vm.$vuetify.theme.dark,"show-size":"","outlined":"","error-messages":errors},model:{value:(_vm.newPrerecordedAudio.file),callback:function ($$v) {_vm.$set(_vm.newPrerecordedAudio, "file", $$v)},expression:"newPrerecordedAudio.file"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('prerecorded_name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"name":"name","label":_vm.$t('prerecorded_name') + '*',"dark":_vm.$vuetify.theme.dark,"clearable":"","outlined":"","counter":"100"},model:{value:(_vm.newPrerecordedAudio.name),callback:function ($$v) {_vm.$set(_vm.newPrerecordedAudio, "name", $$v)},expression:"newPrerecordedAudio.name"}})]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }