export default class SipSessionPlugin {
    session;
    events = [
      'peerconnection',
      'connecting',
      'sending',
      'progress',
      'accepted',
      'confirmed',
      'ended',
      'failed',
      'newDTMF',
      'newInfo',
      'hold',
      'unhold',
      'muted',
      'unmuted',
      'reinvite',
      'update',
      'refer',
      'replaces',
      'sdp',
      'getusermediafailed',
      'peerconnection:createofferfailed',
      'peerconnection:createanswerfailed',
      'peerconnection:setlocaldescriptionfailed',
      'peerconnection:setremotedescriptionfailed'
    ];

    constructor (session) {
      this.session = session
    }

    triggerEvents () {
      this.events.forEach(item => {
        this.session.on(item, e => {
          this.triggerCustomEvents(this.session.direction, item, e)
        })
      })
    }

    triggerCustomEvents (direction, item, e) {
      const event = new Event(`${direction}-${item}`)
      event.eventData = e
      event.session = this.session
      event.sessionId = this.session.id
      document.dispatchEvent(event)
    }
}
