export default function languageApiModule (instance) {
  return {

    /**
     * Return list of languages
     *
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    list: async (data) => {
      const resource = '/languages'

      const response = await instance.get(resource, data)

      return response
    }

  }
}
