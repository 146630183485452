import store from '@/store'
export default {
  methods: {
    async getMicrophonePermission () {
      await navigator.permissions.query({ name: 'microphone' }).then(permissionStatus => {
        this.updateMicrophonePermission('microphonePermissionState', permissionStatus.state)
        permissionStatus.addEventListener('change', e => {
          this.updateMicrophonePermission('microphonePermissionState', e.target.state)
        })
      })
    },
    async requestMicrophonePermission () {
      try {
        await navigator.mediaDevices.getUserMedia({ video: false, audio: true })
      } catch (err) {
        console.log(err)
      }
    },
    fireMicrophonePermissionEvent (state) {
      const event = new Event('microphonePermissionUpdated')
      event.permissionStatus = state
      document.dispatchEvent(event)
    },
    displayMicrophonePermissionNotification (show) {
      store.commit('notifications/notificationDialogMutator', {
        show: show,
        header: 'Permission error',
        color: 'error',
        text: 'CHS requires access to your microphone. Click the microphone blocked icon'
      })
    },
    async updateMicrophonePermission (type, state) {
      switch (state) {
        case 'prompt':
        case 'denied':
          store.commit('settings/audioSettingsMutation', { type: type, data: false })
          this.fireMicrophonePermissionEvent(false)
          this.displayMicrophonePermissionNotification(true)
          await this.requestMicrophonePermission()
          break
        case 'granted':
          store.commit('settings/audioSettingsMutation', { type: type, data: true })
          this.fireMicrophonePermissionEvent(true)
          this.displayMicrophonePermissionNotification(false)
          break
      }
    }
  }
}
