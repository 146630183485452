<template>
  <v-dialog
    v-model="dialogOpen"
    hide-overlay
    max-width="600"
    no-click-animation
    persistent
    transition="dialog-bottom-transition"
  >
    <template v-slot:default>
      <v-card :class="'chs-dialog-' + notificationDialogGetter.color" dark>
        <v-card-title class="chs-dialog-header">
          <v-icon v-if="notificationDialogGetter.color == 'error'" color="error"
            >mdi-alert-circle
          </v-icon>
          <v-icon
            v-if="notificationDialogGetter.color == 'warning'"
            color="warning"
            >mdi-alert
          </v-icon>
          <v-icon
            v-if="notificationDialogGetter.color == 'success'"
            color="success"
            >mdi-check-circle
          </v-icon>

          <span class="ml-5">
            {{ notificationDialogGetter.header }}
          </span>
        </v-card-title>

        <v-card-text>
          <div class="text-h7 text-center pa-4">
            {{ notificationDialogGetter.text }}
          </div>
          <div v-if="notificationDialogGetter.icon" class="text-center">
            <img :src="require('@/assets/images/access_blocked.svg')" />
          </div>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DialogComponent',
  data: () => ({
    dialogOpen: false
  }),
  computed: {
    ...mapGetters('notifications', ['notificationDialogGetter'])
  },
  methods: {
    ...mapActions('notifications', ['notificationDialogAction'])
  },
  watch: {
    'notificationDialogGetter.show' (val) {
      this.dialogOpen = val
    },
    dialogOpen (val) {
      if (!val) {
        this.notificationDialogAction({
          text: this.notificationDialogGetter.text,
          header: this.notificationDialogGetter.header,
          color: this.notificationDialogGetter.color,
          show: false
        })
      }
    }
  }
}
</script>

<style scoped></style>
