export default () => ({

  callHistory: {
    data: [],
    meta: {
      total: 0
    }
  }

})
