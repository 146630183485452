export default function callHistoryLocalCacherModule (indexDB) {
  return {
    get: function (payload) {
      const data = indexDB.callHistory.get(payload)

      return data
    },
    updateTable: (data) => {
      indexDB.callHistory.insertMany(data)
    },

    last: function () {
      return indexDB.callHistory.last()
    },
    clear () {
      indexDB.callHistory.clear()
    },
    count: function (filter) {
      return indexDB.callHistory.count(filter)
    }
  }
}
