export default function layoutsApiModule (instance) {
  return {

    /**
     * Return list of layouts
     * @param data
     * @returns {Promise<*>}
     */
    list: async (data) => {
      const resource = '/layouts'

      const response = await instance.get(resource, data)

      return response
    },

    /**
     * Get single layout
     *
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    get: async (id) => {
      const resource = '/layouts/' + id

      const response = await instance.get(resource)

      return response
    },

    /**
     * Create Layout
     *
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    create: async (data) => {
      const resource = '/layouts'

      const response = await instance.post(resource, data)

      return response
    },

    /**
     * Assign Layout to logged user
     * @param id
     * @returns {Promise<*>}
     */
    assignLayout: async (id) => {
      const resource = '/layouts/assign-layout'

      const response = await instance.post(resource, {
        id: id
      })

      return response
    },

    /**
     * Update layout
     *
     * @param id
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    edit: async (id, data) => {
      const resource = `/layouts/${id}`

      const response = await instance.patch(resource, data)

      return response
    },

    /**
     * Delete Layout
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    delete: async (id) => {
      const resource = `/layouts/${id}`

      const response = await instance.delete(resource)

      return response
    }

  }
}
