export const updateGlobalRingtonesMutator = async (state, payload) => {
  state.globalRingtones = payload
}

export const updateRealmRingtonesMutator = async (state, payload) => {
  state.realmRingtones = payload
}

export const permissions = (state, role) => {
  state.permissions = role
}
export const roles = (state, role) => {
  state.roles = role
}
