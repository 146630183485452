<template>
  <v-dialog
    v-model="show"
    :eager="eager"
    :max-width="maxWidth"
    hide-overlay
    no-click-animation
    persistent
  >
    <v-card :dark="$vuetify.theme.dark" color="secondary_card" tile>
      <v-card-title color="primary_card">
        <v-toolbar-title :dark="$vuetify.theme.dark">
          {{ title }}
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn :dark="$vuetify.theme.dark" icon @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <slot></slot>
      </v-card-text>
      <v-card-actions>
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'DialogShared',
  props: ['value', 'max-width', 'eager', 'title'],
  data: () => ({
    show: false
  }),
  watch: {
    value (val) {
      this.show = val
    },
    show (val) {
      this.$emit('input', val)
    }
  }
}
</script>
