export default function userApiModule (instance) {
  return {

    /**
     * Get logged user data
     * @returns {*}
     */
    async get () {
      return await instance.get('/auth/me')
    },

    /**
     * Authenticate user
     * @param payload
     * @returns {*}
     */
    async login (payload) {
      return await instance.post('auth/login', payload)
    },

    /**
     * Logout user from api
     * @returns {*}
     */
    async logout () {
      return await instance.post('auth/logout')
    },

    /**
     * Change password api
     * @param data
     * @returns {Promise<*>}
     */
    async changePassword (data) {
      return await instance.post('auth/reset-password', data)
    },

    /**
     * Submit phone number for 2fa
     * @param phoneNumber
     * @returns {Promise<*>}
     */
    async send2faToPhoneNumber (phoneNumber) {
      return await instance.post('2fa/send', {
        phone_number: phoneNumber
      })
    },

    /**
     * Validate if 2fa code is valid
     * @param data
     * @returns {Promise<*>}
     */
    async validate2faCode (data) {
      return await instance.patch('2fa/submit', {
        verification_id: data.verification_id,
        verification_code: data.verification_code,
        initial_phone_verify: true
      })
    }
  }
}
