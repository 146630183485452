import cacheControl from '@/helpers/cache.control'

export default function sipApiModule (instance) {
  return {

    /**
     * Get realms
     * @returns {Promise<*>}
     */
    getRealms: async () => {
      const timestamp = cacheControl.getTimestamp('sipAddress')

      const resource = `/sipaddresses/realms?${timestamp}`

      const response = await instance.get(resource)

      return response.data
    },

    /**
     * Get user realms
     * @param userId User Id
     * @returns {Promise<*>}
     */
    getUserRealms: async (userId) => {
      const resource = `/sipaddresses/realms/${userId}`

      const response = await instance.get(resource)

      return response.data
    },

    /**
     * Get logged user sip addresses
     * @returns {Promise<*>}
     */
    getSipAddresses: async () => {
      const timestamp = cacheControl.getTimestamp('sipAddress')

      const resource = `/sipaddresses?${timestamp}`

      const response = await instance.get(resource)

      return response.data
    },

    /**
     * Returns default sip credentials.
     *
     * @return { Promise } response
     */
    getDefaultSipAddresses: async () => {
      const timestamp = cacheControl.getTimestamp('sipAddress')

      const resource = `/sipaddresses/default?${timestamp}`

      const response = await instance.get(resource)

      return response.data
    },

    /**
     * Returns default sip credentials.
     *
     * @param { Object } data
     *
     * @return { Promise } response
     */
    addSipAddress: async data => {
      const timestamp = cacheControl.getTimestamp('sipAddress', 'replace')

      const resource = `/sipaddresses?${timestamp}`

      const response = await instance.post(resource, data)

      return response.data
    },

    updateDefaultSipAddress: async data => {
      const timestamp = cacheControl.getTimestamp('sipAddress', 'replace')

      const resource = `/sipaddresses/default/${data.id}?${timestamp}`

      const response = await instance.patch(resource, data)

      return response.data
    },

    /**
     * Update sip address
     * @param data
     * @returns {Promise<*>}
     */
    updateSipAddress: async data => {
      const timestamp = cacheControl.getTimestamp('sipAddress', 'replace')

      const resource = `/sipaddresses/${data.id}?${timestamp}`

      const response = await instance.patch(resource, data)

      return response.data
    },

    /**
     * Remove sip address
     * @param data
     * @returns {Promise<*>}
     */
    removeSipAddress: async data => {
      const timestamp = cacheControl.getTimestamp('sipAddress', 'replace')

      const resource = `/sipaddresses/${data}?${timestamp}`

      const response = await instance.delete(resource)

      return response.data
    }
  }
}
