export default () => ({
  callPhoneNumberState: null,
  dialPadStatusState: false,

  callOutgoingSettingsState: {

    mediaConstraints: {
      audio: true,
      video: false
    }
    /* pcConfig: {
            iceServers: [
                {
                    urls: [
                        'stun:stun.l.google.com:19302',
                        'stun:stun1.l.google.com:19302'
                    ]
                },
            ],
        } */

  }
})
