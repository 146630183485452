
export default function i3LoggerApiModule (instance) {
  return {
    callStartLogEvent (data) {
      const resource = 'i3-logger/call-start-log-event'
      instance.post(resource, data)
    },
    callEndLogEvent (data) {
      const resource = 'i3-logger/call-end-log-event'
      instance.post(resource, data)
    },
    locationQueryLogEvent (data) {
      const resource = 'i3-logger/location-query-log-event'
      instance.post(resource, data)
    },
    locationResponseLogEvent (data) {
      const resource = 'i3-logger/location-response-log-event'
      instance.post(resource, data)
    },
    additionalDataQueryLogEvent (data) {
      const resource = 'i3-logger/additional-data-query-log-event'
      instance.post(resource, data)
    },
    additionalDataResponseLogEvent (data) {
      const resource = 'i3-logger/additional-data-response-log-event'
      instance.post(resource, data)
    }
  }
}
