export default function realmSettingsApiModule (instance) {
  return {

    /**
     * Return list of realm settings
     * @param realmName
     * @returns {Promise<*>}
     */
    load: async (realmName = null) => {
      let resource = '/realm-settings'

      if (realmName) {
        resource = `/realm-settings?realm_name=${realmName}`
      }

      const response = await instance.get(resource)

      return response
    },

    /**
     * Update Realm Setting
     * @param id
     * @param data
     * @param realmName
     * @returns {Promise<*>}
     */
    edit: async (id, data, realmName = null) => {
      let resource = `/realm-settings/${id}`
      if (realmName) {
        resource = `/realm-settings/${id}?realm_name=${realmName}`
      }

      const response = await instance.patch(resource, data)

      return response
    }

  }
}
