<template>
  <div>
    <v-card tile flat color="secondary_card">
      <v-card-title>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-switch
              v-on="on"
              v-model="playPrerecordedAudioValue"
              :label="$t('prerecorded_audio_permission')"
            />
          </template>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon @click="openToCreate" :disabled="!!!$can('can_create_prerecorded_audios')">
              <v-icon color="error">
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('add_new')}}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn @click="openToEdit" icon v-on="on" :disabled="!!!$can('can_edit_prerecorded_audios') || selected.length === 0 ">
              <v-icon color="primary">
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('edit') }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn  v-on="on" icon :loading="loading.delete" :disabled="!!!$can('can_delete_prerecorded_audios')">
              <v-icon color="error" @click="remove" :disabled="selected.length === 0">
                mdi-delete-outline
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('delete') }}</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-data-table class="transparent-background"
          :headers="headers"
          :loading="loading.data"
          :items-per-page="10"
          :items="getPrerecordedAudiosGetting"
          show-select
          single-select
          v-model="selected"
        >
          <template v-slot:[`item.file_url`]="{ item }">
            <audio-player class="audio-player" :key="item.file_url" :flat="true" :auto-play="false" :file="item.file_url" ></audio-player>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <validation-observer ref="observer">
      <DialogShared v-model="dialog" eager max-width="600" :title="dialogTitle" class="white--text">
        <v-form class="pt-10" ref="form" novalidate >
          <v-row tile class="white--text">
            <v-col cols="6">
              <validation-provider v-slot="{ errors }" :name="$t('file')" :rules="mode === 'create' ? 'required' : ''">
                <v-file-input
                  v-model="newPrerecordedAudio.file"
                  truncate-length="15"
                  name="ringtone"
                  accept=".mp3,.wav,.aac,.mpga"
                  :label="$t('choose_file')"
                  :dark="$vuetify.theme.dark"
                  show-size
                  outlined
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <validation-provider v-slot="{ errors }" :name="$t('prerecorded_name')" rules="required">
                <v-text-field
                  :error-messages="errors"
                  name="name"
                  :label="$t('prerecorded_name') + '*'"
                  :dark="$vuetify.theme.dark"
                  clearable
                  outlined
                  v-model="newPrerecordedAudio.name"
                  counter="100"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </v-form>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            class="pa-7"
            v-if="mode === 'create'"
            :loading="loading.add"
            :disabled="loading.add"
            @click="add"
          >
            {{ $t('add') }}
          </v-btn>
          <v-btn
            color="error"
            class="pa-7"
            v-if="mode === 'edit'"
            :loading="loading.edit"
            :disabled="loading.edit"
            @click="edit"
          >
            {{ $t('edit') }}
          </v-btn>
        </template>
      </DialogShared>

    </validation-observer>
  </div>
</template>
<script>
import AudioPlayer from '@/app/widgets/admin-panel-widget/components/AudioComponent'
import DialogShared from '@/app/shared/dialog/DialogShared'

import apiService from '@/modules/api/csp'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

import '@/rules/validation.rules.js'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PrerecordedAudioTab',
  data: () => ({
    loading: {
      data: false,
      add: false,
      edit: false,
      delete: false,
      default: false
    },
    dialog: false,
    mode: 'create',
    newPrerecordedAudio: {
      name: null,
      file: null
    },
    selected: [],
    valid: false,
    savedAudios: [],
    playPrerecordedAudio: {},
    playPrerecordedAudioValue: false
  }),
  computed: {
    headers () {
      return [
        { text: this.$t('prerecorded_audio'), value: 'file_url' },
        { text: this.$t('prerecorded_name'), value: 'name' }
      ]
    },
    dialogTitle () {
      return this.mode === 'create' ? this.$t('add_new_prerecorded_audio') : this.$t('edit_prerecorded_audio')
    },
    ...mapGetters('settings', ['getUserSetting', 'getPrerecordedAudiosGetting'])
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    DialogShared,
    AudioPlayer
  },
  methods: {
    ...mapActions('settings', ['prerecordedAudioAction', 'loadUserSettingsAction']),
    async list () {
      const response = await apiService.prerecordedAudios.list()

      if (response.status !== 200) return

      this.savedAudios = response.data
    },
    async add () {
      const isValid = await this.$refs.observer.validate()

      if (!isValid) return

      await apiService.prerecordedAudios.create(this.newPrerecordedAudio)

      this.prerecordedAudioAction()

      this.dialog = false
    },
    async edit () {
      const isValid = await this.$refs.observer.validate()

      if (!isValid) return

      await apiService.prerecordedAudios.edit({ ...this.newPrerecordedAudio })

      this.prerecordedAudioAction()

      this.dialog = false
    },
    async remove () {
      await apiService.prerecordedAudios.delete(this.selected[0].id)

      this.prerecordedAudioAction()
    },
    openToEdit () {
      this.mode = 'edit'

      this.newPrerecordedAudio = { ...this.selected[0] }
      this.dialog = !this.dialog
    },
    openToCreate () {
      this.mode = 'create'

      this.newPrerecordedAudio = {
        name: null
      }

      this.dialog = !this.dialog
    }
  },
  watch: {
    playPrerecordedAudioValue (val) {
      apiService.userSettings.edit(this.playPrerecordedAudio.setting_id, {
        value: {
          active: val
        }
      }).then(async () => {
        this.loadUserSettingsAction()
      })
    }
  },
  mounted () {
    this.list()

    this.playPrerecordedAudio = JSON.parse(JSON.stringify(this.getUserSetting('play_prerecorded_audio')))
    this.playPrerecordedAudioValue = this.playPrerecordedAudio.value.active
  }
}
</script>
