export default function speedDialApiModule (instance) {
  return {

    /**
     * Returns Contacts Hot Buttons.
     *
     * @return { Promise } response
     */
    get: async (payload) => {
      const resource = '/hot-contact-book-buttons'

      const response = await instance.get(resource, {
        params: payload
      })

      return response
    },

    /**
     * Create Contacts Hot Buttons.
     *
     * @param { Object } data
     *
     * @return { Promise } response
     */
    create: async (data) => {
      const resource = '/hot-contact-book-buttons'

      const response = await instance.post(resource, data)

      return response
    },

    /**
     * Edit Contacts Hot Buttons by id.
     *
     * @param { Number } id
     *
     * @param { Object } data
     *
     * @return { Promise } response
     */
    edit: async (id, data) => {
      const resource = `/hot-contact-book-buttons/${id}`

      data.is_folder = Boolean(data.is_folder)

      const response = await instance.patch(resource, data)

      return response
    },

    /**
     * Delete Contacts Hot Buttons by id.
     *
     * @param { Number } id
     *
     * @return { Promise } response
     */
    delete: async (id) => {
      const resource = `/hot-contact-book-buttons/${id}`

      const response = await instance.delete(resource)

      return response
    }
  }
}
