export default function incorrectLocationApiModule (instance) {
  return {

    /**
     * Get list of incorrect location.
     * @param callID
     * @returns {Promise<*>}
     */
    getIncorrectLocations: async (callID) => {
      const resource = `/incorrect-locations/${callID}`
      return await instance.get(resource)
    },

    /**
     * Save incorrect location
     * @param data
     * @returns {Promise<*>}
     */
    saveIncorrectLocation: async (data) => {
      const resource = '/incorrect-locations'
      return await instance.post(resource, data)
    }

  }
}
