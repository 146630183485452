export const dateTimeGetter = state => {
  return state.dateTimeState
}

export const esrkNumberGetter = state => {
  return state.esrkNumberState
}

export const incidentIDGetter = state => {
  return state.incidentID
}
