const aliases = {
  // Start of STS
  ALLEE: 'ALY',
  ALLEY: 'ALY',
  ALLY: 'ALY',
  ALY: 'ALY',
  ANEX: 'ANX',
  ANNEX: 'ANX',
  ANNX: 'ANX',
  ANX: 'ANX',
  ARC: 'ARC',
  ARCADE: 'ARC',
  AV: 'AVE',
  AVE: 'AVE',
  AVEN: 'AVE',
  AVENU: 'AVE',
  AVENUE: 'AVE',
  AVN: 'AVE',
  AVNUE: 'AVE',
  BAYOO: 'BYU',
  BAYOU: 'BYU',
  BCH: 'BCH',
  BEACH: 'BCH',
  BEND: 'BND',
  BND: 'BND',
  BLF: 'BLF',
  BLUF: 'BLF',
  BLUFF: 'BLF',
  BLUFFS: 'BLF',
  BOT: 'BTM',
  BTM: 'BTM',
  BOTTM: 'BTM',
  BOTTOM: 'BTM',
  BLVD: 'BLVD',
  BOUL: 'BLVD',
  BOULEVARD: 'BLVD',
  BOULV: 'BLVD',
  BR: 'BR',
  BRNCH: 'BR',
  BRANCH: 'BR',
  BRDGE: 'BRG',
  BRG: 'BRG',
  BRIDGE: 'BRG',
  BRK: 'BRK',
  BROOK: 'BRK',
  BROOKS: 'BRK',
  BURG: 'BRK',
  BURGS: 'BRK',
  BYP: 'BYP',
  BYPA: 'BYP',
  BYPAS: 'BYP',
  BYPASS: 'BYP',
  BYPS: 'BYP',
  CAMP: 'CP',
  CP: 'CP',
  CMP: 'CP',
  CANYN: 'CYN',
  CANYON: 'CYN',
  CNYN: 'CYN',
  CAPE: 'CPE',
  CPE: 'CPE',
  CAUSEWAY: 'CSWY',
  CAUSWA: 'CSWY',
  CSWY: 'CSWY',
  CEN: 'CTR',
  CENT: 'CTR',
  CENTER: 'CTR',
  CENTR: 'CTR',
  CENTRE: 'CTR',
  CNTER: 'CTR',
  CNTR: 'CTR',
  CTR: 'CTR',
  CENTERS: 'CTR',
  CIR: 'CIR',
  CIRC: 'CIR',
  CIRCL: 'CIR',
  CIRCLE: 'CIR',
  CRCL: 'CIR',
  CRCLE: 'CIR',
  CIRCLES: 'CIR',
  CLF: 'CLF',
  CLIFF: 'CLF',
  CLFS: 'CLFS',
  CLIFFS: 'CLFS',
  CLB: 'CLB',
  CLUB: 'CLB',
  COMMON: 'CLB',
  COMMONS: 'CLB',
  COR: 'COR',
  CORNER: 'COR',
  CORNERS: 'CORS',
  CORS: 'CORS',
  COURSE: 'CRSE',
  CRSE: 'CRSE',
  COURT: 'CT',
  CT: 'CT',
  COURTS: 'CTS',
  CTS: 'CTS',
  COVE: 'CV',
  CV: 'CV',
  COVES: 'CV',
  CREEK: 'CRK',
  CRK: 'CRK',
  CRESCENT: 'CRES',
  CRES: 'CRES',
  CRSENT: 'CRES',
  CRSNT: 'CRES',
  CREST: 'CRES',
  CROSSING: 'XING',
  CRSSNG: 'XING',
  XING: 'XING',
  CROSSROAD: 'XING',
  CROSSROADS: 'XING',
  CURVE: 'XING',
  DALE: 'DL',
  DL: 'DL',
  DAM: 'DM',
  DM: 'DM',
  DIV: 'DV',
  DIVIDE: 'DV',
  DV: 'DV',
  DVD: 'DV',
  DR: 'DR',
  DRIV: 'DR',
  DRIVE: 'DR',
  DRV: 'DR',
  DRIVES: 'DR',
  EST: 'EST',
  ESTATE: 'EST',
  ESTATES: 'ESTS',
  ESTS: 'ESTS',
  EXP: 'EXPY',
  EXPR: 'EXPY',
  EXPRESS: 'EXPY',
  EXPRESSWAY: 'EXPY',
  EXPW: 'EXPY',
  EXPY: 'EXPY',
  EXT: 'EXT',
  EXTENSION: 'EXT',
  EXTN: 'EXT',
  EXTNSN: 'EXT',
  EXTS: 'EXT',
  FALL: 'EXT',
  FALLS: 'FLS',
  FLS: 'FLS',
  FERRY: 'FRY',
  FRRY: 'FRY',
  FRY: 'FRY',
  FIELD: 'FLD',
  FLD: 'FLD',
  FIELDS: 'FLDS',
  FLDS: 'FLDS',
  FLAT: 'FLT',
  FLT: 'FLT',
  FLATS: 'FLTS',
  FLTS: 'FLTS',
  FORD: 'FRD',
  FRD: 'FRD',
  FORDS: 'FRD',
  FOREST: 'FRST',
  FORESTS: 'FRST',
  FRST: 'FRST',
  FORG: 'FRG',
  FORGE: 'FRG',
  FRG: 'FRG',
  FORGES: 'FRG',
  FORK: 'FRK',
  FRK: 'FRK',
  FORKS: 'FRKS',
  FRKS: 'FRKS',
  FORT: 'FT',
  FRT: 'FT',
  FT: 'FT',
  FREEWAY: 'FWY',
  FREEWY: 'FWY',
  FRWAY: 'FWY',
  FRWY: 'FWY',
  FWY: 'FWY',
  GARDEN: 'GDN',
  GARDN: 'GDN',
  GRDEN: 'GDN',
  GRDN: 'GDN',
  GARDENS: 'GDNS',
  GDNS: 'GDNS',
  GRDNS: 'GDNS',
  GATEWAY: 'GTWY',
  GATEWY: 'GTWY',
  GATWAY: 'GTWY',
  GTWAY: 'GTWY',
  GTWY: 'GTWY',
  GLEN: 'GLN',
  GLN: 'GLN',
  GLENS: 'GLN',
  GREEN: 'GRN',
  GRN: 'GRN',
  GREENS: 'GRN',
  GROV: 'GRV',
  GROVE: 'GRV',
  GRV: 'GRV',
  GROVES: 'GRV',
  HARB: 'HBR',
  HARBOR: 'HBR',
  HARBR: 'HBR',
  HBR: 'HBR',
  HRBOR: 'HBR',
  HARBORS: 'HBR',
  HAVEN: 'HVN',
  HVN: 'HVN',
  HT: 'HTS',
  HTS: 'HTS',
  HIGHWAY: 'HWY',
  HIGHWY: 'HWY',
  HIWAY: 'HWY',
  HIWY: 'HWY',
  HWAY: 'HWY',
  HWY: 'HWY',
  HILL: 'HL',
  HL: 'HL',
  HILLS: 'HLS',
  HLS: 'HLS',
  HLLW: 'HOLW',
  HOLLOW: 'HOLW',
  HOLLOWS: 'HOLW',
  HOLW: 'HOLW',
  HOLWS: 'HOLW',
  INLT: 'HOLW',
  IS: 'IS',
  ISLAND: 'IS',
  ISLND: 'IS',
  ISLANDS: 'ISS',
  ISLNDS: 'ISS',
  ISS: 'ISS',
  ISLE: 'ISLE',
  ISLES: 'ISLE',
  JCT: 'JCT',
  JCTION: 'JCT',
  JCTN: 'JCT',
  JUNCTION: 'JCT',
  JUNCTN: 'JCT',
  JUNCTON: 'JCT',
  JCTNS: 'JCTS',
  JCTS: 'JCTS',
  JUNCTIONS: 'JCTS',
  KEY: 'KY',
  KY: 'KY',
  KEYS: 'KYS',
  KYS: 'KYS',
  KNL: 'KNL',
  KNOL: 'KNL',
  KNOLL: 'KNL',
  KNLS: 'KNLS',
  KNOLLS: 'KNLS',
  LK: 'LK',
  LAKE: 'LK',
  LKS: 'LKS',
  LAKES: 'LKS',
  LAND: 'LKS',
  LANDING: 'LNDG',
  LNDG: 'LNDG',
  LNDNG: 'LNDG',
  LANE: 'LN',
  LN: 'LN',
  LGT: 'LGT',
  LIGHT: 'LGT',
  LIGHTS: 'LGT',
  LF: 'LF',
  LOAF: 'LF',
  LCK: 'LCK',
  LOCK: 'LCK',
  LCKS: 'LCKS',
  LOCKS: 'LCKS',
  LDG: 'LDG',
  LDGE: 'LDG',
  LODG: 'LDG',
  LODGE: 'LDG',
  LOOP: 'LOOP',
  LOOPS: 'LOOP',
  MALL: 'LOOP',
  MNR: 'MNR',
  MANOR: 'MNR',
  MANORS: 'MNRS',
  MNRS: 'MNRS',
  MEADOW: 'MNRS',
  MDW: 'MDWS',
  MDWS: 'MDWS',
  MEADOWS: 'MDWS',
  MEDOWS: 'MDWS',
  MEWS: 'MDWS',
  MILL: 'MDWS',
  MILLS: 'MDWS',
  MISSN: 'MSN',
  MSSN: 'MSN',
  MOTORWAY: 'MSN',
  MNT: 'MT',
  MT: 'MT',
  MOUNT: 'MT',
  MNTAIN: 'MTN',
  MNTN: 'MTN',
  MOUNTAIN: 'MTN',
  MOUNTIN: 'MTN',
  MTIN: 'MTN',
  MTN: 'MTN',
  MNTNS: 'MTNS',
  MOUNTAINS: 'MTNS',
  NCK: 'NCK',
  NECK: 'NCK',
  ORCH: 'ORCH',
  ORCHARD: 'ORCH',
  ORCHRD: 'ORCH',
  OVAL: 'OVAL',
  OVL: 'OVAL',
  OVERPASS: 'OVAL',
  PARK: 'PARK',
  PRK: 'PARK',
  PARKS: 'PARK',
  PARKWAY: 'PKWY',
  PARKWY: 'PKWY',
  PKWAY: 'PKWY',
  PKWY: 'PKWY',
  PKY: 'PKWY',
  PARKWAYS: 'PKWY',
  PKWYS: 'PKWY',
  PASS: 'PKWY',
  PASSAGE: 'PKWY',
  PATH: 'PATH',
  PATHS: 'PATH',
  PIKE: 'PIKE',
  PIKES: 'PIKE',
  PINE: 'PIKE',
  PINES: 'PNES',
  PNES: 'PNES',
  PL: 'PNES',
  PLAIN: 'PLN',
  PLN: 'PLN',
  PLAINS: 'PLNS',
  PLNS: 'PLNS',
  PLAZA: 'PLZ',
  PLZ: 'PLZ',
  PLZA: 'PLZ',
  POINT: 'PT',
  PT: 'PT',
  POINTS: 'PTS',
  PTS: 'PTS',
  PORT: 'PRT',
  PRT: 'PRT',
  PORTS: 'PRTS',
  PRTS: 'PRTS',
  PR: 'PR',
  PRAIRIE: 'PR',
  PRR: 'PR',
  RAD: 'RADL',
  RADIAL: 'RADL',
  RADIEL: 'RADL',
  RADL: 'RADL',
  RAMP: 'RADL',
  RANCH: 'RNCH',
  RANCHES: 'RNCH',
  RNCH: 'RNCH',
  RNCHS: 'RNCH',
  RAPID: 'RPD',
  RPD: 'RPD',
  RAPIDS: 'RPDS',
  RPDS: 'RPDS',
  REST: 'RST',
  RST: 'RST',
  RDG: 'RDG',
  RDGE: 'RDG',
  RIDGE: 'RDG',
  RDGS: 'RDGS',
  RIDGES: 'RDGS',
  RIV: 'RIV',
  RIVER: 'RIV',
  RVR: 'RIV',
  RIVR: 'RIV',
  RD: 'RD',
  ROAD: 'RD',
  ROADS: 'RDS',
  RDS: 'RDS',
  ROUTE: 'RDS',
  ROW: 'RDS',
  RUE: 'RDS',
  RUN: 'RDS',
  SHL: 'SHL',
  SHOAL: 'SHL',
  SHLS: 'SHLS',
  SHOALS: 'SHLS',
  SHOAR: 'SHR',
  SHORE: 'SHR',
  SHR: 'SHR',
  SHOARS: 'SHRS',
  SHORES: 'SHRS',
  SHRS: 'SHRS',
  SKYWAY: 'SHRS',
  SPG: 'SPG',
  SPNG: 'SPG',
  SPRING: 'SPG',
  SPRNG: 'SPG',
  SPGS: 'SPGS',
  SPNGS: 'SPGS',
  SPRINGS: 'SPGS',
  SPRNGS: 'SPGS',
  SPUR: 'SPGS',
  SPURS: 'SPGS',
  SQ: 'SQ',
  SQR: 'SQ',
  SQRE: 'SQ',
  SQU: 'SQ',
  SQUARE: 'SQ',
  SQRS: 'SQS',
  SQUARES: 'SQS',
  STA: 'STA',
  STATION: 'STA',
  STATN: 'STA',
  STN: 'STA',
  STRA: 'STRA',
  STRAV: 'STRA',
  STRAVEN: 'STRA',
  STRAVENUE: 'STRA',
  STRAVN: 'STRA',
  STRVN: 'STRA',
  STRVNUE: 'STRA',
  STREAM: 'STRM',
  STREME: 'STRM',
  STRM: 'STRM',
  STREET: 'ST',
  STRT: 'ST',
  ST: 'ST',
  STR: 'ST',
  STREETS: 'ST',
  SMT: 'SMT',
  SUMIT: 'SMT',
  SUMITT: 'SMT',
  SUMMIT: 'SMT',
  TER: 'TER',
  TERR: 'TER',
  TERRACE: 'TER',
  THROUGHWAY: 'TER',
  TRACE: 'TRCE',
  TRACES: 'TRCE',
  TRCE: 'TRCE',
  TRACK: 'TRAK',
  TRACKS: 'TRAK',
  TRAK: 'TRAK',
  TRK: 'TRAK',
  TRKS: 'TRAK',
  TRAFFICWAY: 'TRAK',
  TRAIL: 'TRL',
  TRAILS: 'TRL',
  TRL: 'TRL',
  TRLS: 'TRL',
  TRAILER: 'TRLR',
  TRLR: 'TRLR',
  TRLRS: 'TRLR',
  TUNEL: 'TUNL',
  TUNL: 'TUNL',
  TUNLS: 'TUNL',
  TUNNEL: 'TUNL',
  TUNNELS: 'TUNL',
  TUNNL: 'TUNL',
  TRNPK: 'TPKE',
  TURNPIKE: 'TPKE',
  TURNPK: 'TPKE',
  UNDERPASS: 'TPKE',
  UN: 'UN',
  UNION: 'UN',
  UNIONS: 'UN',
  VALLEY: 'VLY',
  VALLY: 'VLY',
  VLLY: 'VLY',
  VLY: 'VLY',
  VALLEYS: 'VLYS',
  VLYS: 'VLYS',
  VDCT: 'VIA',
  VIA: 'VIA',
  VIADCT: 'VIA',
  VIADUCT: 'VIA',
  VIEW: 'VW',
  VW: 'VW',
  VIEWS: 'VWS',
  VWS: 'VWS',
  VILL: 'VLG',
  VILLAG: 'VLG',
  VILLAGE: 'VLG',
  VILLG: 'VLG',
  VILLIAGE: 'VLG',
  VLG: 'VLG',
  VILLAGES: 'VLGS',
  VLGS: 'VLGS',
  VILLE: 'VL',
  VL: 'VL',
  VIS: 'VIS',
  VIST: 'VIS',
  VISTA: 'VIS',
  VST: 'VIS',
  VSTA: 'VIS',
  WALK: 'VIS',
  WALKS: 'VIS',
  WALL: 'VIS',
  WY: 'WAY',
  WAY: 'WAY',
  WAYS: 'WAY',
  WELL: 'WAY',
  WELLS: 'WLS',
  WLS: 'WLS',
  // End of STS

  // Start of PRD|POD
  WEST: 'W',
  EAST: 'E',
  SOUTH: 'S',
  NORTH: 'N',
  NORTHWEST: 'NW',
  NORTHEAST: 'NE',
  SOUTHEAST: 'SE',
  SOUTHWEST: 'SW',
  // End of PRD|POD

  getValue (key) {
    if (!key) return ''

    key = key.toString().toUpperCase()

    return this[key] || ''
  }
}

export default aliases
