import store from '@/store'
import apiService from '@/modules/api/csp'
import localStorageService from '@/util/local-storage-service'

export default class CallMonitoringModule {
  session;

  monitorCall (session) {
    if (session._request.hasHeader('Call-Monitoring')) {
      this.session = session
      this.checkCallType()
    }
  }

  checkCallType () {
    switch (this.session._request.getHeader('Call-Monitoring')) {
      case 'BARGE':
        this.barge()
        break
      case 'OBSERVE':
        this.observe()
        break
      case 'WHISPER':
        this.whisper()
        break
    }
  }

  barge () {
    this.sendDTMF(3)
  }

  observe () {

  }

  whisper () {
    this.sendDTMF(2)
  }

  sendDTMF (dtmfCode) {
    console.log('M DTMF', dtmfCode)

    setTimeout(() => {
      this.session.sendDTMF(dtmfCode, {
        transportType: 'RFC2833'
      })
    }, 2000)

    const data = {
      call_id: this.session._request.call_id,
      sip_address: store.getters['settings/sipDefaultAddressGetter'].sip_address,
      sip_message: JSON.stringify({
        from: 'Monitoring',
        value: dtmfCode,
        transportType: 'RFC2833'
      }),
      method: 'DTMF',
      direction: 'outgoing',
      type: 'json',
      write_time: Date.now(),
      position: localStorageService.getPositionNumber() ?? null
    }

    apiService.callLogs.saveCallLogs(data)
  }
}
