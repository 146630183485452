export default function connectionSettingsApiModule (instance) {
  return {

    /**
     * Returns connection settings (proxy)
     *
     * @returns {Promise<any>}
     */
    getConnectionSettings: async () => {
      const resource = '/connection-settings'
      return await instance.get(resource)
    },

    /**
     * Store connection settings (proxy)
     * @param data
     * @returns {Promise<*>}
     */
    storeConnectionSetting: async (data) => {
      const resource = '/connection-settings'
      return await instance.post(resource, data)
    },

    /**
     * Update connection settings (proxy)
     * @param data
     * @returns {Promise<*>}
     */
    updateConnectionSetting: async (id, data) => {
      const resource = `/connection-settings/${id}`
      return await instance.patch(resource, data)
    },

    /**
     * Save connection settings (proxy)
     * @param data
     * @returns {Promise<*>}
     */
    saveConnectionSetting: async (data) => {
      const resource = '/connection-settings/update-or-create'
      return await instance.post(resource, data)
    },

    /**
     * Delete connection settings
     * @param data
     * @returns {Promise<*>}
     */
    deleteConnectionSetting: async (data) => {
      const resource = `/connection-settings/${data}`
      const response = await instance.delete(resource)
      return response
    }

  }
}
