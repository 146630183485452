export default function rolesApiModule (instance) {
  return {

    /**
     * Return list of roles
     *
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    list: async (data) => {
      const appId = process.env.VUE_APP_PERMISSION_APP_ID

      const resource = `/roles/${appId}`

      const response = await instance.get(resource, data)

      return response
    },

    /**
     * Get single role
     * @param id
     * @returns {Promise<*>}
     */
    get: async (id) => {
      const resource = '/roles/find-role/' + id

      const response = await instance.get(resource)

      return response
    },

    /**
     * Create Role
     *
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    create: async (data) => {
      const resource = '/roles'

      data.app_id = process.env.VUE_APP_PERMISSION_APP_ID

      const response = await instance.post(resource, data)

      return response
    },

    /**
     * Update role
     *
     * @param id
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    edit: async (id, data) => {
      const resource = `/roles/${id}`

      const response = await instance.patch(resource, data)

      return response
    },

    /**
     * Delete Role
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    delete: async (id) => {
      const resource = `/roles/${id}`

      const data = {}
      data.app_id = process.env.VUE_APP_PERMISSION_APP_ID

      const response = await instance.delete(resource, { params: data })

      return response
    },

    /**
     * Sync roles for user
     * @param user_id
     * @param roles
     * @returns {Promise<AxiosResponse<any>>}
     */
    syncRoles: async (userId, roles) => {
      const resource = '/roles/sync-roles'

      const response = await instance.post(resource, {
        user_id: userId,
        roles: roles
      })

      return response
    },

    /**
     * Assign roles to user
     * @param userId
     * @param roles
     * @returns {Promise<AxiosResponse<any>>}
     */
    assignRoles: async (userId, roles) => {
      const resource = '/roles/assign-roles'

      const response = await instance.post(resource, {
        user_id: userId,
        roles: roles
      })

      return response
    },

    /**
     * Revoke roles from user
     * @param userId
     * @param roles
     * @returns {Promise<AxiosResponse<any>>}
     */
    revokeRoles: async (userId, roles) => {
      const resource = '/roles/revoke-roles'

      const response = await instance.post(resource, {
        user_id: userId,
        roles: roles
      })

      return response
    }
  }
}
