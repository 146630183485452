import helpers from '@/plugins/i3-logger/helpers/helper'
export default class CallLogEventSchema {
  /* CallLogEvent:
      type: object
      required:
        - direction
      properties:
        direction:
          $ref: '#/components/schemas/Direction'
        standardPrimaryCallType:
          type: string
          description: Values limited to those in the LogEvent CallTypes registry
        standardSecondaryCallType:
          type: string
          description: Values limited to those in the LogEvent CallTypes registry
        localCallType:
          type: string
        localUse:
          type: object
        to:
          type: string
        from:
          type: string */
  callLogEvent (session) {
    return {
      direction: session?._direction,
      standardPrimaryCallType: helpers().callType(session?._request?.headers?.['Call-Info']),
      to: session?._request?.to?._uri?._user,
      from: session?._request?.from?._uri?._user
    }
  }
}
