export default function translationsApiModule (instance) {
  return {

    /**
     * Return list of translations by application
     * @param params - {application_id, translation_id, realm}
     * @returns {Promise<*>}
     */
    load: async (params) => {
      const resource = `/translations/apps/${params.application_id}`

      const response = await instance.get(resource, {
        params: {
          realm_name: params.realm
        }
      })

      return response
    }

  }
}
