import VuetifyConfirm from '@/app/shared/notification/components'
import vuetify from '@/plugins/vuetify.plugin'

import i18n from './i18n'

export default {
  install (Vue) {
    Vue.use(VuetifyConfirm, {
      vuetify,
      buttonTrueText: i18n.t('confirm'),
      buttonFalseText: i18n.t('discard'),
      buttonTrueColor: 'success',
      buttonFalseColor: 'error',
      buttonTrueFlat: false,
      buttonFalseFlat: false,
      color: 'warning',
      icon: 'mdi-alert',
      title: i18n.t('warning'),
      width: 400,
      property: '$confirm'
    })
  }
}
