import textHelper from '@/helpers/text.helper'
class Logger {
  sip = null
  user = null
  level = 0
  socket = null
  debugMode = false

  LOG_LEVELS = {
    LOG: 'LOG',
    WARN: 'WARN',
    ERROR: 'ERROR',
    DEBUG: 'DEBUG',
    VERBOSE: 'VERBOSE'
  };

  LOG_LEVEL_VALUES = {
    LOG: 1,
    WARN: 2,
    ERROR: 4,
    DEBUG: 8,
    VERBOSE: 16
  }

  setLogLevel (level) {
    level = parseInt(level)

    if (isNaN(level) || !level) level = 0

    this.level = level
  }

  setUser (user) {
    this.user = user
  }

  setSip (sip) {
    this.sip = { ...sip }

    delete this.sip.password
  }

  setDebugMode (debugMode) {
    this.debugMode = debugMode
  }

  setSocket (socket) {
    this.socket = socket
  }

  /**
   * Write a 'log' level log.
   */
  log (message, ...optionalParams) {
    this.message(this.LOG_LEVELS.LOG, message, optionalParams)
  }

  /**
   * Write an 'error' level log.
   */
  error (message, ...optionalParams) {
    this.message(this.LOG_LEVELS.ERROR, message, optionalParams)
  }

  /**
   * Write a 'warn' level log.
   */
  warn (message, ...optionalParams) {
    this.message(this.LOG_LEVELS.WARN, message, optionalParams)
  }

  /**
   * Write a 'debug' level log.
   */
  debug (message, ...optionalParams) {
    this.message(this.LOG_LEVELS.DEBUG, message, optionalParams)
  }

  /**
   * Write a 'verbose' level log.
   */
  verbose (message, ...optionalParams) {
    this.message(this.LOG_LEVELS.VERBOSE, message, optionalParams)
  }

  message (logLevel, message, data) {
    if (this.debugMode) {
      console.log(message, data)
    }

    if (!this.socket || !(this.level & this.LOG_LEVEL_VALUES[logLevel])) return

    data = JSON.parse(textHelper.cleanStringify(data))

    this.socket.emit('send-chs-log', {
      data: {
        sip: this.sip,
        user: this.user,
        data: data
      },
      message,
      logLevel
    })
  }
}

export default {
  install (Vue) {
    Vue.prototype.$logger = new Logger()
    Vue.prototype.$logger.setSocket(Vue.prototype.$socket)

    Vue.prototype.$socket.on('log-level-update', (level) => {
      Vue.prototype.$logger.setLogLevel(level)
    })

    Vue.prototype.$event.listen('jssip-write-log', (event) => {
      // Vue.prototype.$logger.message(
      //   event.eventData.level,
      //   event.eventData.args.shift(),
      //   event.eventData.args
      // )
    })

    Vue.prototype.$socket.emit('get-log-level')
  }
}
