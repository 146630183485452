export default () => ({
  snackbar: {
    show: false,
    color: 'success',
    text: '',
    right: true,
    left: false,
    bottom: true,
    top: false,
    timeout: 20000,
    icon: null
  },
  dialog: {
    show: false,
    color: 'success',
    header: '',
    text: ''
  }
})
