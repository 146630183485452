export const dateTimeMutator = (state, payload) => {
  state.dateTimeState = payload
}

export const esrkNumberMutator = (state, payload) => {
  state.esrkNumberState = payload
}

export const incidentID = (state, payload) => {
  state.incidentID[payload.call_id] = payload.incident_id
}
