import rolesHelper from '@/helpers/roles.helper'

/**
 * Roles mixin
 */
export default {

  methods: {

    /**
     * Check is user has any roles
     * @param roles
     * @returns {boolean|*}
     */
    $hasAnyRole (roles) {
      return rolesHelper.hasAnyRole(roles)
    },

    /**
     * Check if user has role
     *
     * @param roleName
     * @returns {boolean|*}
     */
    $hasRole (roleName) {
      return rolesHelper.hasAnyRole(roleName)
    }
  }
}
