export default class Timer {
  _timers = []

  start () {
    setInterval(this.check.bind(this), 1000)
  }

  check () {
    for (const timer of this._timers) {
      if (!timer) continue

      const seconds = this.getSeconds()

      if (timer.lastRun + timer.interval <= seconds) {
        timer.lastRun = seconds
        timer.func()
      }
    }
  }

  add (func, interval) {
    this._timers.push({
      func,
      interval,
      lastRun: this.getSeconds()
    })

    return this._timers.length - 1
  }

  remove (index) {
    this._timers[index] = null
  }

  getSeconds () {
    return Math.round(Date.now() / 1000)
  }
}
