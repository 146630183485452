<template>
  <v-card flat class="fill-height align-start pa-0 full-width" color="transparent">
    <v-tabs
      show-arrows
      height="40"
      dark
      background-color="transparent"
      color="#FFFFFF"
      v-model="tab"
    >
      <template v-for="item in getItems">
        <v-tab :key="item.tab" :cypress="item.cypress" v-if="item.name !== 'invite' || reason !== 'abandoned'">
          <template v-if="item && item.name === 'abandoned_call' && abandonedCounter> 0 ">
            <v-badge overlap color="red" :content="abandonedCounter">
              {{ item.tab }}
            </v-badge>
          </template>
          <template v-else>
            {{ item.tab }}
          </template>
        </v-tab>
      </template>
    </v-tabs>
    <v-tabs-items style="height: calc(100% - 42px)" v-model="tab" class="full-width">
      <template v-for="item in getItems">
        <v-tab-item
          :eager="item.eager"
          :key="item.tab"
          class="fill-height"
          v-if="item.name !== 'invite' || reason !== 'abandoned'"
        >
          <component v-if="!!item.component" :is="item.component" ></component>
        </v-tab-item>
      </template>
    </v-tabs-items>
  </v-card>
</template>

<script>
import parentHeightMixin from '@/mixins/parent-height.mixin'
export default {
  name: 'tabsShared',
  props: ['items', 'abandonedCounter', 'reason'],
  mixins: [parentHeightMixin],
  data: () => ({
    tab: null
  }),
  computed: {
    getItems () {
      const items = this.items.filter((item) => {
        return (item.visible !== undefined ? item.visible : true)
      }).sort((a, b) => a.order - b.order)

      return items
    }
  }
}
</script>

<style>
.v-window__container {
  height: 100% !important;
}
</style>
