<template>
  <div>
    <v-list flat class="list transparent">
      <v-list-item class="col-4" v-if="$can('can_see_2fa_settings')">
        <v-list-item-content class="font-weight-medium">
          {{ $t('multi_factor_authentication') }}
        </v-list-item-content>
        <v-list-item-action>
          <v-switch :disabled="show.twoFA.codeValid" dense flat inset v-model="show.twoFA.button"></v-switch>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item class="col-5" v-if="show.twoFA.button">
        <v-list-item-content>
          <div>
            <v-btn v-if="!show.twoFA.codeValid" large class="text-capitalize pr-7" color="error"
                   @click="show.twoFA.phoneInput = true">
              <v-icon color="white" class="pl-4 pr-4">
                mdi-dialpad
              </v-icon>
              {{ $t('authorization_with_phone_number') }}
            </v-btn>
            <v-btn v-if="show.twoFA.codeValid" large class="text-capitalize pr-7" color="error"
                   @click="disablePhoneAuthorization">
              <v-icon color="white" class="pl-4 pr-4">
                mdi-cellphone-off
              </v-icon>
              {{ $t('disable_authorization') }}
            </v-btn>

          </div>

          <div class="mt-5" v-if="show.twoFA.phoneInput">

            <validation-observer ref="twoFaPhone">

              <validation-provider v-slot="{ errors }" vid="phone_number" :name="$t('phone_number')" rules="required">
                <v-text-field :disabled="loading" name="phone_number" v-model="twoFa.phoneNumber"
                              :label="$t('phone_number')" :error-messages="errors" outlined
                              background-color="primary_card"
                              class="mt-0">
                  <template v-slot:append v-if="show.twoFA.codeValid">
                    <span>
                      <v-icon color="green">mdi-check-circle</v-icon>
                    </span>
                  </template>
                  <template slot="append-outer">
                    <v-btn :disabled="loading" fab tile class="rounded" elevation="0"
                           :color="show.twoFA.codeSent ? 'primary' : 'error'" @click="send2FACode">
                      <v-icon v-if="show.twoFA.codeSent">mdi-refresh</v-icon>
                      <v-icon v-else>mdi-send</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </validation-provider>

            </validation-observer>

            <validation-observer ref="twoFaCode">

              <div v-show="show.twoFA.codeSent">
                <validation-provider v-slot="{ errors }" vid="twoFa_code" :name="$t('twoFA_code')" rules="required">
                  <v-text-field :disabled="loading" name="twoFa_code" v-model="twoFa.verification_code"
                                :label="$t('tfa_code')" :error-messages="errors" outlined
                                background-color="primary_card">
                    <div slot="append-outer">
                      <v-btn :disabled="loading" fab tile class="rounded" elevation="0" color="error"
                             @click="validate2FACode">
                        <v-icon>mdi-send</v-icon>
                      </v-btn>
                    </div>
                  </v-text-field>
                </validation-provider>
              </div>

            </validation-observer>
          </div>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item class="col-4" v-if="$can('can_see_dark_light_settings')">
        <v-list-item-content class="font-weight-medium">
          {{ $t('lightdark_mode') }}
        </v-list-item-content>
        <v-list-item-action>
          <v-switch dense flat inset v-model="$vuetify.theme.dark" @change="saveThemeStyle"></v-switch>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item class="col-4" v-if="$can('can_see_off_hook_relay')">
        <v-list-item-content class="font-weight-medium">
          {{ $t('enable_off_hook_relay') }}
        </v-list-item-content>
        <v-list-item-action v-if="off_hook">
          <v-switch dense flat inset @change="updateSetting(off_hook)" v-model="off_hook.value.active"></v-switch>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item class="col-4" v-if="$can('can_see_auxiliary_relay')">
        <v-list-item-content class="font-weight-medium">
          {{ $t('enable_auxiliary_relay') }}
        </v-list-item-content>
        <v-list-item-action v-if="auxiliary">
          <v-switch dense flat inset @change="updateSetting(auxiliary)" v-model="auxiliary.value.active"></v-switch>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item class="col-4" v-if="$can('can_see_right_left_dial_pad_settings')">
        <v-list-item-content class="font-weight-medium">
          {{ $t('leftright_dial_pad') }}
        </v-list-item-content>
        <v-list-item-action v-if="dial_pad">
          <v-switch dense flat inset @change="updateSetting(dial_pad)" v-model="dial_pad.value.active"></v-switch>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item class="col-4"
                   v-if="$can('can_see_set_prefix_for_outgoing_calls_settings') && call_settings_prefix_for_outgoing_calls">
        <v-list-item-content class="font-weight-medium">
          {{ $t('set_prefix_for_outgoing_calls') }}
        </v-list-item-content>
        <v-list-item-action>
          <v-switch dense flat inset @change="updateSetting(call_settings_prefix_for_outgoing_calls)"
                    v-model="call_settings_prefix_for_outgoing_calls.value.active"></v-switch>
        </v-list-item-action>
      </v-list-item>
      <v-list-item class="col-4"
                   v-if="call_settings_prefix_for_outgoing_calls && call_settings_prefix_for_outgoing_calls.value.active">
        <v-list-item-content>
          <v-text-field v-model="call_settings_prefix_for_outgoing_calls.value.prefix" :label="$t('prefix')" outlined
                        background-color="primary_card">
            <div slot="append-outer">
              <v-btn @click="updateSetting(call_settings_prefix_for_outgoing_calls)" fab tile class="rounded"
                     elevation="0" color="error">
                <v-icon>mdi-database</v-icon>
              </v-btn>
            </div>
          </v-text-field>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item class="col-4"
                   v-if="$can('can_see_queue_auth_via_phone_number') && call_settings_queue_auth_via_phone_number">
        <v-list-item-content class="font-weight-medium">
          {{ $t('queue_auth_via_phone_number') }}
        </v-list-item-content>
        <v-list-item-action>
          <v-switch dense flat inset @change="updateSetting(call_settings_queue_auth_via_phone_number)"
                    v-model="call_settings_queue_auth_via_phone_number.value.active"></v-switch>
        </v-list-item-action>
      </v-list-item>
      <v-list-item class="col-4"
                   v-if="call_settings_queue_auth_via_phone_number && call_settings_queue_auth_via_phone_number.value.active">
        <v-list-item-content>
          <v-text-field :label="$t('login_number')"
                        v-model="call_settings_queue_auth_via_phone_number.value.login_number" outlined
                        background-color="primary_card">
            <div slot="append-outer">
              <v-btn @click="updateSetting(call_settings_queue_auth_via_phone_number)" fab tile class="rounded"
                     elevation="0" color="error">
                <v-icon>mdi-database</v-icon>
              </v-btn>
            </div>
          </v-text-field>
          <v-text-field :label="$t('logout_number')"
                        v-model="call_settings_queue_auth_via_phone_number.value.logout_number" outlined
                        background-color="primary_card">
            <div slot="append-outer">
              <v-btn @click="updateSetting(call_settings_queue_auth_via_phone_number)" fab tile class="rounded"
                     elevation="0" color="error">
                <v-icon>mdi-database</v-icon>
              </v-btn>
            </div>
          </v-text-field>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item class="col-4">
        <v-list-item-content class="font-weight-medium col-5">
          {{ $t('language') }}
        </v-list-item-content>
        <v-list-item-action class="col-7">
          <v-select @input="switchLanguage" class="language-select" :items="languages" v-model="language"
                    item-text="name" item-value="code"/>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item class="col-4">
        <v-list-item-content class="font-weight-medium col-5">
          {{ $t('scale_font_size') }} %
        </v-list-item-content>
        <v-list-item-action class="col-7">
          <v-slider
            :tick-labels="ticksLabels"
            :value="fontSize"
            :min="90"
            :max="120"
            step="10"
            ticks="always"
            tick-size="4"
            @change="changeFontSize"
          ></v-slider>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item class="col-4" v-if="$can('can_enable_debug_logs')">
        <v-list-item-content class="font-weight-medium">
          {{ $t('debug_mode') }}
        </v-list-item-content>
        <v-list-item-action>
          <v-switch dense flat inset @change="updateSetting(chs_debug_logs)" v-model="chs_debug_logs.value.active"></v-switch>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item class="col-4" v-if="$can('can_see_change_password_settings')">
        <v-list-item-content class="font-weight-medium">
          {{ $t('password_change') }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="col-4" v-if="$can('can_see_change_password_settings')">
        <v-row>
          <validation-observer ref="observer" tag="form" @submit.prevent="updatePassword" class="pt-4 col-12">
            <validation-provider v-slot="{ errors }" name="current_password" rules="required">
              <v-text-field id="current_password" name="current_password" :error-messages="errors"
                            :label="$t('previous_password')" outlined
                            :type="show.current_password ? 'text' : 'password'"
                            background-color="primary_card" v-model="password_form.current_password">
                <template v-slot:append>
                  <span class="pointer">
                    <v-icon color="grey" @click="show.current_password = false" v-if="show.current_password">mdi-eye
                    </v-icon>
                    <v-icon color="grey" @click="show.current_password = true" v-else>mdi-eye-off</v-icon>
                  </span>
                </template>
              </v-text-field>
            </validation-provider>
            <validation-provider v-slot="{ errors }" name="new_password" rules="required">
              <v-text-field id="new_password" name="new_password" :error-messages="errors" :label="$t('new_password')"
                            outlined :type="show.new_password ? 'text' : 'password'" background-color="primary_card"
                            v-model="password_form.new_password">
                <template v-slot:append>
                  <span class="pointer">
                    <v-icon color="grey" @click="show.new_password = false" v-if="show.new_password">mdi-eye</v-icon>
                    <v-icon color="grey" @click="show.new_password = true" v-else>mdi-eye-off</v-icon>
                  </span>
                </template>
              </v-text-field>
            </validation-provider>
            <validation-provider v-slot="{ errors }" name="repeat_new_password" rules="required">
              <v-text-field id="new_password_confirmation" name="new_password_confirmation" :error-messages="errors"
                            :label="$t('repeat_new_password')" outlined
                            :type="show.new_password_confirmation ? 'text' : 'password'"
                            background-color="primary_card" v-model="password_form.new_password_confirmation">
                <template v-slot:append>
                  <span class="pointer">
                    <v-icon color="grey" @click="show.new_password_confirmation = false"
                            v-if="show.new_password_confirmation">mdi-eye</v-icon>
                    <v-icon color="grey" @click="show.new_password_confirmation = true" v-else>mdi-eye-off</v-icon>
                  </span>
                </template>
              </v-text-field>
            </validation-provider>
            <v-btn type="submit" large color="error">
              {{ $t('change_password') }}
            </v-btn>
          </validation-observer>
        </v-row>
      </v-list-item>
    </v-list>
  </div>
</template>

<style lang="scss" scoped>
::v-deep .v-input__append-outer {
  margin-top: 0px !important;
}
</style>

<script>
import apiService from '@/modules/api/csp'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import '@/rules/validation.rules.js'
import { mapActions, mapGetters } from 'vuex'
import localStorageService from '@/util/local-storage-service'

export default {
  name: 'GeneralSettingTab',
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data: () => ({
    test: '',
    language: null,
    languages: [],

    loading: false,

    dial_pad: null,
    off_hook: null,
    auxiliary: null,
    chs_debug_logs: false,
    call_settings_prefix_for_outgoing_calls: null,
    call_settings_queue_auth_via_phone_number: null,

    show: {
      twoFA: {
        button: false,
        codeSent: false,
        codeValid: false,
        codeInput: false,
        phoneInput: false
      },
      queueAuth: false,
      outgoingCalls: false,
      new_password: false,
      current_password: false,
      new_password_confirmation: false
    },

    twoFa: {
      phoneNumber: null,
      verification_id: null,
      verification_code: null
    },

    password_form: {
      new_password: null,
      current_password: null,
      new_password_confirmation: null
    },
    fontSize: null,
    ticksLabels: [
      '90',
      '100',
      '110',
      '120'
    ]
  }),
  async mounted () {
    await this.loadSettings()
    await this.loadLanguages()
    this.fontSize = localStorageService.getFontSize() || 100
  },
  computed: {
    ...mapGetters('global', ['authUserGetter']),
    ...mapGetters('settings', ['userSettingsGetter', 'getUserSetting', 'sipDefaultAddressGetter'])
  },

  methods: {

    async loadLanguages () {
      await apiService.languages.list().then((response) => {
        this.languages = response.data
      })

      this.language = localStorageService.getLanguage()
    },

    switchLanguage (input) {
      localStorageService.setLanguage(input)
      this.$i18n.locale = input
    },

    loadSettings () {
      if (this.authUserGetter && this.sipDefaultAddressGetter) {
        this.twoFa.phoneNumber = this.authUserGetter.mobile_phone
        this.show.twoFA.codeValid = this.getUserSetting('2fa').value.phone_validated
        if (this.show.twoFA.codeValid) {
          this.show.twoFA.phoneInput = true
        }
        this.show.twoFA.button = this.getUserSetting('2fa').value.active

        this.dial_pad = JSON.parse(JSON.stringify(this.getUserSetting('dial-pad-on-right')))
        this.off_hook = JSON.parse(JSON.stringify(this.getUserSetting('enable_off_hook_relay')))
        this.auxiliary = JSON.parse(JSON.stringify(this.getUserSetting('enable_auxiliary_relay')))
        this.chs_debug_logs = JSON.parse(JSON.stringify(this.getUserSetting('chs_debug_logs')))

        this.call_settings_prefix_for_outgoing_calls = JSON.parse(JSON.stringify(this.getUserSetting('call_settings_prefix_for_outgoing_calls')))
        this.call_settings_queue_auth_via_phone_number = JSON.parse(JSON.stringify(this.getUserSetting('call_settings_queue_auth_via_phone_number')))
      }
    },

    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),
    ...mapActions('global', [
      'changeFontSizeAction'
    ]),
    ...mapActions('settings', [
      'setUserSettingsAction'
    ]),

    async updatePassword () {
      const isValid = await this.$refs.observer.validate()

      if (!isValid) return

      this.loading = true

      apiService.user.changePassword(this.password_form).then(() => {
        this.notificationSnackBarAction({
          show: true,
          color: 'success',
          text: 'Password Changed'
        })

        this.password_form.new_password = null
        this.password_form.current_password = null
        this.password_form.new_password_confirmation = null

        this.$nextTick(() => this.$refs.observer.reset())
      }).catch((error) => {
        if (error.response.data.errors) {
          this.$refs.observer.setErrors(error.response.data.errors)
        }

        if (error.response.data.message) {
          this.notificationSnackBarAction({
            show: true,
            color: 'error',
            text: error.response.data.message[0]
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },

    /**
     * Submit phone number and send 2fa code
     * @returns {Promise<void>}
     */
    async send2FACode () {
      const isValid = await this.$refs.twoFaPhone.validate()

      if (!isValid) return

      this.loading = true

      apiService.user.send2faToPhoneNumber(this.twoFa.phoneNumber).then((response) => {
        this.notificationSnackBarAction({
          show: true,
          color: 'success',
          text: response.data.data.message
        })

        this.twoFa.verification_code = null
        this.show.twoFA.codeSent = true
        this.twoFa.verification_id = response.data.data.id

        this.$nextTick(() => this.$refs.twoFaPhone.reset())
      }).catch((error) => {
        if (error.response.data.message) {
          this.$refs.twoFaPhone.setErrors({
            phone_number: error.response.data.message[0]
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },

    async validate2FACode () {
      const isValid = await this.$refs.twoFaCode.validate()

      if (!isValid) return

      this.loading = true

      apiService.user.validate2faCode({
        verification_id: this.twoFa.verification_id,
        verification_code: this.twoFa.verification_code
      }).then((response) => {
        this.notificationSnackBarAction({
          show: true,
          color: 'success',
          text: response.data.message
        })

        this.twoFa.verification_code = null
        this.twoFa.verification_id = null

        this.show.twoFA.codeValid = true
        this.show.twoFA.codeSent = false

        this.$nextTick(() => this.$refs.twoFaCode.reset())
      }).catch((error) => {
        if (error.response.data.message) {
          this.$refs.twoFaCode.setErrors({
            twoFa_code: error.response.data.message[0]
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },

    async updateSetting (updatedSetting) {
      this.loading = true

      apiService.userSettings.edit(updatedSetting.setting.id, {
        value: updatedSetting.value
      }).then((response) => {
        const list = JSON.parse(JSON.stringify(this.userSettingsGetter))
        const object = list.findIndex(obj => obj.setting.id === updatedSetting.setting.id)
        list[object] = updatedSetting

        this.setUserSettingsAction(JSON.parse(JSON.stringify(list)))

        this.loading = false
      }).catch(() => {
        this.notificationSnackBarAction({
          show: true,
          color: 'error',
          text: this.$t('update_error')
        })

        this.loading = false
      })
    },

    async disablePhoneAuthorization () {
      apiService.userSettings.edit(1, {
        value: {
          active: false
        }
      }).then((response) => {
        this.show.twoFA.button = false
        this.show.twoFA.codeSent = false
        this.show.twoFA.codeInput = false
        this.show.twoFA.codeValid = false
        this.show.twoFA.phoneInput = false
      })
    },

    saveThemeStyle () {
      localStorageService.setThemeStyle(this.$vuetify.theme.dark)
    },
    changeFontSize (val) {
      this.changeFontSizeAction(val)
    }
  },
  watch: {
    'userSettingsGetter' (value) {
      this.loadSettings()
    },
    'show.twoFA.button' (val) {
      if (val === false) {
        this.show.twoFA.codeSent = false
        this.show.twoFA.codeInput = false
        this.show.twoFA.codeValid = false
        this.show.twoFA.phoneInput = false
      }
    }
  }
}
</script>

<style type="scss" scoped>
.language-select {
  max-width: 200px;
}
</style>
