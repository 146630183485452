
/**
 * Update state of dashboard icons
 * @param state
 * @param payload
 * @returns {Promise<void>}
 */
export const dashboardsIconsMutator = async (state, payload) => {
  state.dashboardIcons = payload
}

/**
 * Show dashboard
 * @param state
 * @param payload true/false
 * @returns {Promise<void>}
 */
export const showDashboardMutator = async (state, payload) => {
  state.showDashboard = payload
}

/**
 * Update state of dashboards
 * @param state
 * @param payload
 * @returns {Promise<void>}
 */
export const dashboardsMutator = async (state, payload) => {
  state.dashboards = payload
}

/**
 * Update state of widgets
 * @param state
 * @param payload
 * @returns {Promise<void>}
 */
export const widgetsMutator = async (state, payload) => {
  state.widgets = payload
}

/**
 * Update state of current dashboard
 * @param state
 * @param payload
 * @returns {Promise<void>}
 */
export const currentDashboardMutator = async (state, payload) => {
  state.currentDashboard = payload
}
