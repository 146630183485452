import apiService from '@/modules/api/csp'

export const loadRingtonesAction = async ({ commit, getters }) => {
  await apiService.globalRingtone.getAllDefault().then(globalRingtonesResponse => {
    commit('updateGlobalRingtonesMutator', globalRingtonesResponse.data.data)
    return globalRingtonesResponse.data.data
  })

  await apiService.sip.getDefaultSipAddresses().then(async response => {
    if (response != null && response.realm != null) {
      await apiService.realmRingtone.getAllDefault(response.realm).then(realmRingtonesReponse => {
        commit('updateRealmRingtonesMutator', realmRingtonesReponse.data.data)
        return realmRingtonesReponse.data.data
      })
    }
  })
}

export const permissions = ({ commit }, permissions) => {
  commit('permissions', permissions)
}
export const roles = ({ commit }, roles) => {
  commit('roles', roles)
}
