export default function globalRingtoneApiModule (instance) {
  return {
    /**
     * get selected ringtone by file_id ??
     * put default global ringtone
     */

    /**
     * Get ringtone types
     * @returns {Promise<*>}
     */
    types: async () => {
      const resource = '/ringtones/types'

      const response = await instance.get(resource)

      return response
    },

    /**
     * Upload ringtone
     * @param realm
     * @param payload (type_id,file)
     * @returns {Promise<*>}
     */
    upload: async (payload) => {
      const resource = '/ringtones/global'

      const formData = new FormData()

      formData.append('type_id', payload.type_id)
      formData.append('ringtone', payload.ringtone)

      const response = await instance.post(resource, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return response
    },

    /**
     * Mark selected ringtone as default
     * @param fileId
     * @returns {Promise<*>}
     */
    markAsDefault: async (fileId) => {
      const resource = `/ringtones/global/default/${fileId}`

      const response = await instance.put(resource)

      return response
    },

    /**
     * Delete ringtone
     * @param fileId
     * @returns {Promise<*>}
     */
    deleteRingtone: async (fileId) => {
      const resource = `/ringtones/global/${fileId}`

      const response = await instance.delete(resource)

      return response
    },

    /**
     * Get all global ringtones
     * @returns {Promise<*>}
     */
    getAll: async () => {
      const resource = '/ringtones/global'

      const response = await instance.get(resource)

      return response
    },

    /**
     * Get all default global ringtones
     * @returns {Promise<*>}
     */
    getAllDefault: async () => {
      const resource = '/ringtones/global/default'

      const response = await instance.get(resource)

      return response
    },

    getDefaultRingtones: async (realm) => {
      const resource = `/ringtones/realm/${realm}/default`

      const response = await instance.get(resource)

      return response
    }

  }
}
