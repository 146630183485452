export default function prerecordedAudiosApiModule (instance) {
  return {
    list: async () => {
      const resource = 'prerecorded_audios/list'

      return instance.get(resource)
    },
    create: async (payload) => {
      const resource = 'prerecorded_audios/create'

      const formData = new FormData()

      formData.append('name', payload.name)
      formData.append('file', payload.file)

      const response = await instance.post(resource, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      return response
    },
    edit: async (payload) => {
      const resource = `prerecorded_audios/edit/${payload.id}`

      delete payload.id

      const formData = new FormData()

      formData.append('name', payload.name)
      formData.append('file', payload.file)

      const response = await instance.post(resource, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      return response
    },
    delete: (id) => {
      const resource = `prerecorded_audios/${id}`

      return instance.delete(resource)
    }
  }
}
