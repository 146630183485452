/**
 * Look for specific ringtone in realm and global ringtones.
 * @param state
 * @returns {function(*): null}
 */
export const getRingtoneByType = state => (type) => {
  const realmRingtone = state.realmRingtones.find(item => item.type === type)

  if (realmRingtone) {
    return realmRingtone.file_url
  }

  const globalRingtone = state.globalRingtones.find(item => item.type === type)

  if (globalRingtone) {
    return globalRingtone.file_url
  }
}

export const permissions = (state) => {
  return state.permissions
}
export const roles = (state) => {
  return state.roles
}
