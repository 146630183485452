import store from '@/store'

export default function callLogsApiModule (instance) {
  return {
    /**
     * Save call WS logs
     * @param data
     * @returns {Promise<*>}
     */
    saveCallLogs: async (data) => {
      const resource = '/call-log-details'

      data.incident_id = store.getters['callInformation/incidentIDGetter'][data.call_id]

      return await instance.post(resource, data)
    }
  }
}
