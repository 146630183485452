export const heldUpdateGetter = state => {
  return state.updated
}

export const heldGetter = state => {
  return state.heldState
}
export const what3wordsAddressGetter = state => {
  return state.what3wordsAddress
}
