import state from '@/app/widgets/admin-panel-widget/store/state'
import * as actions from '@/app/widgets/admin-panel-widget/store/actions'
import * as getters from '@/app/widgets/admin-panel-widget/store/getters'
import * as mutations from '@/app/widgets/admin-panel-widget/store/mutations'

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  state
}
