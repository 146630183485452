<template>
  <div></div>
</template>

<script>
export default {
  name: 'MovableDialogsScriptComponent',
  data: () => ({
    display: {},
    boundInterval: null
  }),
  methods: {
    startDraggingEventListeners () {
      document.addEventListener('mousedown', (e) => {
        const closestDialog = e.target.closest('.v-dialog.v-dialog--active')
        if (
          e.button === 0 &&
          closestDialog != null &&
          e.target.classList.contains('v-card__title')
        ) {
          this.display.el = closestDialog
          this.display.mouseStartX = e.clientX
          this.display.mouseStartY = e.clientY
          this.display.elStartX = this.display.el.getBoundingClientRect().left
          this.display.elStartY = this.display.el.getBoundingClientRect().top
          this.display.el.style.position = 'fixed'
          this.display.el.style.margin = 0
          this.display.oldTransition = this.display.el.style.transition
          this.display.el.style.transition = 'none'
        }
      })
      document.addEventListener('mousemove', (e) => {
        if (this.display.el === undefined) return
        this.display.el.style.left =
          Math.min(
            Math.max(
              this.display.elStartX + e.clientX - this.display.mouseStartX,
              0
            ),
            window.innerWidth - this.display.el.getBoundingClientRect().width
          ) + 'px'
        this.display.el.style.top =
          Math.min(
            Math.max(
              this.display.elStartY + e.clientY - this.display.mouseStartY,
              0
            ),
            window.innerHeight - this.display.el.getBoundingClientRect().height
          ) + 'px'
      })
      document.addEventListener('mouseup', () => {
        if (this.display.el === undefined) return
        this.display.el.style.transition = this.display.oldTransition
        this.display.el = undefined
      })
    },
    preventOutOfBounds () {
      this.boundInterval = setInterval(() => {
        const dialog = document.querySelector('.v-dialog.v-dialog--active')
        if (dialog === null) return
        dialog.style.left =
          Math.min(
            parseInt(dialog.style.left),
            window.innerWidth - dialog.getBoundingClientRect().width
          ) + 'px'
        dialog.style.top =
          Math.min(
            parseInt(dialog.style.top),
            window.innerHeight - dialog.getBoundingClientRect().height
          ) + 'px'
      }, 100)
    }
  },
  mounted () {
    this.startDraggingEventListeners()
    this.preventOutOfBounds()
  },
  beforeDestroy () {
    clearInterval(this.boundInterval)
  }
}
</script>
