import apiService from '@/modules/api/csp'

export const callAgentsAction = async ({ commit }, payload = []) => {
  await apiService.egress.getUserList(payload.accountId, payload.params).then(result => {
  // await apiService.egress.getDevicesList(payload.accountId, payload.params).then(result => {
    (result.data.status === 'success') ? commit('callAgentsMutator', result.data.data) : commit('callAgentsMutator', [])
  }).catch(() => {
    commit('callAgentsMutator', [])
  })
}
