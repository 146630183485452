<template>
  <v-dialog
    :max-width="width"
    eager
    hide-overlay
    no-click-animation
    persistent
    value="true"
    @input="change"
    @keydown.esc="choose(false)"
  >
    <v-card :dark="$vuetify.theme.dark" color="secondary_card" tile>
      <v-card-title v-if="Boolean(title)" color="primary_card">
        <v-icon v-if="Boolean(icon)" :color="color" left>{{ icon }}</v-icon>
        <v-toolbar-title
          :dark="$vuetify.theme.dark"
          color="primary"
          v-text="title"
        />

        <v-spacer />

        <v-icon
          :dark="$vuetify.theme.dark"
          class="pointer"
          @click="choose(false)"
          >mdi-close
        </v-icon>
      </v-card-title>

      <v-card-text class="text-h6 py-3" v-html="message" />

      <v-card-text class="text-right">
        <v-btn
          v-if="Boolean(buttonFalseText)"
          :text="buttonFalseFlat"
          class="mr-5"
          color="grey"
          cypress="confirm_false"
          @click="choose(false)"
        >
          {{ buttonFalseText }}
        </v-btn>
        <v-btn
          v-if="Boolean(buttonTrueText)"
          :text="buttonTrueFlat"
          color="red"
          cypress="confirm_true"
          @click="choose(true)"
        >
          {{ buttonTrueText }}
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  VBtn,
  VCard,
  VCardText,
  VDialog,
  VIcon,
  VSpacer,
  VToolbarTitle
} from 'vuetify/lib'

export default {
  components: {
    VCard,

    VCardText,
    VDialog,
    VIcon,
    VToolbarTitle,
    VSpacer,
    VBtn
  },
  props: {
    buttonTrueText: {
      type: String,
      default: 'Yes'
    },
    buttonFalseText: {
      type: String,
      default: 'No'
    },
    buttonTrueColor: {
      type: String,
      default: 'primary'
    },
    buttonFalseColor: {
      type: String,
      default: 'grey'
    },
    buttonFalseFlat: {
      type: Boolean,
      default: true
    },
    buttonTrueFlat: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'warning'
    },
    icon: {
      type: String,
      default () {
        return this.$vuetify.icons.values.warning
      }
    },
    message: {
      type: String,
      required: true
    },
    persistent: Boolean,
    title: {
      type: String
    },
    width: {
      type: Number,
      default: 450
    }
  },
  data () {
    return {
      value: false
    }
  },
  mounted () {
    this.$event.listen('keyup', this.onEnterPressed)
  },
  destroyed () {
    document.removeEventListener('keyup', this.onEnterPressed)
  },
  methods: {
    onEnterPressed (e) {
      if (e.keyCode === 13) {
        e.stopPropagation()
        this.choose(true)
      }
    },
    choose (value) {
      this.$emit('result', value)
      this.value = value
      this.$destroy()
    },
    change (res) {
      this.$destroy()
    }
  }
}
</script>
