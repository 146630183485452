export const notificationSnackBarMutator = async (state, payload) => {
  state.snackbar.show = payload.show || false
  state.snackbar.color = payload.color || 'success'
  state.snackbar.text = payload.text || ''
  state.snackbar.right = payload.right || true
  state.snackbar.left = payload.left || false
  state.snackbar.bottom = payload.bottom || true
  state.snackbar.top = payload.top || false
  state.snackbar.timeout = payload.timeout || 20000
  state.snackbar.icon = payload.icon || null
}

export const notificationDialogMutator = async (state, payload) => {
  state.dialog = payload
}
