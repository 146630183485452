<template>
  <code class="transparent">
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          cypress="header_settings"
          @click="settingsModal"
          icon
          color="grey_light"
          text
          v-on="on"
        >
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('settings') }}</span>
    </v-tooltip>
    <DialogShared @input="closed" v-model="toggleSettings" eager max-width="1200" :title="$t('settings')">
      <SettingsComponent />
    </DialogShared>
  </code>
</template>

<script>
import { mapMutations } from 'vuex'
import DialogShared from '@/app/shared/dialog/DialogShared'
import SettingsComponent from '@/app/widgets/settings-widget/components/SettingsComponent'

export default {
  name: 'SettingsButton',
  data: () => ({
    toggleSettings: false
  }),
  components: {
    DialogShared,
    SettingsComponent
  },
  methods: {
    ...mapMutations('audioVolume', [
      'testRingingMutator',
      'testCallerMutator'
    ]),
    closed (val) {
      if (!val) { this.clearAudioElements() }
    },
    clearAudioElements () {
      this.testRingingMutator(false)
      this.testCallerMutator(false)
    },
    settingsModal () {
      this.toggleSettings = !this.toggleSettings
    }
  }
}
</script>

<style scoped>

</style>
