<template>
  <v-snackbar :class="'chs-snackbar-'+notificationSnackBarGetter.color"
              v-model="snackbar"
              :bottom="notificationSnackBarGetter.bottom"
              :top="notificationSnackBarGetter.top"
              :right="notificationSnackBarGetter.right"
              :left="notificationSnackBarGetter.left"
              :timeout="notificationSnackBarGetter.timeout"
  >

    <template v-if="notificationSnackBarGetter" >
      <v-icon v-if="notificationSnackBarGetter.color == 'info' && !notificationSnackBarGetter.icon"
              color="info" >mdi-playlist-check</v-icon>
      <v-icon v-if="notificationSnackBarGetter.color == 'error' && !notificationSnackBarGetter.icon"
              color="error" >mdi-alert-circle</v-icon>
      <v-icon v-if="notificationSnackBarGetter.color == 'warning' && !notificationSnackBarGetter.icon"
              color="warning" >mdi-alert</v-icon>
      <v-icon v-if="notificationSnackBarGetter.color == 'success' && !notificationSnackBarGetter.icon"
              color="success" >mdi-check-circle</v-icon>
      <v-icon v-if="notificationSnackBarGetter.color && notificationSnackBarGetter.icon"
              :color="notificationSnackBarGetter.color" >{{ notificationSnackBarGetter.icon }}</v-icon>
      {{ notificationSnackBarGetter.text }}
    </template>

    <template v-slot:action="{ attrs }">
      <v-icon  :dark="$vuetify.theme.dark"

          text
          v-bind="attrs"
          @click="snackbar = false"
      >
        mdi-close
      </v-icon>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SnackBarComponent',
  mixins: [],
  data: () => ({
    snackbar: false
  }),
  computed: {
    ...mapGetters('notifications', [
      'notificationSnackBarGetter'
    ])
  },
  methods: {
    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ])

  },
  watch: {
    'notificationSnackBarGetter.show' (val) {
      this.snackbar = val
    },
    snackbar (val) {
      if (!val) {
        this.notificationSnackBarAction({
          text: '',
          color: 'error',
          show: false
        })
      }
    }
  }
}
</script>

<style scoped>
</style>
